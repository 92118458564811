[data-component='container-isi-v2'] {
  .aaaem-isi {
    &-banner {
      flex-direction: column-reverse;
      align-items: unset;
      position: relative;
      z-index: var(--emu-common-other-z-index-layer);

      @include mq('tablet') {
        max-width: 1170px; //as per live
        margin-left: auto;
        margin-right: auto;
      }
    }

    &-container {
      height: 155px; //as per live

      &__button-wrapper {
        span {
          font-weight: var(--emu-common-font-weight-bold);
          font-size: var(--emu-semantic-font-sizes-wide-xs);
          line-height: 15px;
          margin-bottom: 6px;
        }
      }

      &__banner-content {
        p {
          font-size: var(--emu-semantic-font-sizes-wide-xs);
          font-weight: var(--emu-common-font-weight-regular);
          line-height: 15px;

          &:not(:has(.emphasis)) {
            margin-bottom: 6px;
          }

          .emphasis {
            b {
              font-size: var(--emu-common-font-sizes-wide-medium);
              line-height: 1.5;
            }
          }

          sup {
            font-weight: var(--emu-common-font-weight-regular);
          }
        }
      }

      &__isi-content {
        padding-top: 123px;
        padding-bottom: 40px;

        p {
          font-size: var(--emu-common-font-sizes-wide-medium);
          line-height: 1.5;

          &:not(:has(.emphasis)) {
            margin-bottom: var(--emu-common-spacing-medium);
          }

          sup {
            font-weight: var(--emu-common-font-weight-light);
          }
        }
      }
    }
  }
}
