#results {
  .results {
    &__banner {
      &-wrapper {
        margin-top: 115px;
        position: relative;

        @include mq('tablet') {
          margin-top: 80px;
        }

        @include mq('large') {
          margin-top: var(--emu-common-spacing-none);
        }
      }

      &-title {
        & .emu-title__text {
          padding-top: 50px;
          padding-bottom: 30px;
          margin-bottom: var(--emu-common-spacing-small);

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-xxl);
            line-height: var(--emu-semantic-line-heights-wide-xxl);
            padding-left: 15px;
            padding-right: 15px;
          }

          br {
            &:first-child {
              @include mq('medium') {
                display: none;
              }
            }
          }
        }
      }

      &-left-fade-img,
      &-right-fade-img {
        position: absolute;
        top: 0;
        height: 100%;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: var(--emu-semantic-colors-secondary-500);
        }

        img {
          height: 100%;
        }
      }

      &-left-fade-img {
        left: 0;
      }

      &-right-fade-img {
        right: 0;
      }

      &-carousel {
        padding-left: 13px;
        padding-right: 13px;
        margin-bottom: -5.6px;

        @include mq('medium') {
          @media (hover: hover) {
            // when one of the items is hovered
            &:has(.results__banner-carousel-item:hover) {
              // show a white overlay on the rest of the items
              .results__banner-carousel-item:not(:hover) {
                &::before {
                  background-color: var(--emu-semantic-colors-secondary-500);
                }
              }
            }
          }
        }

        @include mq('medium') {
          margin-bottom: var(--emu-common-spacing-none);
          padding-left: 28px;
          padding-right: 28px;
        }

        &-wrapper {
          padding-bottom: 136px;

          @include mq('medium') {
            padding-bottom: var(--emu-common-spacing-none);
          }
        }

        &-content {
          position: relative;
          cursor: pointer;
        }

        &-item {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: var(--emu-common-colors-transparent);
            pointer-events: none;
          }
        }

        &-title {
          & .emu-title__text {
            position: absolute;
            top: 20px;
            font-size: var(--emu-common-font-sizes-wide-medium);
            line-height: 25px;
            border-bottom: var(--emu-common-border-width-medium) solid
              var(--emu-common-colors-white);
            left: 15px;
            width: 80%; //as per live

            @include mq('medium') {
              width: 90%; //as per live
              left: 20px;
            }
          }
        }

        &-img {
          @include mq('xx-large') {
            img {
              width: 100%;
            }
          }
        }

        &-expand {
          font-family: var(--emu-semantic-font-families-mono);
          position: absolute;
          bottom: 15px;
          left: 20px;
          font-size: var(--emu-common-font-sizes-narrow-medium);
          line-height: var(--emu-common-line-heights-narrow-medium);
          font-weight: var(--emu-common-font-weight-regular);
          pointer-events: none;
        }

        & .emu-carousel {
          &__action {
            top: auto;
            bottom: -53px;

            &-prev {
              left: var(--emu-common-spacing-xxs);

              @include mq('medium') {
                display: none;
              }
            }

            &-next {
              right: var(--emu-common-spacing-xxs);

              @include mq('medium') {
                top: 28px;
                right: 20px;
                height: 17px; // to match live
              }

              & .emu-carousel__action-icon {
                @include mq('medium') {
                  background-image: url('./assets/images/next-white-arrow.png');
                  width: 42px; // to match live
                  height: 17px; // to match live
                }
              }
            }
          }
        }
      }

      &-view-gallery {
        position: absolute;
        font-weight: var(--emu-semantic-font-weight-500);
        bottom: -37px;
        left: 50%;
        transform: translateX(-50%);
        font-size: var(--emu-semantic-font-sizes-wide-xs);
        text-decoration: underline;
      }

      &-footnote {
        font-size: 11px;
        line-height: 1.5;
        font-family: var(--emu-semantic-font-families-mono);
        font-weight: var(--emu-common-font-weight-regular);
        margin-bottom: var(--emu-common-spacing-medium);
        position: absolute;
        bottom: 9px;
        width: 100%;

        @include mq('medium') {
          font-size: var(--emu-semantic-font-sizes-narrow-xs);
          bottom: -50px;
        }
      }

      &-main-content {
        padding-bottom: 60px;

        @include mq('medium') {
          padding-bottom: var(--emu-common-spacing-none);
        }

        &.aaaem-container {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }
      }

      &-close-btn {
        &.emu-button.emu-button-v2.emu-button__primary-filled {
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-none);
          padding-left: 5px;
          padding-right: 5px;
          margin-bottom: 15px;
          background-color: var(--emu-common-colors-transparent);
          color: #333; // used once
          border: none;
          display: block;
          width: auto;
          margin-left: auto;
          font-family: var(--emu-semantic-font-families-mono);
          font-size: var(--emu-semantic-font-sizes-wide-xs);
          line-height: 21px;
          font-weight: var(--emu-common-font-weight-regular);

          @include mq('medium') {
            padding-left: 25px;
            padding-right: 25px;
          }

          @include mq('large') {
            padding-left: 5px;
            padding-right: 5px;
          }
        }
      }

      &-accordion {
        margin-left: 20px;
        margin-right: 20px;

        &:has(dd:last-of-type.-ba-is-active) {
          border-bottom: var(--emu-common-border-width-thin) solid
            var(--emu-semantic-colors-primary-100);
        }

        .aaaem-accordion {
          &__item {
            padding: var(--emu-common-spacing-none);
          }

          &__header {
            padding-top: 20px;
            padding-bottom: 20px;
            padding-right: var(--emu-common-spacing-none);
            border-bottom-color: var(--emu-semantic-colors-primary-100);

            &[aria-expanded='true'] {
              .aaaem-accordion__icon {
                &::before {
                  transform: rotate(-45deg);
                  bottom: var(--emu-common-spacing-xs);
                }
              }
            }

            &.-ba-is-active {
              border: none;
              border-bottom: var(--emu-common-border-width-thin) solid
                rgba(0, 0, 0, 0.125);
              margin-bottom: -1px; // as per live site
            }
          }

          &__title {
            font-family: var(--emu-semantic-font-families-mono);
            font-size: var(--emu-common-font-sizes-wide-medium);
            line-height: 1.5;
            font-weight: var(--emu-common-font-weight-regular);
            color: var(--emu-semantic-colors-primary-100);
          }

          &__button {
            position: relative;
          }

          &__icon {
            width: 21px; // to match live
            height: 21px; // to match live
            position: absolute;
            margin-left: var(--emu-common-spacing-none);
            right: 7.5px;
            bottom: var(--emu-common-spacing-xxs);

            &::before {
              content: '';
              position: absolute;
              width: 15px; // to match live
              height: 15px; // to match live
              border-top: var(--emu-common-border-width-medium) solid
                var(--emu-semantic-colors-primary-100);
              border-right: var(--emu-common-border-width-medium) solid
                var(--emu-semantic-colors-primary-100);
              margin-top: var(--emu-common-spacing-none);
              right: 0;
            }
          }

          &__panel {
            position: relative;
            cursor: url('./assets/images/cursor_plus_white.png') 7 4, crosshair;

            &.-ba-is-active + .aaaem-accordion__item p {
              border-top: var(--emu-common-border-width-thin) solid
                var(--emu-semantic-colors-primary-100);
            }

            .js-badger-accordion-panel-inner {
              padding: var(--emu-common-spacing-none);
            }
          }
        }
      }

      &-tab-content {
        display: flex;

        > .container {
          flex: 0 0 50%; // as per live

          @include mq('medium') {
            flex: unset;
          }
        }
      }

      .custom-cursor__text {
        background: #ffffff8c; // color used only once
        color: var(--emu-common-colors-black);
      }

      &-before-content,
      &-after-content {
        position: relative;

        @include mq('medium') {
          cursor: url('./assets/images/cursor_plus_white.png') 7 4, crosshair;
          overflow: hidden;
        }
      }

      &-before-text,
      &-after-text {
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 10px;
        line-height: 1.5;
        font-weight: var(--emu-common-font-weight-regular);

        @include mq('medium') {
          font-size: var(--emu-common-font-sizes-wide-medium);
          left: 20px;
        }
      }

      &-before-img,
      &-after-img {
        background-position: center;
        background-repeat: no-repeat;
        user-select: none;

        @include mq('medium') {
          position: relative;
          overflow: hidden;
          width: 310px; // deduced as per live
          height: 455px; // deduced as per live
        }

        @include mq('large') {
          width: 485px; // deduced as per live
          height: 600px; // deduced as per live
        }

        img {
          // opacity: 0;
          width: 170px;
          height: 210px; // as per live
          object-fit: contain;
          object-position: center;
          margin-left: auto;
          margin-right: auto;
          pointer-events: none;

          &.animated {
            transition: all 0.25s ease-in-out;
          }

          @include mq('medium') {
            position: absolute;
            width: 375px;
            height: auto;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            max-width: none;
          }

          @include mq('large') {
            width: 490px;
          }
        }
      }

      &-before-img {
        img {
          @include mq('medium') {
            border-top-left-radius: var(--emu-common-border-radius-xs);
          }
        }
      }

      &-after-img {
        img {
          @include mq('medium') {
            border-top-right-radius: var(--emu-common-border-radius-xs);
          }
        }
      }

      &-disclaimer {
        margin-top: 5px;
        margin-bottom: 30px;
        padding-left: 10px;
        padding-right: 10px;
        font-family: var(--emu-semantic-font-families-mono);
        font-size: 8px;
        line-height: 12px;
        font-weight: var(--emu-common-font-weight-regular);
        cursor: url('./assets/images/cursor_plus_white.png') 7 4, crosshair;

        @include mq('medium') {
          font-size: 12px;
          line-height: var(--emu-common-line-heights-narrow-large);
          margin-top: 29px;
          margin-bottom: 20px;
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
          width: 620px; // to match live
        }

        @include mq('large') {
          width: 100%;
          margin-top: 5px;
          margin-bottom: var(--emu-common-spacing-none);
        }
      }

      &-desktop-tabs {
        @include mq('medium') {
          display: flex;
          position: relative;
        }

        @include mq('large') {
          padding-bottom: 60px;
        }

        > .aaaem-tabs__tablist {
          @include mq('medium') {
            flex: 0 0 16.67%; // as per live
            max-width: 16.67%; // as per live
          }

          > .aaaem-tabs__tab {
            font-size: var(--emu-common-font-sizes-wide-medium);
            line-height: 24.5px; // to match spacing
            margin-left: 13.5px;

            @include mq('x-large') {
              margin-left: var(--emu-common-spacing-none);
            }

            a {
              &[aria-selected='true'] {
                color: var(--emu-semantic-colors-primary-100);
                font-weight: var(--emu-semantic-font-weight-500);
              }
            }
          }
        }

        > .aaaem-tabs__tabpanels {
          @include mq('medium') {
            flex: 0 0 83.33%; // as per live
            max-width: 83.33%; // as per live
          }
        }
      }

      &-next-btn {
        display: none;

        @include mq('medium') {
          display: inline-flex;
          position: absolute;
          right: 65px;
          bottom: 10px;
        }

        @include mq('large') {
          right: 5px;
          bottom: 43px;
        }

        &.emu-button.emu-button-v2.emu-button__primary-filled {
          padding: var(--emu-common-spacing-none);
          background-color: var(--emu-common-colors-transparent);
          border: none;
        }

        span {
          background-image: url('./assets/images/next-arrow.png');
          background-size: 20px 16.5px;
          width: 20px; // to match live
          height: 16.5px; // to match live
        }
      }
    }

    &__cards {
      padding-top: 50px;
      padding-bottom: 50px;

      @include mq('medium') {
        padding-top: 60px;
        padding-bottom: 60px;
      }

      @include mq('large') {
        padding-top: 120px;
        padding-bottom: 120px;
      }

      &-heading {
        margin-bottom: 40px;

        .cmp-title__text {
          font-weight: var(--emu-common-font-weight-light);
          line-height: 40px;

          @include mq('medium') {
            font-size: 35px;
            line-height: 42px;
            font-weight: var(--emu-common-font-weight-regular);
          }

          br {
            display: block;

            @include mq('medium') {
              display: none;
            }
          }
        }
      }

      &-grid {
        @include mq('small-plus') {
          display: flex;
        }

        @include mq('medium') {
          width: 91%; //as per live
          margin-left: auto;
          margin-right: auto;
        }

        @include mq('large') {
          width: 100%; //as per live
        }
      }

      &-content {
        margin-bottom: 10px;

        @include mq('medium') {
          margin-bottom: var(--emu-common-spacing-none);
        }

        &-footer {
          padding-top: 65px;
          padding-bottom: 65px;
          padding-left: 40px;
          padding-right: 40px;

          @include mq('medium') {
            padding-top: 50px;
            padding-bottom: 50px;
            padding-left: 15px;
            padding-right: 15px;
          }

          @include mq('large') {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 30px;
            padding-bottom: 30px;
            padding-left: 65px;
            padding-right: 65px;
            min-height: 320px; //as per live
          }
        }

        &-title {
          margin-bottom: 12px;

          .cmp-title__text {
            font-size: 26px;
            line-height: 1.2;

            @include mq('medium') {
              font-size: 24px;
            }

            @include mq('large') {
              font-size: var(--emu-semantic-font-sizes-wide-large);
              line-height: var(--emu-semantic-line-heights-wide-large);
            }
          }
        }

        &-text {
          font-size: var(--emu-common-font-sizes-wide-medium);
          line-height: 22px;

          @include mq('medium') {
            font-size: 15px;
            line-height: 25px;
          }

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-small);
          }

          sup {
            font-size: 13px;
          }
        }
      }
    }

    &__weekly-treatment-sec {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      padding-bottom: 50px;

      @include mq('medium') {
        padding-bottom: 80px;
      }

      &-list {
        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin-bottom: var(--emu-common-spacing-medium);

          li {
            padding-left: var(--emu-common-spacing-xs);
            padding-right: var(--emu-common-spacing-xs);

            &.item-active {
              a {
                font-weight: var(--emu-common-font-weight-bold);
                cursor: pointer;

                &::before {
                  content: '';
                  position: absolute;
                  left: 0;
                  bottom: -3px;
                  width: 100%;
                  height: 6px; // to match live
                  background-color: var(--emu-semantic-colors-primary-100);
                }
              }
            }

            a {
              font-size: var(--emu-semantic-font-sizes-narrow-medium);
              line-height: 42px;
              font-weight: var(--emu-common-font-weight-light);
              color: var(--emu-semantic-colors-primary-100);
              padding-left: 9px;
              padding-right: 9px;
              position: relative;

              &::after {
                content: '';
                position: absolute;
                right: -5px;
                top: 15%;
                width: var(--emu-common-sizing-xxs); // as per live
                height: 70%; //as per live
                background-color: var(--emu-semantic-colors-primary-100);
              }
            }
          }

          li:nth-child(3) a::after {
            content: none;
          }
        }
      }

      &-carousel {
        padding-left: 20px;
        cursor: pointer;

        @include mq('medium') {
          width: 90%; //as per live
          margin-left: auto;
          margin-right: auto;
          padding-left: var(--emu-common-spacing-none);
        }

        @include mq('1025px') {
          width: 100%;
        }

        & .emu-carousel {
          &__item {
            &.slide-active {
              .aaaem-carousel__item-container {
                .emu-title__text {
                  font-weight: var(--emu-semantic-font-weight-500);
                }

                .results__weekly-treatment-sec-carousel-img {
                  &::before {
                    content: '';
                    position: absolute;
                    top: -8px;
                    width: 100%;
                    height: var(--emu-common-sizing-xs); // as per live
                    background-color: var(--emu-semantic-colors-primary-100);

                    @include mq('medium') {
                      top: 0;
                      height: 5px; // as per live
                    }
                  }

                  &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: var(--emu-common-sizing-xs); // as per live
                    background-color: var(--emu-semantic-colors-primary-100);

                    @include mq('medium') {
                      bottom: 0;
                      height: 5px;
                    }
                  }
                }

                .results__weekly-treatment-sec-carousel-footer {
                  p {
                    color: var(--emu-semantic-colors-primary-100);
                    font-weight: var(--emu-common-font-weight-bold);

                    @include mq('medium') {
                      font-weight: var(--emu-common-font-weight-regular);
                    }
                  }
                }
              }
            }
          }

          &__action {
            top: 75.5%;

            @include mq('419px') {
              top: 80%;
            }

            &-prev {
              left: -5px;
            }

            &-next {
              right: 15px;
            }
          }
        }

        &-header {
          .emu-title__text {
            font-weight: var(--emu-common-font-weight-light);
            font-size: 25px;
            line-height: 1.2;
            padding-bottom: 15px;

            @include mq('medium') {
              padding-bottom: var(--emu-common-spacing-none);
            }
          }
        }

        &-img {
          position: relative;

          @include mq('medium') {
            padding-top: 25px;
            padding-bottom: 25px;
          }

          img {
            width: 100%;
          }
        }

        &-footer {
          padding-top: 45px;
          font-size: var(--emu-common-font-sizes-wide-medium);
          line-height: 1.5;
          color: #36363691; //used once
          font-weight: var(--emu-common-font-weight-regular);

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-xs);
            padding-top: 15px;
          }

          @include mq('large') {
            font-size: var(--emu-common-font-sizes-wide-medium);
          }

          b {
            display: block;
          }
        }
      }

      &-footnote {
        padding-left: 20px;
        padding-right: 10px;
        margin-top: 20px;
        font-size: 10px;
        line-height: 1.5;
        font-family: var(--emu-semantic-font-families-mono);
        font-weight: var(--emu-common-font-weight-regular);
        margin-bottom: 5px;

        @include mq('medium') {
          width: 90%; //as per live
          margin-left: auto;
          margin-right: auto;
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
          font-size: 11px;
        }

        @include mq('1025px') {
          width: 100%;
          font-size: var(--emu-common-font-sizes-narrow-medium);
        }
      }
    }

    &__patient-result {
      position: relative;

      &-img {
        img {
          width: 100%;
        }
      }

      &-text {
        font-size: 11px;
        line-height: 12px;
        padding-left: 20px;
        padding-right: 20px;
        position: absolute;
        bottom: 12px;

        @include mq('medium') {
          padding-left: 40px;
          padding-right: 40px;
          font-size: var(--emu-common-font-sizes-narrow-medium);
          line-height: var(--emu-common-line-heights-narrow-medium);
        }
      }
    }
  }
}
