.aaaem-tabs {
  &__tab {
    a {
      @include mq('large') {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
