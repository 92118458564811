// needed twice to increase the specificity
.emu-modal.emu-modal {
  .modal {
    &-wrapper {
      width: auto;
      display: block;
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      transition: all 0.3s ease-out;
      transition-delay: 0.3s;
      display: flex;
      align-items: center;

      @include mq('medium') {
        width: 100%;
      }

      .modal-content {
        transform: translateY(-50px);
        transition: all 0.3s ease-out;
        transition-delay: var(--emu-common-other-time-delay-none);
        opacity: 0;
        margin-left: var(--emu-common-spacing-small);
        margin-right: var(--emu-common-spacing-small);
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
        width: auto;

        @include mq('small-plus') {
          margin-left: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);
        }

        @include mq('medium') {
          margin-left: auto;
          margin-right: auto;
        }
      }

      &.is-open {
        opacity: 1;
        background-color: var(--emu-semantic-colors-secondary-100);
        visibility: visible;
        transition-delay: var(--emu-common-other-time-delay-none);
        pointer-events: initial;

        .modal-content {
          transform: translateY(0);
          transition-delay: 0.3s;
          opacity: 1;
        }
      }

      .close {
        text-shadow: 0 1px 0 var(--emu-common-colors-white);
        opacity: 0.5;
        top: 14px;
        right: -6px;
        display: flex;
        width: 96px; // to match live
        justify-content: end;
        z-index: var(--emu-common-other-z-index-layer);

        @include mq('medium') {
          top: 28px;
          right: var(--emu-common-spacing-xs);
        }

        &:hover {
          opacity: 0.75;
        }

        span {
          font-size: 20px;
          font-family: var(--emu-semantic-font-families-mono);
          font-weight: var(--emu-common-font-weight-bold);
          line-height: 1;
          margin-top: var(--emu-common-spacing-xs);
        }
      }
    }
  }
}
