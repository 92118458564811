.aaaem-tabs--treatment {
  display: flex;
  flex-direction: column-reverse;

  .aaaem-tabs {
    &__tablist {
      margin-top: 15px;
      padding-bottom: 10px;
      justify-content: space-evenly;

      @include mq('medium') {
        padding-bottom: var(--emu-common-spacing-none);
        flex-direction: column;
        position: absolute;
        left: var(--emu-common-spacing-medium);
        bottom: 70px;
      }

      @include mq('x-large') {
        left: 0;
      }
    }

    &__tab {
      font-size: 10px;
      line-height: 1.5;
      margin-bottom: 15px;

      @include mq('large') {
        font-size: 14px;
      }

      a {
        display: flex;
        align-items: center;

        &::before {
          background-size: 25px; // to match live
          width: 25px; // to match live
          height: 25px; // to match live
          display: inline-block;

          @include mq('large') {
            background-size: 40px; // to match live
            width: 40px; // to match live
            height: 40px; // to match live
          }
        }

        &[aria-selected='true'] {
          font-weight: var(--emu-semantic-font-weight-500);
        }
      }

      span {
        padding-left: 5px;

        @include mq('large') {
          padding-left: 10px;
        }

        @include mq('x-large') {
          padding-left: 15px;
        }
      }

      &:nth-child(1) {
        a {
          &::before {
            content: '';
            background-image: url('../../assets/images/eye-open-icon.png');
          }

          &[aria-selected='true'] {
            &::before {
              background-image: url('../../assets/images/eye-open-icon-active.png');
            }
          }
        }
      }

      &:nth-child(2) {
        a {
          &::before {
            content: '';
            background-image: url('../../assets/images/eye-close-icon.png');
          }

          &[aria-selected='true'] {
            &::before {
              background-image: url('../../assets/images/eye-close-icon-active.png');
            }
          }
        }
      }

      &:nth-child(3) {
        a {
          &::before {
            content: '';
            background-image: url('../../assets/images/eye-angle-icon.png');
          }

          &[aria-selected='true'] {
            &::before {
              background-image: url('../../assets/images/eye-angle-icon-active.png');
            }
          }
        }
      }
    }
  }
}
