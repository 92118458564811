.aaaem-accordion {
  &__item {
    padding-top: 12px;
    padding-bottom: 11px;
    padding-right: 20px;

    &.u-hide + .aaaem-accordion__panel {
      display: none;
    }
  }

  &__title {
    font-weight: var(--emu-semantic-font-weight-500);
  }

  &__icon {
    height: auto;
    width: auto;
    display: block;
    align-self: end;

    &::before {
      content: '+';
      font-size: var(--emu-semantic-font-sizes-wide-small);
      line-height: var(--emu-semantic-line-heights-wide-small);
      font-weight: var(--emu-semantic-font-weight-500);
      position: unset;
      transform: unset;
    }

    &::after {
      display: none;
    }
  }

  &__header {
    &[aria-expanded='true'] {
      .aaaem-accordion__icon {
        &::before {
          content: 'x';
        }
      }
    }
  }
}
