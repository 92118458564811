html {
  -webkit-text-size-adjust: 100%;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
span,
button {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  color: inherit;
}

body {
  color: var(--emu-semantic-colors-primary-50);
}

html,
body {
  // Adobe adds a negative margin, need to overwrite. Do not remove.
  .xf-content-height {
    margin: inherit;
  }

  .experiencefragment {
    padding: var(--emu-common-spacing-none);
  }
}

body {
  -webkit-overflow-scrolling: touch;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*,
::before,
::after {
  box-sizing: border-box;
}

.cmp-image__image,
image {
  max-width: 100%;
  width: auto;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

body {
  max-width: var(--emu-common-sizing-container-large);
  margin: auto;
}

sup {
  font-size: 65%;
  line-height: 0;
  vertical-align: baseline;
  position: relative;
  top: -0.45em;
}
