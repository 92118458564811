[data-component='carousel-v2'].emu-carousel {
  &[data-nav='true'] {
    padding-bottom: var(--emu-common-spacing-none);
  }

  .tns-nav {
    bottom: 25px;

    button {
      border-radius: var(--emu-common-border-radius-none);
      width: 40px; // as per live

      &:not(:last-child) {
        margin-right: 10px;
      }

      @include mq('medium') {
        width: 30px; // as per live
      }

      @include mq('tablet') {
        width: 55px; // as per live
      }
    }
  }

  .emu-carousel__action {
    span {
      svg {
        display: none;
      }

      &::before {
        display: block;
      }
    }

    &-icon {
      background-size: 45%;
      background-repeat: no-repeat;
      display: block;
      width: 46px; // to match live
      height: 25px; // to match live
    }

    &-prev {
      & .emu-carousel__action-icon {
        background-image: url('../../assets/images/prev-arrow.png');
      }
    }

    &-next {
      & .emu-carousel__action-icon {
        background-image: url('../../assets/images/next-arrow.png');
        background-position: right top;
      }
    }
  }
}
