#about-latisse {
  .about {
    &__banner {
      background-color: var(--emu-semantic-colors-primary-600);
      margin-top: -15px;
      min-height: 750px; // as per live
      position: relative;

      @include mq('medium') {
        min-height: var(--emu-common-sizing-xxs);
        margin-top: 35px;
        padding-top: 75px;
        padding-bottom: 75px;
        padding-left: 35px;
        padding-right: 35px;
      }

      @include mq('tablet') {
        margin-top: var(--emu-common-spacing-none);
      }

      @include mq('large') {
        margin-top: -115px;
        min-height: 750px; // as per live
        background-size: cover;
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: var(--emu-common-spacing-none);
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      &-bg {
        > .image {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: var(--emu-semantic-colors-primary-600);
          z-index: var(--emu-common-other-z-index-base);

          @include mq('medium') {
            background-color: var(--emu-common-colors-white);
          }
        }

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: contain;
          object-position: bottom;

          @include mq('medium') {
            object-fit: contain;
            object-position: top;
          }

          // media query needed to match the live site
          @include mq('830px') {
            object-fit: cover;
          }

          @include mq('large') {
            object-position: left;
          }

          @include mq('x-large') {
            object-position: left top;
          }
        }
      }

      &-content-wrapper.aaaem-container {
        position: relative;
        z-index: var(--emu-common-other-z-index-layer);

        @include mq('medium') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }
      }

      &-content {
        min-height: 700px; // as per live
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include mq('medium') {
          min-height: var(--emu-common-sizing-xxs);
          max-width: 50%;
          margin-left: auto;
          padding-left: 15px;
          padding-right: 15px;
        }

        @include mq('large') {
          min-height: 700px; // as per live
        }
      }

      &-title {
        margin-bottom: 30px;

        // tag needed as there are special styles and this is from an RTE
        h1 {
          font-size: var(--emu-semantic-font-sizes-wide-xxl);
          line-height: 1;
          text-align: center;
          color: var(--emu-semantic-colors-primary-100);

          @include mq('medium') {
            font-size: 56px;
            line-height: 62px;
            width: fit-content; // as per live
            margin-left: auto;
            margin-right: auto;
            text-align: left;
          }

          @include mq('large') {
            font-size: 80px;
            line-height: 70px;
            margin-top: 30px;
            text-indent: -40px;
          }

          span {
            color: var(--emu-semantic-colors-primary-300);

            @include mq('large') {
              display: block;
              position: relative;
              top: -10px;
              text-indent: -55px;
            }
          }
        }
      }

      &-text {
        max-width: 95%; // as per live
        margin-left: auto;
        margin-right: auto;

        @include mq('medium') {
          max-width: 100%;
          margin-bottom: 30px;
        }

        @include mq('large') {
          max-width: 75%; // as per live
        }
      }
    }

    &__how-to-use {
      padding-top: 75px;
      padding-bottom: 75px;

      &-title {
        margin-bottom: 35px;

        .cmp-title__text {
          line-height: 56px;

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-xxl);
          }
        }
      }

      &-text {
        max-width: 90%; // as per live
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;

        @include mq('medium') {
          max-width: 710px; // as per live
          margin-bottom: 35px;
          font-size: 20px;
          line-height: 30px;
        }
      }
    }

    &__steps {
      @include mq('medium') {
        display: flex;
        margin-left: -15px; // to match live
        margin-right: -15px; // to match live
      }

      > .container {
        @include mq('medium') {
          flex: 0 0 33.33%; // as per live
          max-width: 33.33%; // as per live
        }
      }
    }

    &__step {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;

      @include mq('medium') {
        padding-left: 35px;
        padding-right: 35px;
      }

      &-img {
        img {
          max-width: 150px; // as per live
          max-height: 150px; // as per live
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 10px;
        }
      }

      &-title {
        margin-top: 20px;
        margin-bottom: 20px;

        .cmp-title__text {
          font-size: var(--emu-semantic-font-sizes-narrow-small);
          line-height: 56px;
        }
      }

      &-desc {
        width: 90%; // as per live
        margin-left: auto;
        margin-right: auto;

        @include mq('medium') {
          font-size: 20px;
          line-height: 30px;
          width: 100%;
        }

        p {
          margin-bottom: 15px;

          @include mq('medium') {
            margin-bottom: var(--emu-common-spacing-medium);
          }
        }

        span {
          color: var(--emu-semantic-colors-primary-100);
          font-weight: var(--emu-common-font-weight-bold);
          display: inline-block;
          text-indent: -1em;
        }
      }
    }

    &__imp-reminders {
      padding-top: 65px;
      padding-bottom: 45px;
      padding-left: 15px;
      padding-right: 15px;

      @include mq('medium') {
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 65px;
      }

      @include mq('large') {
        padding-top: 75px;
        padding-bottom: 75px;
        padding-left: 75px;
        padding-right: 75px;
      }

      &-wrapper {
        @include mq('medium') {
          display: flex;

          &.aaaem-container {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }

          > .container {
            flex: 0 0 50%;
            max-width: 50%; // as per live
            padding-left: 15px;
            padding-right: 15px;
          }
        }
      }

      &-title {
        margin-bottom: 15px;

        .cmp-title__text {
          line-height: 65px;

          @include mq('medium') {
            text-align: left;
          }

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xxl);
          }
        }
      }

      &-desc {
        max-width: 95%; // as per live
        margin-bottom: 35px;
        padding-bottom: 35px;
        font-weight: var(--emu-semantic-font-weight-500);
        border-bottom: var(--emu-common-border-width-thin) solid
          var(--emu-semantic-colors-secondary-200);

        @include mq('medium') {
          max-width: 75%; // as per live
        }

        @include mq('large') {
          font-size: 18px;
          line-height: 27px;
        }
      }

      &-list {
        ul {
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-medium);
          padding-left: var(--emu-common-spacing-medium);
          max-width: 95%; // as per live

          li {
            margin-bottom: 15px;
            line-height: 1.2;

            @include mq('medium') {
              font-size: 20px;
              margin-bottom: 10px;
            }
          }
        }
      }

      &-img {
        padding-top: 25px;
        margin-bottom: 15px;

        @include mq('medium') {
          padding-top: var(--emu-common-spacing-none);
        }

        img {
          width: 100%;
        }
      }

      &-caption {
        font-weight: var(--emu-common-font-weight-regular);
        font-size: var(--emu-common-font-sizes-wide-medium);
        line-height: 1.5;

        @include mq('medium') {
          font-size: 18px;
        }
      }
    }

    &__faq {
      padding-top: 48px;
      padding-bottom: 48px;
      background-size: 0 !important;

      @include mq('medium') {
        background-position: center;
        background-size: cover !important;
        background-repeat: no-repeat;
      }

      @include mq('large') {
        background-size: 100% !important; // needed to override the inline styles from AEM
        background-position: unset;
      }

      &-wrapper {
        &.aaaem-container {
          @include mq('medium') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            display: flex;

            > .title {
              flex: 0 0 50%;
              max-width: 50%; // as per live
            }

            > .container {
              flex: 0 0 50%;
              max-width: 50%; // as per live
            }
          }
        }
      }

      &-title {
        padding-top: 24px;
        padding-bottom: 24px;
        padding-left: 24px;
        padding-right: 24px;

        @include mq('medium') {
          padding-right: 95px;
          padding-left: 39px;
        }

        .cmp-title__text {
          @include mq('medium') {
            text-align: right;
          }
        }
      }

      &-accordion-wrapper {
        @include mq('medium') {
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      &-accordion-text {
        @include mq('large') {
          font-size: 18px;
          line-height: 27px;
        }
      }

      &-cta {
        &.emu-button.emu-button-v2.emu-button__primary-outline {
          padding-top: 1px;
          padding-bottom: 1px;
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
          border-width: var(--emu-common-border-width-none);
          border-bottom: var(--emu-common-border-width-thin) solid
            var(--emu-semantic-colors-primary-50);
          margin-top: 24px;
          margin-bottom: 24px;

          span {
            line-height: var(--emu-common-line-heights-wide-large);
            font-weight: var(--emu-common-font-weight-regular);
            color: var(--emu-semantic-colors-primary-50);
          }
        }
      }
    }
  }
}

.about__banner-bg {
  @include aem-editor-view {
    height: 600px !important;
    position: static !important;
  }

  img {
    @include aem-editor-view {
      object-fit: contain !important;
    }
  }
}
