.alle-fap {
  @include mq('medium') {
    display: flex;

    > .container {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .alle {
    &__container {
      position: relative;
    }

    &__content-container {
      position: absolute;
      right: 0;
      top: 10%;

      @include mq('medium') {
        top: 15%;
      }

      @include mq('large') {
        right: 5%;
        top: 20%;
      }
    }

    &__logo {
      img {
        max-width: 80px; // as per live
        margin-left: auto;
        margin-right: auto;

        @include mq('large') {
          max-width: 150px; // as per live
        }
      }
    }

    &__content {
      max-width: 65%; // as per live
      margin-top: 15px;
      margin-bottom: 15px;
      margin-left: auto;
      margin-right: auto;
      font-weight: var(--emu-common-font-weight-regular);
      font-size: var(--emu-common-font-sizes-narrow-medium);
      line-height: 14px;

      @include mq('medium') {
        font-size: 14px;
        line-height: 16px;
        max-width: 60%; // as per live
      }

      @include mq('large') {
        font-size: 20px;
        line-height: 26px;
        max-width: 65%; // as per live
      }

      a {
        text-decoration: none;
      }

      span.emphasis {
        color: var(--emu-semantic-colors-primary-100);
        font-size: 20px;
        line-height: 1.5;
        text-decoration: underline;
        text-underline-position: under;

        @include mq('medium') {
          font-size: 18px;
        }

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-small);
        }
      }

      p {
        @include mq('medium') {
          &:not(:last-child) {
            margin-bottom: var(--emu-common-spacing-medium);
          }
        }
      }
    }
  }

  .fap {
    &__container {
      padding-top: 60px;
      padding-bottom: 60px;
      padding-left: 15px;
      padding-right: 15px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include mq('medium') {
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: var(--emu-common-spacing-none);
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
        height: 100%;
      }

      .cmp-form {
        display: flex;

        &-text {
          text-align: center;

          &.has-error {
            .error-message {
              display: none;
            }
          }

          &__text {
            border-radius: var(--emu-common-border-radius-none);
            background-color: var(--emu-common-colors-transparent);
            border: var(--emu-common-border-width-thin) solid
              var(--emu-semantic-colors-primary-300);
            height: 40px; // as per live
            width: 210px; // as per live
            font-weight: var(--emu-common-font-weight-regular);
            font-size: var(--emu-common-font-sizes-wide-medium);
            line-height: var(--emu-common-line-heights-wide-large);
            color: var(--emu-common-colors-white);

            &::placeholder {
              color: var(--emu-common-colors-white);
              text-align: center;
            }
          }
        }

        &-button {
          font-size: 0px; // making sure that the content of the button is not visible
          line-height: 0px; // making sure that the content of the button is not visible
          height: 40px; // as per live
          width: 39px; // as per live
          background-color: var(--emu-semantic-colors-secondary-400);

          @include mq('large') {
            transform: translateY(-1.5px);
          }

          &::before {
            content: '';
            background-image: url('../../assets/images/search.png');
            background-size: cover;
            display: block;
            height: 20px; // as per live
            width: 20px; // as per live
            position: absolute;
            transform: translate(47%, -40%);
          }
        }
      }
    }

    &__title {
      margin-bottom: 15px;
    }

    &__text {
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: var(--emu-common-spacing-medium);

      @include mq('medium') {
        font-size: var(--emu-common-font-sizes-wide-medium);
      }
    }
  }
}
