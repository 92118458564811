header.experiencefragment {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: var(--emu-common-other-z-index-header);
}

.skip-menu-container a.skip-menu {
  position: absolute;
}

.header {
  &__eyebrow {
    &-dropdown-wrapper {
      > .button {
        top: 11px;
        right: 31px;
        position: absolute;
        z-index: calc(
          var(--emu-common-other-z-index-header) + 1
        ); // to match live
      }

      &:has(.header__eyebrow-container.js-toggle-on) {
        > .button {
          top: 16px;
        }
      }
    }

    &-wrapper.aaaem-container {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);

      @include mq('tablet') {
        display: flex;
        justify-content: center;
        padding-left: 15px;
        padding-right: 15px;
        padding-right: 45px;
      }

      > .button:not(:last-child) {
        @include mq('tablet') {
          display: flex;
          align-items: center;
        }
      }
    }

    &-container {
      position: absolute;
      width: 100%;
      z-index: calc(
        var(--emu-common-other-z-index-header) + 1
      ); // to match live
      transform: translateY(-100%) translateY(41px);
      transition: transform 0.3s ease-in-out;

      @include mq('tablet') {
        position: relative;
        transform: unset;
      }

      &.js-toggle-on {
        transform: unset;

        .header__eyebrow-items {
          ul {
            li:last-child {
              padding-top: 15px;
              padding-bottom: 17px;
            }
          }
        }
      }
    }

    &-btn {
      position: absolute;

      &.emu-button__primary-filled.emu-button.emu-button-v2 {
        padding: var(--emu-common-spacing-none);

        @include mq('tablet') {
          display: none;
        }
      }

      &::before {
        content: '';
        position: absolute;
        width: 10px; // as per live
        height: 10px; // as per live
        border-right: var(--emu-common-border-width-medium) solid
          var(--emu-common-colors-white);
        border-top: var(--emu-common-border-width-medium) solid
          var(--emu-common-colors-white);
        transform: rotate(135deg);
      }

      &.js-toggle-on {
        &::before {
          transform: rotate(-45deg);
        }
      }
    }

    &-items {
      font-size: var(--emu-semantic-font-sizes-narrow-xs);
      line-height: normal;

      ul {
        @include mq('tablet') {
          display: flex;
        }
      }

      li {
        padding-top: 15px;
        padding-bottom: 12px;

        @include mq('tablet') {
          border-bottom: none;
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-none);
          padding-left: 10px;
          padding-right: 10px;
          height: 35px; // as per live
          display: flex;
          align-items: center;
        }

        &:not(:last-child) {
          border-bottom: 1.5px solid var(--emu-common-colors-white);

          @include mq('tablet') {
            border-bottom: none;
          }
        }

        &:last-child {
          padding-top: 13px;
          padding-bottom: 10px;

          @include mq('tablet') {
            padding-top: var(--emu-common-spacing-none);
            padding-bottom: var(--emu-common-spacing-none);
          }
        }

        &.item-active {
          @include mq('tablet') {
            color: var(--emu-semantic-colors-primary-100);
            background-color: var(--emu-common-colors-white);
            font-weight: var(--emu-common-font-weight-bold);
          }
        }
      }

      a {
        text-decoration: none;
      }
    }
  }

  &__brand-menu {
    &-dropdown {
      display: none;
      position: absolute;
      top: 0;
      width: 100%;
      background-color: var(--emu-semantic-colors-primary-100);
      z-index: calc(
        var(--emu-common-other-z-index-header) + 1
      ); // to match live
      transition: all ease var(--emu-common-other-time-transition-xl);

      @include mq('tablet') {
        top: 35px;
        background-color: var(--emu-common-colors-white);
      }

      &--open {
        display: block;
      }
    }

    &-close-btn {
      position: absolute;
      top: 10px;
      right: 20px;

      @include mq('tablet') {
        top: 0;
      }

      span {
        font-weight: var(--emu-common-font-weight-regular);
        font-size: var(--emu-semantic-font-sizes-wide-xs);
        line-height: 21px;
        color: var(--emu-common-colors-white);

        @include mq('tablet') {
          color: var(--emu-semantic-colors-primary-50);
        }
      }

      &.emu-button.emu-button-v2.emu-button__primary-filled {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: var(--emu-common-spacing-none);
        background-color: var(--emu-common-colors-transparent);
        border-width: var(--emu-common-border-width-none);

        &:hover,
        &:active,
        &:focus {
          background-color: var(--emu-common-colors-transparent);
          color: inherit;
        }
      }
    }

    &-items {
      ul {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include mq('tablet') {
          flex-wrap: wrap;
          height: 95px; // as per live
        }
      }

      li {
        padding-top: 12px;
        padding-bottom: 15px;
        margin-top: 5px;
        margin-bottom: 5px;
        flex: 1 1 100%; // as per live

        @include mq('tablet') {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 13.5px;
          padding-bottom: 13.5px;
        }

        &:not(:last-child) {
          border-bottom: 1.5px solid var(--emu-common-colors-white);

          @include mq('tablet') {
            border-bottom: none;
            border-right: var(--emu-common-border-width-thin) solid
              var(--emu-semantic-colors-primary-100);
          }
        }
      }

      a {
        text-decoration: none;
        font-size: var(--emu-semantic-font-sizes-wide-xs);
        line-height: 21px;
        font-weight: var(--emu-common-font-weight-regular);
        display: block;
        color: var(--emu-common-colors-white);

        @include mq('tablet') {
          color: var(--emu-semantic-colors-primary-50);
          border-bottom: var(--emu-common-border-width-thin) solid
            var(--emu-common-colors-transparent);
        }

        @media (hover: hover) {
          &:hover {
            @include mq('tablet') {
              border-color: var(--emu-semantic-colors-primary-50);
            }
          }
        }
      }
    }
  }

  &__menu {
    background-color: var(--emu-semantic-colors-secondary-100);
    height: 60px; // as per live
    padding-top: 10px;
    padding-bottom: 10px;
    position: absolute;
    top: 45px;
    width: 100%;

    @include mq('medium') {
      top: 40px;
    }

    @include mq('tablet') {
      height: 95px; // as per live
      padding-top: var(--emu-common-spacing-none);
      padding-bottom: var(--emu-common-spacing-none);
      position: unset;
    }

    @include mq('large') {
      background-color: var(--emu-common-colors-transparent);
    }

    > .button {
      position: absolute;
      top: 20.5px;
      right: var(--emu-common-spacing-medium);

      @include mq('medium') {
        top: 24px;
      }

      @include mq('tablet') {
        display: none;
      }

      .emu-button.emu-button-v2 {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: var(--emu-common-spacing-none);
        border-width: var(--emu-common-border-width-none);

        span {
          background-image: url('../../assets/images/menu-hamburger.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          width: var(--emu-common-sizing-medium); // as per live
          height: 20px; // as per live
          display: block;
        }

        &.js-toggle-on {
          span {
            background-image: url('../../assets/images/menu-close.png');
            width: 24px; // as per live
          }
        }
      }
    }

    &-mobile {
      &-dropdown {
        display: block;
        position: absolute;
        width: 100%;
        left: 0;
        top: 100%;
        overflow: hidden;
        max-height: 0;
        transition: all 0.3s ease-in-out;

        @include mq('tablet') {
          position: relative;
          max-height: unset;
          overflow: visible;
        }

        > .container {
          pointer-events: none;
          padding-top: 11px;
          padding-bottom: 3px;
          background-color: var(--emu-semantic-colors-secondary-100);
          overflow: auto;
          max-height: 100vh;

          @include mq('medium') {
            padding-bottom: var(--emu-common-spacing-none);
            padding-top: 18px;
          }

          @include mq('tablet') {
            padding-top: var(--emu-common-spacing-none);
            pointer-events: initial;
            overflow: visible;
            position: static;
          }
        }

        &.js-toggle-on {
          max-height: 100vh; // for animation

          > .container {
            pointer-events: initial;
          }
        }

        &-wrapper {
          @include mq('tablet') {
            display: flex;
          }

          &.aaaem-container {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);

            @include mq('tablet') {
              padding-left: 15px;
              padding-right: 15px;
            }
          }

          > .navigationlist {
            @include mq('tablet') {
              flex: 0 0 41.666667%; // as per live
              max-width: 41.666667%; // as per live
            }
          }

          > .image {
            @include mq('tablet') {
              flex: 0 0 16.666667%; // as per live
              max-width: 16.666667%; // as per live
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          // right side nav
          .header__navigation-list {
            > ul {
              li:last-child {
                border-width: var(--emu-common-border-width-none);

                @include mq('tablet') {
                  border-bottom-width: var(--emu-common-border-width-thick);
                }

                a {
                  @include mq('medium') {
                    padding-bottom: 7px;
                  }

                  @include mq('tablet') {
                    padding-bottom: var(--emu-common-spacing-none);
                  }
                }
              }

              @include mq('tablet') {
                justify-content: flex-end;
              }
            }
          }
        }
      }
    }

    // mobile logo
    &-close-logo {
      display: flex;
      justify-content: center;

      @include mq('tablet') {
        display: none;
      }

      @include aem-editor-view {
        display: block;
      }

      img {
        width: 110px; // as per live

        @include mq('medium') {
          width: 128px; // to match live
        }
      }
    }

    // desktop logo
    &-open-logo {
      display: none;

      @include mq('tablet') {
        display: block;
      }

      @include aem-editor-view {
        display: block;
      }

      img {
        width: 160px; // to match live
      }
    }
  }
}
