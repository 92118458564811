.aaaem-title {
  &__color-black {
    color: var(--emu-common-colors-black);
  }

  &__color-white {
    color: var(--emu-common-colors-white);
  }

  &__color-primary {
    color: var(--emu-semantic-colors-primary-100);
  }
}
