:root {
  /**
  * @theme latisse-consumer-theme
  * @category common tokens
  */
  --emu-common-spacing-xs: 4px;
  --emu-common-spacing-xxs: 2px;
  --emu-common-spacing-small: 8px;
  --emu-common-spacing-medium: 16px;
  --emu-common-spacing-large: 32px;
  --emu-common-spacing-xl: 64px;
  --emu-common-spacing-none: 0px;
  --emu-common-spacing-unset: unset;
  --emu-common-colors-white: #FFFFFF;
  --emu-common-colors-black: #000000;
  --emu-common-colors-red-50: #FFF5F5;
  --emu-common-colors-red-100: #FFDCDB;
  --emu-common-colors-red-200: #FFABA8;
  --emu-common-colors-red-300: #FF7F7A;
  --emu-common-colors-red-400: #FF4E47;
  --emu-common-colors-red-500: #FF1D15;
  --emu-common-colors-red-600: #DB0700;
  --emu-common-colors-red-700: #A30500;
  --emu-common-colors-red-800: #700400;
  --emu-common-colors-red-900: #380200;
  --emu-common-colors-red-950: #190100;
  --emu-common-colors-grey-100: #F4F5F5;
  --emu-common-colors-grey-200: #D4D7D8;
  --emu-common-colors-grey-300: #B4B8BB;
  --emu-common-colors-grey-400: #949A9E;
  --emu-common-colors-grey-500: #7E868B;
  --emu-common-colors-grey-600: #61676B;
  --emu-common-colors-grey-700: #44484B;
  --emu-common-colors-grey-800: #303436;
  --emu-common-colors-grey-900: #1D1F20;
  --emu-common-colors-transparent: rgba(0,0,0,0);
  --emu-common-colors-teal-50: #E0FBFF;
  --emu-common-colors-teal-100: #C2F7FF;
  --emu-common-colors-teal-200: #85EFFF;
  --emu-common-colors-teal-300: #42E6FF;
  --emu-common-colors-teal-400: #05DEFF;
  --emu-common-colors-teal-500: #00ABC7;
  --emu-common-colors-teal-600: #00899E;
  --emu-common-colors-teal-700: #006675;
  --emu-common-colors-teal-800: #004752;
  --emu-common-colors-teal-900: #002329;
  --emu-common-colors-teal-950: #001214;
  --emu-common-colors-beige-50: #FEFCFB;
  --emu-common-colors-beige-100: #FDF9F7;
  --emu-common-colors-beige-200: #F9EFEB;
  --emu-common-colors-beige-300: #F7E8E3;
  --emu-common-colors-beige-400: #F5E2DB;
  --emu-common-colors-beige-500: #F2D9D0;
  --emu-common-colors-beige-600: #DFA18B;
  --emu-common-colors-beige-700: #CB6743;
  --emu-common-colors-beige-800: #8C4227;
  --emu-common-colors-beige-900: #482214;
  --emu-common-colors-beige-950: #24110A;
  --emu-common-colors-light-brown-50: #FAF6F5;
  --emu-common-colors-light-brown-100: #F5ECEA;
  --emu-common-colors-light-brown-200: #EAD7D2;
  --emu-common-colors-light-brown-300: #E0C4BD;
  --emu-common-colors-light-brown-400: #D5AEA5;
  --emu-common-colors-light-brown-500: #CA9A8E;
  --emu-common-colors-light-brown-600: #B4705F;
  --emu-common-colors-light-brown-700: #8E5243;
  --emu-common-colors-light-brown-800: #5E362C;
  --emu-common-colors-light-brown-900: #311C17;
  --emu-common-colors-light-brown-950: #180E0B;
  --emu-common-colors-medium-brown-50: #F6F0EF;
  --emu-common-colors-medium-brown-100: #EBDDDB;
  --emu-common-colors-medium-brown-200: #D6BCB8;
  --emu-common-colors-medium-brown-300: #C29A94;
  --emu-common-colors-medium-brown-400: #AD7971;
  --emu-common-colors-medium-brown-500: #925C54;
  --emu-common-colors-medium-brown-600: #754A43;
  --emu-common-colors-medium-brown-700: #573732;
  --emu-common-colors-medium-brown-800: #3A2522;
  --emu-common-colors-medium-brown-900: #1D1211;
  --emu-common-colors-medium-brown-950: #100A09;
  --emu-common-colors-dark-brown-50: #F1E4E5;
  --emu-common-colors-dark-brown-100: #E3CACB;
  --emu-common-colors-dark-brown-200: #C8989A;
  --emu-common-colors-dark-brown-300: #AC6365;
  --emu-common-colors-dark-brown-400: #7E4345;
  --emu-common-colors-dark-brown-500: #492728;
  --emu-common-colors-dark-brown-600: #3C2021;
  --emu-common-colors-dark-brown-700: #2B1718;
  --emu-common-colors-dark-brown-800: #1E1010;
  --emu-common-colors-dark-brown-900: #0D0707;
  --emu-common-colors-dark-brown-950: #070404;
  --emu-common-colors-light-grey-50: #FFFFFF;
  --emu-common-colors-light-grey-100: #FDFCFC;
  --emu-common-colors-light-grey-200: #FDFCFC;
  --emu-common-colors-light-grey-300: #FCF9F8;
  --emu-common-colors-light-grey-400: #FCF9F8;
  --emu-common-colors-light-grey-500: #FAF6F5;
  --emu-common-colors-light-grey-600: #DABCB4;
  --emu-common-colors-light-grey-700: #B87F70;
  --emu-common-colors-light-grey-800: #855042;
  --emu-common-colors-light-grey-900: #221411;
  --emu-common-colors-medium-grey-50: #FFFFFF;
  --emu-common-colors-medium-grey-100: #F8F7F7;
  --emu-common-colors-medium-grey-200: #E6E5E5;
  --emu-common-colors-medium-grey-300: #D8D5D5;
  --emu-common-colors-medium-grey-400: #C9C5C5;
  --emu-common-colors-medium-grey-500: #B9B4B4;
  --emu-common-colors-medium-grey-600: #958E8E;
  --emu-common-colors-medium-grey-700: #716A6A;
  --emu-common-colors-medium-grey-800: #4C4747;
  --emu-common-colors-medium-grey-900: #252222;
  --emu-common-colors-medium-grey-950: #121111;
  --emu-common-colors-dark-grey-50: #F7F7F7;
  --emu-common-colors-dark-grey-100: #E8E8E8;
  --emu-common-colors-dark-grey-200: #C9C9CA;
  --emu-common-colors-dark-grey-300: #ADADAE;
  --emu-common-colors-dark-grey-400: #8E8F90;
  --emu-common-colors-dark-grey-500: #6f7071;
  --emu-common-colors-dark-grey-600: #58595A;
  --emu-common-colors-dark-grey-700: #424243;
  --emu-common-colors-dark-grey-800: #2D2E2E;
  --emu-common-colors-dark-grey-900: #171717;
  --emu-common-colors-dark-grey-950: #0A0A0A;
  --emu-common-colors-blush-50: #FFFFFF;
  --emu-common-colors-blush-100: #FDFCFC;
  --emu-common-colors-blush-200: #F4EBEF;
  --emu-common-colors-blush-300: #EDDEE4;
  --emu-common-colors-blush-400: #E6D1D9;
  --emu-common-colors-blush-500: #DDC1CC;
  --emu-common-colors-blush-600: #C08CA1;
  --emu-common-colors-blush-700: #A15876;
  --emu-common-colors-blush-800: #693A4D;
  --emu-common-colors-blush-900: #351D26;
  --emu-common-colors-blush-950: #1A0E13;
  --emu-common-colors-abbvie-blue-50: #E3EBFC;
  --emu-common-colors-abbvie-blue-100: #BED1F9;
  --emu-common-colors-abbvie-blue-200: #6A97F1;
  --emu-common-colors-abbvie-blue-300: #1B60E9;
  --emu-common-colors-abbvie-blue-400: #0F3D9A;
  --emu-common-colors-abbvie-blue-500: #071D49;
  --emu-common-colors-abbvie-blue-600: #06183C;
  --emu-common-colors-abbvie-blue-700: #04112A;
  --emu-common-colors-abbvie-blue-800: #030B1C;
  --emu-common-colors-abbvie-blue-900: #01060E;
  --emu-common-colors-abbvie-blue-950: #010409;
  --emu-common-font-families-sans: Arial, sans-serif;
  --emu-common-font-families-serif: Times New Roman, serif;
  --emu-common-font-families-mono: Courier, monospace;
  --emu-common-font-weight-bold: 700;
  --emu-common-font-weight-light: 300;
  --emu-common-font-weight-black: 900;
  --emu-common-font-weight-regular: 400;
  --emu-common-font-sizes-narrow-medium: 12px;
  --emu-common-font-sizes-narrow-large: 16.97px;
  --emu-common-font-sizes-narrow-xl: 23.99px;
  --emu-common-font-sizes-narrow-xxl: 33.93px;
  --emu-common-font-sizes-narrow-xxxl: 47.97px;
  --emu-common-font-sizes-wide-medium: 16px;
  --emu-common-font-sizes-wide-large: 22.62px;
  --emu-common-font-sizes-wide-xl: 31.99px;
  --emu-common-font-sizes-wide-xxl: 45.23px;
  --emu-common-font-sizes-wide-xxxl: 63.96px;
  --emu-common-line-heights-narrow-large: 18px;
  --emu-common-line-heights-narrow-medium: 18px;
  --emu-common-line-heights-narrow-xl: 36px;
  --emu-common-line-heights-narrow-xxl: 36px;
  --emu-common-line-heights-narrow-xxxl: 54px;
  --emu-common-line-heights-wide-large: 24px;
  --emu-common-line-heights-wide-medium: 24px;
  --emu-common-line-heights-wide-xl: 48px;
  --emu-common-line-heights-wide-xxl: 48px;
  --emu-common-line-heights-wide-xxxl: 72px;
  --emu-common-border-radius-xxs: 2px;
  --emu-common-border-radius-xs: 4px;
  --emu-common-border-radius-small: 8px;
  --emu-common-border-radius-medium: 16px;
  --emu-common-border-radius-large: 32px;
  --emu-common-border-radius-none: 0px;
  --emu-common-border-width-thin: 1px;
  --emu-common-border-width-medium: 2px;
  --emu-common-border-width-thick: 4px;
  --emu-common-border-width-none: 0px;
  --emu-common-outline-width-thin: thin;
  --emu-common-outline-width-medium: medium;
  --emu-common-outline-width-thick: thick;
  --emu-common-outline-width-unset: unset;
  --emu-common-sizing-none: 0px;
  --emu-common-sizing-xxs: 1px;
  --emu-common-sizing-xs: 8px;
  --emu-common-sizing-small: 16px;
  --emu-common-sizing-medium: 32px;
  --emu-common-sizing-large: 64px;
  --emu-common-sizing-xl: 120px;
  --emu-common-sizing-xxl: 256px;
  --emu-common-sizing-xxxl: 1170px;
  --emu-common-sizing-container: 1520px;
  --emu-common-sizing-container-large: 1720px;
  --emu-common-other-time-transition-short: 200ms;
  --emu-common-other-time-transition-base: 400ms;
  --emu-common-other-time-transition-long: 600ms;
  --emu-common-other-time-transition-xl: 1000ms;
  --emu-common-other-time-duration-instant: 400ms;
  --emu-common-other-time-duration-short: 2000ms;
  --emu-common-other-time-duration-base: 4000ms;
  --emu-common-other-time-duration-long: 6000ms;
  --emu-common-other-time-delay-none: 0ms;
  --emu-common-other-time-delay-short: 50ms;
  --emu-common-other-time-delay-base: 100ms;
  --emu-common-other-time-delay-long: 200ms;
  --emu-common-other-z-index-cookie-banner: 700;
  --emu-common-other-z-index-modal: 600;
  --emu-common-other-z-index-header: 500;
  --emu-common-other-z-index-isi: 400;
  --emu-common-other-z-index-overlay: 300;
  --emu-common-other-z-index-layer: 100;
  --emu-common-other-z-index-base: 0;
  --emu-common-other-z-index-behind: -1;
  --emu-common-box-shadow-soft-light: 0 0 6 0 var(--emu-common-colors-grey-700);

  /**
  * @theme latisse-consumer-theme
  * @category semantic tokens
  */
  --emu-semantic-colors-none: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-light: var(--emu-common-colors-light-brown-100);
  --emu-semantic-colors-surface-dark: var(--emu-common-colors-dark-brown-500);
  --emu-semantic-colors-surface-modal-backdrop: rgba(0, 0, 0, 0.4);
  --emu-semantic-colors-actions-primary-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-semantic-colors-actions-primary-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-semantic-colors-actions-primary-default-light: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-actions-primary-default-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-actions-primary-hover-light: var(--emu-semantic-colors-primary-600);
  --emu-semantic-colors-actions-primary-hover-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-active-light: var(--emu-semantic-colors-primary-600);
  --emu-semantic-colors-actions-primary-active-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-focus-light: var(--emu-semantic-colors-primary-600);
  --emu-semantic-colors-actions-primary-focus-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-disabled-light: var(--emu-common-colors-medium-grey-500);
  --emu-semantic-colors-actions-primary-disabled-dark: var(--emu-common-colors-medium-grey-300);
  --emu-semantic-colors-actions-primary-error-light: var(--emu-semantic-colors-error-500);
  --emu-semantic-colors-actions-primary-error-dark: var(--emu-semantic-colors-error-300);
  --emu-semantic-colors-actions-on-primary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-primary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-secondary-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-semantic-colors-actions-secondary-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-semantic-colors-actions-secondary-default-light: var(--emu-semantic-colors-secondary-500);
  --emu-semantic-colors-actions-secondary-default-dark: var(--emu-common-colors-blush-500);
  --emu-semantic-colors-actions-secondary-hover-light: var(--emu-semantic-colors-secondary-600);
  --emu-semantic-colors-actions-secondary-hover-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-active-light: var(--emu-semantic-colors-secondary-600);
  --emu-semantic-colors-actions-secondary-active-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-focus-light: var(--emu-semantic-colors-secondary-600);
  --emu-semantic-colors-actions-secondary-focus-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-disabled-light: var(--emu-common-colors-medium-grey-300);
  --emu-semantic-colors-actions-secondary-disabled-dark: var(--emu-common-colors-medium-grey-200);
  --emu-semantic-colors-actions-on-secondary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-secondary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-text-light: var(--emu-common-colors-black);
  --emu-semantic-colors-text-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-foreground-light: var(--emu-common-colors-medium-brown-300);
  --emu-semantic-colors-foreground-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-primary-0: var(--emu-common-colors-white);
  --emu-semantic-colors-primary-50: #363636;
  --emu-semantic-colors-primary-100: #004F50;
  --emu-semantic-colors-primary-200: #212529;
  --emu-semantic-colors-primary-300: #88B9BB;
  --emu-semantic-colors-primary-400: rgba(54, 54, 54, 0.48);
  --emu-semantic-colors-primary-500: rgba(54, 54, 54, 0.57);
  --emu-semantic-colors-primary-600: #F1F0F6;
  --emu-semantic-colors-primary-700: #F0F5F5;
  --emu-semantic-colors-primary-800: #EAE9EF;
  --emu-semantic-colors-primary-900: #EBEAF0;
  --emu-semantic-colors-primary-950: #C3D5D7;
  --emu-semantic-colors-primary-light: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-primary-dark: var(--emu-semantic-colors-primary-200);
  --emu-semantic-colors-secondary-50: rgba(195, 214, 215, .25);
  --emu-semantic-colors-secondary-100: rgba(255, 255, 255, 0.87);
  --emu-semantic-colors-secondary-200: #B0C9C9;
  --emu-semantic-colors-secondary-300: #FEFEFE;
  --emu-semantic-colors-secondary-400: #C3D6D7;
  --emu-semantic-colors-secondary-500: #FFFFFF6E;
  --emu-semantic-colors-secondary-600: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-secondary-700: var(--emu-common-colors-blush-700);
  --emu-semantic-colors-secondary-800: var(--emu-common-colors-blush-800);
  --emu-semantic-colors-secondary-900: var(--emu-common-colors-blush-900);
  --emu-semantic-colors-secondary-950: var(--emu-common-colors-blush-950);
  --emu-semantic-colors-secondary-light: var(--emu-semantic-colors-secondary-500);
  --emu-semantic-colors-secondary-dark: var(--emu-semantic-colors-secondary-200);
  --emu-semantic-colors-tertiary-50: var(--emu-common-colors-abbvie-blue-50);
  --emu-semantic-colors-tertiary-100: var(--emu-common-colors-abbvie-blue-100);
  --emu-semantic-colors-tertiary-200: var(--emu-common-colors-abbvie-blue-200);
  --emu-semantic-colors-tertiary-300: var(--emu-common-colors-abbvie-blue-300);
  --emu-semantic-colors-tertiary-400: var(--emu-common-colors-abbvie-blue-400);
  --emu-semantic-colors-tertiary-500: var(--emu-common-colors-abbvie-blue-500);
  --emu-semantic-colors-tertiary-600: var(--emu-common-colors-abbvie-blue-600);
  --emu-semantic-colors-tertiary-700: var(--emu-common-colors-abbvie-blue-700);
  --emu-semantic-colors-tertiary-800: var(--emu-common-colors-abbvie-blue-800);
  --emu-semantic-colors-tertiary-900: var(--emu-common-colors-abbvie-blue-900);
  --emu-semantic-colors-tertiary-950: var(--emu-common-colors-abbvie-blue-950);
  --emu-semantic-colors-tertiary-light: var(--emu-semantic-colors-tertiary-500);
  --emu-semantic-colors-tertiary-dark: var(--emu-semantic-colors-tertiary-200);
  --emu-semantic-colors-error-50: var(--emu-common-colors-red-50);
  --emu-semantic-colors-error-100: var(--emu-common-colors-red-100);
  --emu-semantic-colors-error-200: var(--emu-common-colors-red-200);
  --emu-semantic-colors-error-300: var(--emu-common-colors-red-300);
  --emu-semantic-colors-error-400: var(--emu-common-colors-red-400);
  --emu-semantic-colors-error-500: var(--emu-common-colors-red-500);
  --emu-semantic-colors-error-600: var(--emu-common-colors-red-600);
  --emu-semantic-colors-error-700: var(--emu-common-colors-red-700);
  --emu-semantic-colors-error-800: var(--emu-common-colors-red-800);
  --emu-semantic-colors-error-900: var(--emu-common-colors-red-900);
  --emu-semantic-colors-error-950: var(--emu-common-colors-red-950);
  --emu-semantic-colors-error-light: var(--emu-semantic-colors-error-500);
  --emu-semantic-colors-error-dark: var(--emu-semantic-colors-error-200);
  --emu-semantic-colors-background-light: var(--emu-common-colors-light-brown-50);
  --emu-semantic-colors-background-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-font-weight-500: 500;
  --emu-semantic-font-weight-bold: var(--emu-common-font-weight-bold);
  --emu-semantic-font-weight-light: var(--emu-common-font-weight-light);
  --emu-semantic-font-weight-black: var(--emu-common-font-weight-black);
  --emu-semantic-font-weight-regular: var(--emu-common-font-weight-regular);
  --emu-semantic-font-families-body: 'brandon-grotesque',sans-serif;
  --emu-semantic-font-families-heading: var(--emu-common-font-families-sans);
  --emu-semantic-font-families-mono: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans','Liberation Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  --emu-semantic-spacing-none: var(--emu-common-spacing-none);
  --emu-semantic-spacing-one-line-height-wide: var(--emu-semantic-line-heights-wide-small);
  --emu-semantic-spacing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-small);
  --emu-semantic-spacing-two-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide)*2);
  --emu-semantic-spacing-two-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow)*2);
  --emu-semantic-spacing-three-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide)*3);
  --emu-semantic-spacing-three-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow)*3);
  --emu-semantic-spacing-rem-horizontal: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-narrow: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-wide: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-onerem-narrow: 1rem;
  --emu-semantic-spacing-horizontal-onerem-wide: 1rem;
  --emu-semantic-spacing-horizontal-tworem-narrow: calc(var(--emu-semantic-spacing-horizontal-onerem-narrow)*2);
  --emu-semantic-spacing-horizontal-tworem-wide: calc(var(--emu-semantic-spacing-horizontal-onerem-wide)*2);
  --emu-semantic-spacing-rem-vertical: var(--emu-common-spacing-none);
  --emu-semantic-spacing-vertical-narrow: var(--emu-common-spacing-none);
  --emu-semantic-spacing-vertical-wide: var(--emu-common-spacing-none);
  --emu-semantic-spacing-half-line-height-wide: calc(var(--emu-semantic-line-heights-wide-small)*.5);
  --emu-semantic-spacing-half-line-height-narrow: calc(var(--emu-semantic-line-heights-narrow-small)*.5);
  --emu-semantic-spacing-quarter-line-height-wide: calc(var(--emu-semantic-line-heights-wide-small)*.25);
  --emu-semantic-spacing-quarter-line-height-narrow: calc(var(--emu-semantic-line-heights-narrow-small)*.25);
  --emu-semantic-font-sizes-narrow-medium: 22px;
  --emu-semantic-font-sizes-narrow-large: 30px;
  --emu-semantic-font-sizes-narrow-xl: 32px;
  --emu-semantic-font-sizes-narrow-xxl: 40px;
  --emu-semantic-font-sizes-narrow-xxxl: 46px;
  --emu-semantic-font-sizes-narrow-small: 18px;
  --emu-semantic-font-sizes-narrow-xs: 12px;
  --emu-semantic-font-sizes-wide-medium: 22px;
  --emu-semantic-font-sizes-wide-large: 30px;
  --emu-semantic-font-sizes-wide-xl: 32px;
  --emu-semantic-font-sizes-wide-xxl: 60px;
  --emu-semantic-font-sizes-wide-xxxl: 46px;
  --emu-semantic-font-sizes-wide-small: 20px;
  --emu-semantic-font-sizes-wide-xs: 14px;
  --emu-semantic-line-heights-narrow-large: 36px;
  --emu-semantic-line-heights-narrow-medium: 33px;
  --emu-semantic-line-heights-narrow-xl: 38.4px;
  --emu-semantic-line-heights-narrow-xxl: 48px;
  --emu-semantic-line-heights-narrow-xxxl: 69px;
  --emu-semantic-line-heights-narrow-small: 27px;
  --emu-semantic-line-heights-narrow-xs: 14px;
  --emu-semantic-line-heights-wide-large: 36px;
  --emu-semantic-line-heights-wide-medium: 33px;
  --emu-semantic-line-heights-wide-xl: 38.4px;
  --emu-semantic-line-heights-wide-xxl: 72px;
  --emu-semantic-line-heights-wide-xxxl: 69px;
  --emu-semantic-line-heights-wide-small: 30px;
  --emu-semantic-line-heights-wide-xs: 16px;
  --emu-semantic-border-radius-xs: var(--emu-common-border-radius-xs);
  --emu-semantic-border-radius-small: var(--emu-common-border-radius-small);
  --emu-semantic-border-radius-medium: var(--emu-common-border-radius-medium);
  --emu-semantic-border-radius-large: var(--emu-common-border-radius-large);
  --emu-semantic-border-radius-none: var(--emu-common-border-radius-none);
  --emu-semantic-border-width-thin: var(--emu-common-border-width-thin);
  --emu-semantic-border-width-medium: var(--emu-common-border-width-medium);
  --emu-semantic-border-width-thick: var(--emu-common-border-width-thick);
  --emu-semantic-border-width-none: 0px;
  --emu-semantic-outline-width-thin: var(--emu-common-outline-width-thin);
  --emu-semantic-outline-width-medium: var(--emu-common-outline-width-medium);
  --emu-semantic-outline-width-thick: var(--emu-common-outline-width-thick);
  --emu-semantic-outline-width-unset: unset;
  --emu-semantic-sizing-none: var(--emu-common-sizing-none);
  --emu-semantic-sizing-xxs: var(--emu-common-sizing-xxs);
  --emu-semantic-sizing-xs: var(--emu-common-sizing-xs);
  --emu-semantic-sizing-small: var(--emu-common-sizing-small);
  --emu-semantic-sizing-medium: var(--emu-common-sizing-medium);
  --emu-semantic-sizing-large: var(--emu-common-sizing-large);
  --emu-semantic-sizing-xl: var(--emu-common-sizing-xl);
  --emu-semantic-sizing-xxl: var(--emu-common-sizing-xxl);
  --emu-semantic-sizing-breakpoints-small: 320px;
  --emu-semantic-sizing-breakpoints-small-plus: 576px;
  --emu-semantic-sizing-breakpoints-medium: 768px;
  --emu-semantic-sizing-breakpoints-tablet: 993px;
  --emu-semantic-sizing-breakpoints-large: 1024px;
  --emu-semantic-sizing-breakpoints-x-large: 1200px;
  --emu-semantic-sizing-breakpoints-xx-large: 1440px;
  --emu-semantic-sizing-one-line-height-narrow: 20px;
  --emu-semantic-sizing-one-line-height-wide: 20px;
  --emu-semantic-sizing-two-line-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)*2);
  --emu-semantic-sizing-two-line-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)*2);
  --emu-semantic-sizing-three-line-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)*3);
  --emu-semantic-sizing-three-line-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)*3);
  --emu-semantic-sizing-max-width-text-narrow: 40em;
  --emu-semantic-sizing-max-width-text-wide: 55em;
  --emu-semantic-typography-narrow-headings-xxxl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-xxxl)/var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-headings-xxl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-xxl)/var(--emu-semantic-line-heights-narrow-xxl) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xl)/var(--emu-semantic-line-heights-narrow-xl) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-headings-large: var(--emu-semantic-font-weight-500) var(--emu-semantic-font-sizes-narrow-large)/var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-medium)/var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-body-regular: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-small)/var(--emu-semantic-line-heights-narrow-small) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxxl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-wide-xxxl)/var(--emu-semantic-line-heights-wide-xxxl) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-wide-xxl)/var(--emu-semantic-line-heights-wide-xxl) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xl)/var(--emu-semantic-line-heights-wide-xl) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-large: var(--emu-semantic-font-weight-500) var(--emu-semantic-font-sizes-wide-large)/var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-wide-medium)/var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-body-regular: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-wide-small)/var(--emu-semantic-line-heights-wide-small) var(--emu-semantic-font-families-body);

  /**
  * @theme latisse-consumer-theme
  * @category component tokens
  */
  --emu-component-cards-card-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-cards-card-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-color-background-light: var(--emu-common-colors-white);
  --emu-component-cards-card-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-size-height: 30px;
  --emu-component-cards-card-header-button-icon-size-width: 30px;
  --emu-component-cards-card-header-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-cards-card-header-button-icon-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-cards-card-header-button-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-teaser-padding-top-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-top-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-narrow: var(--emu-semantic-spacing-two-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-cards-teaser-padding-wide: var(--emu-semantic-spacing-two-line-height-wide) var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-title-max-width: 66.66667%;
  --emu-component-cards-teaser-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-narrow: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-title-margin-wide: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-cards-teaser-description-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-description-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-subtext-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-subtext-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-actions-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-cards-teaser-actions-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-cards-teaser-actions-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-actions-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-disclaimer-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-cards-teaser-disclaimer-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-cards-teaser-old-padding-top-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-top-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-right-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-right-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-bottom-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-bottom-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-left-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-left-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-old-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-old-title-max-width: 66.66667%;
  --emu-component-cards-teaser-old-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-old-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-old-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-old-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-cards-content-fragment-card-margin-narrow: var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-margin-wide: var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-typography-name-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-cards-content-fragment-card-text-typography-name-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-cards-content-fragment-card-text-typography-title-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-cards-content-fragment-card-text-typography-title-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-cards-content-fragment-card-text-typography-bio-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-cards-content-fragment-card-text-typography-bio-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-cards-content-fragment-card-text-margin-narrow: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-margin-wide: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-cards-content-fragment-card-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-cards-content-fragment-card-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow);
  --emu-component-cards-content-fragment-card-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide);
  --emu-component-cards-content-fragment-card-color-background-dark: var(--emu-semantic-colors-primary-950);
  --emu-component-cards-content-fragment-card-color-background-light: var(--emu-semantic-colors-primary-0);
  --emu-component-cards-content-fragment-card-border-radius-narrow: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-cards-content-fragment-card-border-radius-wide: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-cards-content-fragment-card-author-image-sizing-height-narrow: var(--emu-semantic-sizing-xl);
  --emu-component-cards-content-fragment-card-author-image-sizing-height-wide: var(--emu-semantic-sizing-xxl);
  --emu-component-cards-content-fragment-card-author-image-sizing-width-narrow: var(--emu-semantic-sizing-xl);
  --emu-component-cards-content-fragment-card-author-image-sizing-width-wide: var(--emu-semantic-sizing-xxl);
  --emu-component-cards-content-fragment-card-author-image-border-radius-narrow: var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium);
  --emu-component-cards-content-fragment-card-author-image-border-radius-wide: var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium);
  --emu-component-lists-accordion-color-background-light: var(--emu-component-lists-accordion-item-body-color-background-light);
  --emu-component-lists-accordion-color-background-dark: var(--emu-component-lists-accordion-item-body-color-background-dark);
  --emu-component-lists-accordion-color-text-light: var(--emu-semantic-colors-primary-50);
  --emu-component-lists-accordion-color-text-dark: var(--emu-component-lists-accordion-item-header-text-color-dark);
  --emu-component-lists-accordion-color-border-light: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-light);
  --emu-component-lists-accordion-color-border-dark: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-dark);
  --emu-component-lists-accordion-margin-narrow: 0px;
  --emu-component-lists-accordion-margin-wide: 0px;
  --emu-component-lists-accordion-margin-top-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-top-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-color-border-bottom-closed-light: var(--emu-semantic-colors-primary-50);
  --emu-component-lists-accordion-item-color-border-bottom-open-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-color-border-bottom-open-light: var(--emu-semantic-colors-primary-50);
  --emu-component-lists-accordion-item-body-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-lists-accordion-item-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-body-color-border-open-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-body-color-border-open-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-body-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-body-padding-narrow: 20px;
  --emu-component-lists-accordion-item-body-padding-wide: 20px;
  --emu-component-lists-accordion-item-body-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-accordion-item-header-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-lists-accordion-item-header-text-typography-wide: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-lists-accordion-item-header-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-accordion-item-header-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-accordion-item-header-padding-narrow: 6px 12px 6px 0px;
  --emu-component-lists-accordion-item-header-padding-wide: 6px 12px 6px 0px;
  --emu-component-lists-accordion-item-header-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-icon-margin-narrow: var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-component-lists-accordion-item-header-icon-spacing);
  --emu-component-lists-accordion-item-header-icon-margin-wide: var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-component-lists-accordion-item-header-icon-spacing);
  --emu-component-lists-accordion-item-header-icon-spacing: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-item-header-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-lists-accordion-item-header-icon-border-radius: 0px;
  --emu-component-lists-accordion-item-header-icon-transform-open: rotate(0);
  --emu-component-lists-accordion-item-header-icon-transform-closed: rotate(180deg);
  --emu-component-lists-accordion-item-header-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-light: var(--emu-common-colors-transparent);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-light: var(--emu-common-colors-transparent);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-lists-accordion-item-header-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-size-height-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-size-width-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-lists-accordion-item-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-accordion-border-radius-narrow: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-wide: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-lists-accordion-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-border-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-border-hover-light: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-text-color-default-light: var(--emu-semantic-colors-primary-50);
  --emu-component-lists-navigation-banner-item-text-color-default-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-semantic-colors-primary-50);
  --emu-component-lists-navigation-banner-item-text-color-hover-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-dropdown-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-primary-100);
  --emu-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-z-index: var(--emu-common-other-z-index-header);
  --emu-component-lists-navigation-text-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-light: var(--emu-component-ingredients-select-input-option-text-color-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-light: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-light: var(--emu-component-ingredients-select-input-option-text-color-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-light: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-light: var(--emu-component-ingredients-select-input-option-text-color-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-light: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-light: var(--emu-component-ingredients-select-input-option-text-color-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-light: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-light: var(--emu-component-ingredients-select-input-option-color-background-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-light: var(--emu-component-ingredients-select-input-option-color-background-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-light: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-light: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-light: var(--emu-component-ingredients-select-input-option-color-background-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-light: var(--emu-component-ingredients-select-input-option-color-background-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-light: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-light: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-light: var(--emu-component-ingredients-select-input-color-border-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-dark: var(--emu-component-ingredients-select-input-color-border-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-light: var(--emu-component-ingredients-select-input-color-border-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-dark: var(--emu-component-ingredients-select-input-color-border-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-light: var(--emu-component-ingredients-select-input-color-border-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-dark: var(--emu-component-ingredients-select-input-color-border-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-light: var(--emu-component-ingredients-select-input-color-border-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-dark: var(--emu-component-ingredients-select-input-color-border-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-light: var(--emu-component-ingredients-select-input-color-border-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-dark: var(--emu-component-ingredients-select-input-color-border-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-light: var(--emu-component-ingredients-select-input-color-border-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-dark: var(--emu-component-ingredients-select-input-color-border-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-light: var(--emu-component-ingredients-select-input-color-border-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-dark: var(--emu-component-ingredients-select-input-color-border-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-light: var(--emu-component-ingredients-select-input-color-border-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-dark: var(--emu-component-ingredients-select-input-color-border-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-border-radius-narrow: var(--emu-component-ingredients-select-input-border-radius-narrow);
  --emu-component-lists-language-navigation-dropdown-border-radius-wide: var(--emu-component-ingredients-select-input-border-radius-wide);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-padding-narrow: var(--emu-component-ingredients-select-input-padding-narrow);
  --emu-component-lists-language-navigation-dropdown-padding-wide: var(--emu-component-ingredients-select-input-padding-wide);
  --emu-component-lists-language-navigation-dropdown-gap-narrow: var(--emu-component-ingredients-select-input-gap-narrow);
  --emu-component-lists-language-navigation-dropdown-gap-wide: var(--emu-component-ingredients-select-input-gap-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-narrow: var(--emu-component-ingredients-select-input-border-width-filled-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-wide: var(--emu-component-ingredients-select-input-border-width-filled-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-narrow: var(--emu-component-ingredients-select-input-border-width-outline-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-wide: var(--emu-component-ingredients-select-input-border-width-outline-wide);
  --emu-component-lists-language-navigation-pipes-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-gap-wide: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-lists-language-navigation-pipes-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-lists-language-navigation-pipes-color-border-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-language-navigation-pipes-color-border-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-feeds-instagram-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-post-gap-narrow: 40px;
  --emu-component-feeds-instagram-post-gap-wide: 40px;
  --emu-component-ingredients-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-height-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-separator-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-separator-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-separator-size-height: var(--emu-semantic-sizing-xxs);
  --emu-component-ingredients-title-h1-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxxl);
  --emu-component-ingredients-title-h1-typography-wide: var(--emu-semantic-typography-wide-headings-xxxl);
  --emu-component-ingredients-title-h2-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-ingredients-title-h2-typography-wide: var(--emu-semantic-typography-wide-headings-xxl);
  --emu-component-ingredients-title-h3-typography-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-ingredients-title-h3-typography-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-ingredients-title-h4-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-ingredients-title-h4-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-ingredients-title-h5-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-ingredients-title-h5-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-ingredients-title-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-title-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-text-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-text-text-typography-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-ingredients-text-text-typography-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-ingredients-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-ingredients-text-typography-wide: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-ingredients-loader-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-loader-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-image-map-buttons-size-height: 27px;
  --emu-component-ingredients-image-map-buttons-size-width: 27px;
  --emu-component-ingredients-image-map-buttons-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-image-map-buttons-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-image-map-buttons-color-icon-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-ingredients-image-map-buttons-color-icon-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-ingredients-image-map-buttons-border-radius-top-left: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-top-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-left: 50%;
  --emu-component-ingredients-image-map-buttons-shadow: 0 0 2 5 rgb(0 0 0 / 60%);
  --emu-component-ingredients-google-map-color-background-light: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-color-background-dark: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-image-caption-padding-narrow: calc(var(--emu-semantic-spacing-vertical-narrow)*.5);
  --emu-component-ingredients-label-margin-narrow: var(--emu-semantic-spacing-half-line-height-narrow) 0px;
  --emu-component-ingredients-label-margin-wide: var(--emu-semantic-spacing-half-line-height-wide) 0px;
  --emu-component-ingredients-label-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-label-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-label-text-color-default-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-label-text-color-default-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-label-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-label-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-label-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-label-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-legend-margin-narrow: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-legend-margin-wide: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-legend-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-legend-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-field-message-margin-narrow: var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-field-message-margin-wide: var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-field-message-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-field-message-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-error-message-margin-narrow: var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-error-message-margin-wide: var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-error-message-text-color-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-error-message-text-color-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-text-color-outline-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-select-input-option-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-select-input-option-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-color-border-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-default-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-default-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-hover-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-hover-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-active-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-active-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-active-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-active-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-focus-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-focus-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-disabled-filled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-color-border-disabled-filled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-color-border-disabled-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-disabled-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-error-filled-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-color-border-error-filled-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-color-border-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-color-border-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-select-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-select-input-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-select-input-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-select-input-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-select-input-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-select-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-select-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-select-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-select-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-select-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-select-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-select-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-select-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-select-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-select-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-select-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-select-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-select-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-select-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-select-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-select-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-select-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-text-input-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-input-text-color-empty-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-text-input-text-color-empty-dark: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-text-input-text-color-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-input-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-text-input-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-text-input-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-text-input-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-text-input-text-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-text-input-text-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-text-input-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-empty-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-empty-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-text-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-text-input-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-text-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-text-input-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-empty-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-empty-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-disabled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-disabled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-error-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-error-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-text-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-text-input-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-text-input-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-text-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-text-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-text-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-text-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-text-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-text-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-text-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-text-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-text-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-text-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-text-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-text-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-text-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-text-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-text-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-text-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-text-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-text-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-radio-input-control-size-all-narrow: var(--emu-semantic-sizing-one-line-height-narrow);
  --emu-component-ingredients-radio-input-control-size-all-wide: var(--emu-semantic-sizing-one-line-height-wide);
  --emu-component-ingredients-radio-input-control-border-radius-narrow: var(--emu-semantic-border-radius-medium);
  --emu-component-ingredients-radio-input-control-border-radius-wide: var(--emu-semantic-border-radius-medium);
  --emu-component-ingredients-radio-input-control-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-radio-input-control-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-radio-input-control-color-background-focus-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-focus-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-checked-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-checked-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-disabled-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-disabled-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-error-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-error-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-active-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-active-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-hover-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-hover-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-ingredients-radio-input-control-color-border-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-focus-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-ingredients-radio-input-control-color-border-focus-dark: var(--emu-semantic-colors-actions-primary-focus-dark);
  --emu-component-ingredients-radio-input-control-color-border-checked-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-radio-input-control-color-border-checked-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-radio-input-control-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-radio-input-control-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-control-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-control-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-control-color-border-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-radio-input-control-color-border-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-radio-input-control-color-border-hover-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-ingredients-radio-input-control-color-border-hover-dark: var(--emu-semantic-colors-actions-primary-focus-dark);
  --emu-component-ingredients-radio-input-control-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-control-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-icon-size-all-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) - 4px);
  --emu-component-ingredients-radio-input-icon-size-all-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) - 4px);
  --emu-component-ingredients-radio-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-radio-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-radio-input-label-gap-narrow: var(--emu-component-ingredients-label-gap-narrow);
  --emu-component-ingredients-radio-input-label-gap-wide: var(--emu-component-ingredients-label-gap-wide);
  --emu-component-ingredients-radio-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-radio-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-radio-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-radio-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-radio-input-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-checked-light);
  --emu-component-ingredients-radio-input-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-checked-dark);
  --emu-component-ingredients-radio-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-radio-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-radio-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-radio-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-radio-input-legend-margin-narrow: var(--emu-component-ingredients-legend-margin-narrow);
  --emu-component-ingredients-radio-input-legend-margin-wide: var(--emu-component-ingredients-legend-margin-wide);
  --emu-component-ingredients-radio-input-legend-text-color-light: var(--emu-component-ingredients-legend-text-color-light);
  --emu-component-ingredients-radio-input-legend-text-color-dark: var(--emu-component-ingredients-legend-text-color-dark);
  --emu-component-ingredients-radio-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-radio-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-radio-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-radio-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-radio-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-radio-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-radio-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-radio-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-checkbox-input-control-size-all-narrow: var(--emu-semantic-sizing-one-line-height-narrow);
  --emu-component-ingredients-checkbox-input-control-size-all-wide: var(--emu-semantic-sizing-one-line-height-wide);
  --emu-component-ingredients-checkbox-input-control-border-radius-narrow: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-control-border-radius-wide: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-control-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-checkbox-input-control-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-focus-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-focus-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-checked-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-checked-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-disabled-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-disabled-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-error-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-error-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-hover-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-hover-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-active-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-active-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-checkbox-input-control-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-focus-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-focus-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-checked-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-checked-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-control-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-control-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-active-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-active-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-hover-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-hover-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-control-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-icon-size-all-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) - 4px);
  --emu-component-ingredients-checkbox-input-icon-size-all-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) - 4px);
  --emu-component-ingredients-checkbox-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-checkbox-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-checkbox-input-label-gap-narrow: var(--emu-component-ingredients-label-gap-narrow);
  --emu-component-ingredients-checkbox-input-label-gap-wide: var(--emu-component-ingredients-label-gap-wide);
  --emu-component-ingredients-checkbox-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-checkbox-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-checked-light);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-checked-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-checkbox-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-checkbox-input-legend-margin-narrow: var(--emu-component-ingredients-legend-margin-narrow);
  --emu-component-ingredients-checkbox-input-legend-margin-wide: var(--emu-component-ingredients-legend-margin-wide);
  --emu-component-ingredients-checkbox-input-legend-text-color-light: var(--emu-component-ingredients-legend-text-color-light);
  --emu-component-ingredients-checkbox-input-legend-text-color-dark: var(--emu-component-ingredients-legend-text-color-dark);
  --emu-component-ingredients-checkbox-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-checkbox-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-checkbox-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-checkbox-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-checkbox-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-checkbox-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-checkbox-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-checkbox-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-textarea-input-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-textarea-input-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-textarea-input-text-color-empty-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-textarea-input-text-color-empty-dark: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-textarea-input-text-color-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-textarea-input-text-color-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-textarea-input-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-textarea-input-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-textarea-input-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-textarea-input-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-textarea-input-text-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-textarea-input-text-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-textarea-input-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-empty-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-empty-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-textarea-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-textarea-input-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-textarea-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-textarea-input-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-empty-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-empty-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-disabled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-disabled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-error-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-error-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-textarea-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-textarea-input-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-textarea-input-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-textarea-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-textarea-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-textarea-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-textarea-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-textarea-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-textarea-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-textarea-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-textarea-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-textarea-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-textarea-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-textarea-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-textarea-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-textarea-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-textarea-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-textarea-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-textarea-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-textarea-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-textarea-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-actions-button-text-typography-narrow: var(--emu-semantic-font-weight-500) 16px/16px var(--emu-semantic-font-families-body);
  --emu-component-actions-button-text-typography-wide: var(--emu-semantic-font-weight-500) 16px/16px var(--emu-semantic-font-families-body);
  --emu-component-actions-button-text-color-link-light: var(--emu-component-actions-button-text-color-link-default-light);
  --emu-component-actions-button-text-color-link-dark: var(--emu-component-actions-button-text-color-link-default-dark);
  --emu-component-actions-button-text-color-link-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-button-text-color-link-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-link-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-button-text-color-link-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-button-text-color-link-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-button-text-color-link-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-link-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-button-text-color-link-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-primary-filled-light: var(--emu-component-actions-button-text-color-primary-filled-default-light);
  --emu-component-actions-button-text-color-primary-filled-dark: var(--emu-component-actions-button-text-color-primary-filled-default-dark);
  --emu-component-actions-button-text-color-primary-filled-focus-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-active-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-default-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-light: var(--emu-component-actions-button-text-color-primary-outline-default-light);
  --emu-component-actions-button-text-color-primary-outline-dark: var(--emu-component-actions-button-text-color-primary-outline-default-dark);
  --emu-component-actions-button-text-color-primary-outline-focus-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-text-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-text-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-active-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-text-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-default-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-text-color-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-text-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-text-color-secondary-filled-light: var(--emu-component-actions-button-text-color-secondary-filled-default-light);
  --emu-component-actions-button-text-color-secondary-filled-dark: var(--emu-component-actions-button-text-color-secondary-filled-default-dark);
  --emu-component-actions-button-text-color-secondary-filled-focus-light: var(--emu-semantic-colors-secondary-300);
  --emu-component-actions-button-text-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-hover-light: var(--emu-semantic-colors-secondary-300);
  --emu-component-actions-button-text-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-active-light: var(--emu-semantic-colors-secondary-300);
  --emu-component-actions-button-text-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-default-light: var(--emu-semantic-colors-secondary-300);
  --emu-component-actions-button-text-color-secondary-filled-default-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-light: var(--emu-component-actions-button-text-color-secondary-outline-default-light);
  --emu-component-actions-button-text-color-secondary-outline-dark: var(--emu-component-actions-button-text-color-secondary-outline-default-dark);
  --emu-component-actions-button-text-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-text-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-border-width-primary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-primary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-outline-width-primary-filled: 0;
  --emu-component-actions-button-outline-width-primary-outline: 0;
  --emu-component-actions-button-outline-width-secondary-filled: 0;
  --emu-component-actions-button-outline-width-secondary-outline: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-color-border-primary-filled-light: var(--emu-component-actions-button-color-border-primary-filled-default-light);
  --emu-component-actions-button-color-border-primary-filled-dark: var(--emu-component-actions-button-color-border-primary-filled-default-dark);
  --emu-component-actions-button-color-border-primary-filled-focus-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-primary-filled-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-primary-filled-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-active-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-primary-filled-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-default-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-primary-filled-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-border-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-border-primary-outline-light: var(--emu-component-actions-button-color-border-primary-outline-default-light);
  --emu-component-actions-button-color-border-primary-outline-dark: var(--emu-component-actions-button-color-border-primary-filled-default-dark);
  --emu-component-actions-button-color-border-primary-outline-focus-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-primary-outline-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-primary-outline-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-active-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-primary-outline-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-default-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-border-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-border-secondary-filled-light: var(--emu-component-actions-button-color-border-secondary-filled-default-light);
  --emu-component-actions-button-color-border-secondary-filled-dark: var(--emu-component-actions-button-color-border-secondary-filled-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-focus-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-active-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-secondary-filled-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-default-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-secondary-filled-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-border-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-border-secondary-outline-light: var(--emu-component-actions-button-color-border-secondary-outline-default-light);
  --emu-component-actions-button-color-border-secondary-outline-dark: var(--emu-component-actions-button-color-border-secondary-outline-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-border-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-primary-filled-light: var(--emu-component-actions-button-color-background-primary-filled-default-light);
  --emu-component-actions-button-color-background-primary-filled-dark: var(--emu-component-actions-button-color-background-primary-filled-default-dark);
  --emu-component-actions-button-color-background-primary-filled-focus-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-background-primary-filled-focus-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-background-primary-filled-hover-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-active-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-background-primary-filled-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-default-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-background-primary-filled-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-background-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-background-primary-outline-light: var(--emu-component-actions-button-color-background-primary-outline-default-light);
  --emu-component-actions-button-color-background-primary-outline-dark: var(--emu-component-actions-button-color-background-primary-outline-default-dark);
  --emu-component-actions-button-color-background-primary-outline-focus-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-hover-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-active-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-default-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-disabled-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-disabled-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-filled-light: var(--emu-component-actions-button-color-background-secondary-filled-default-light);
  --emu-component-actions-button-color-background-secondary-filled-dark: var(--emu-component-actions-button-color-background-secondary-filled-disabled-dark);
  --emu-component-actions-button-color-background-secondary-filled-focus-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-background-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-secondary-focus-dark);
  --emu-component-actions-button-color-background-secondary-filled-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-background-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-secondary-hover-dark);
  --emu-component-actions-button-color-background-secondary-filled-active-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-background-secondary-filled-active-dark: var(--emu-semantic-colors-actions-secondary-active-dark);
  --emu-component-actions-button-color-background-secondary-filled-default-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-background-secondary-filled-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-background-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-secondary-outline-light: var(--emu-component-actions-button-color-background-secondary-outline-default-light);
  --emu-component-actions-button-color-background-secondary-outline-dark: var(--emu-component-actions-button-color-background-secondary-outline-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-outline-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-default-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-outline-color-primary-filled-light: var(--emu-component-actions-button-color-outline-color-primary-filled-default-light);
  --emu-component-actions-button-color-outline-color-primary-filled-dark: var(--emu-component-actions-button-color-outline-color-primary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-light: var(--emu-component-actions-button-color-outline-color-primary-outline-default-light);
  --emu-component-actions-button-color-outline-color-primary-outline-dark: var(--emu-component-actions-button-color-outline-color-primary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-light: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-light);
  --emu-component-actions-button-color-outline-color-secondary-filled-dark: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-light: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-light);
  --emu-component-actions-button-color-outline-color-secondary-outline-dark: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-group-horizontal-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-horizontal-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-actions-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-actions-button-icon-transform-open: rotate(0);
  --emu-component-actions-button-icon-transform-closed: rotate(180deg);
  --emu-component-actions-button-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-actions-button-icon-color-primary-filled-light: var(--emu-component-actions-button-icon-color-primary-filled-default-light);
  --emu-component-actions-button-icon-color-primary-filled-dark: var(--emu-component-actions-button-icon-color-primary-filled-default-dark);
  --emu-component-actions-button-icon-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-light: var(--emu-component-actions-button-icon-color-primary-outline-default-light);
  --emu-component-actions-button-icon-color-primary-outline-dark: var(--emu-component-actions-button-icon-color-primary-outline-default-dark);
  --emu-component-actions-button-icon-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-default-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-icon-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-default-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-default-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-light: var(--emu-component-actions-button-icon-color-secondary-filled-default-light);
  --emu-component-actions-button-icon-color-secondary-filled-dark: var(--emu-component-actions-button-icon-color-secondary-filled-default-dark);
  --emu-component-actions-button-icon-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-outline-light: var(--emu-component-actions-button-icon-color-secondary-outline-default-light);
  --emu-component-actions-button-icon-color-secondary-outline-dark: var(--emu-component-actions-button-icon-color-secondary-outline-default-dark);
  --emu-component-actions-button-icon-size-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)*.75);
  --emu-component-actions-button-icon-size-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)*.75);
  --emu-component-actions-button-icon-size-width-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)*.75);
  --emu-component-actions-button-icon-size-width-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)*.75);
  --emu-component-actions-button-icon-border-radius: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-padding-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-padding-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-component-actions-button-padding-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-padding-right-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-button-padding-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-padding-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-component-actions-button-padding-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-padding-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-button-padding-narrow: 6px 40px;
  --emu-component-actions-button-padding-wide: 6px 40px;
  --emu-component-actions-button-border-radius-primary-filled: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-back-to-top-z-index: var(--emu-common-other-z-index-layer);
  --emu-component-actions-back-to-top-icon-size-height: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-icon-size-width: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-position-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-right-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-right-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-top-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-top-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-add-to-calendar-button-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-button-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-button-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-button-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-add-to-calendar-button-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-add-to-calendar-button-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-button-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-button-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-button-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-button-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-button-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-button-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-add-to-calendar-button-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-width: 165px;
  --emu-component-actions-add-to-calendar-dropdown-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-dropdown-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-dropdown-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-dropdown-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-dropdown-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-actions-add-to-calendar-dropdown-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-actions-add-to-calendar-dropdown-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-add-to-calendar-dropdown-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-add-to-calendar-dropdown-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-add-to-calendar-dropdown-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-height-wide: 24px;
  --emu-component-actions-add-to-calendar-dropdown-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-width-wide: 24px;
  --emu-component-actions-pagination-icon-narrow: var(--emu-semantic-font-sizes-narrow-small);
  --emu-component-actions-pagination-icon-wide: var(--emu-semantic-font-sizes-wide-small);
  --emu-component-actions-pagination-text-color-hover-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-hover-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-default-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-actions-pagination-text-color-default-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-actions-pagination-text-color-active-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-active-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-focus-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-focus-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-typography-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-actions-pagination-text-typography-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-actions-pagination-gap-narrow: var(--emu-semantic-spacing-horizontal-onerem-narrow);
  --emu-component-actions-pagination-gap-wide: var(--emu-semantic-spacing-horizontal-onerem-wide);
  --emu-component-actions-pagination-margin-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-pagination-margin-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-scroll-reveal-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-padding-top-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-top-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-static-section-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-cards-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-comparison-slider-divider-color-border-light: var(--emu-component-containers-comparison-slider-divider-color-light);
  --emu-component-containers-comparison-slider-divider-color-border-dark: var(--emu-component-containers-comparison-slider-divider-color-dark);
  --emu-component-containers-comparison-slider-divider-color-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-comparison-slider-divider-color-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-comparison-slider-divider-border-width-narrow: var(--emu-component-containers-comparison-slider-divider-size-width-narrow);
  --emu-component-containers-comparison-slider-divider-border-width-wide: var(--emu-component-containers-comparison-slider-divider-size-width-wide);
  --emu-component-containers-comparison-slider-divider-size-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-divider-size-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-handle-size-width-narrow: var(--emu-semantic-sizing-medium);
  --emu-component-containers-comparison-slider-handle-size-width-wide: var(--emu-semantic-sizing-large);
  --emu-component-containers-comparison-slider-handle-color-background-light: var(--emu-component-containers-comparison-slider-custom-handle-color-background-light);
  --emu-component-containers-comparison-slider-handle-color-background-dark: var(--emu-component-containers-comparison-slider-custom-handle-color-background-dark);
  --emu-component-containers-comparison-slider-handle-padding-narrow: var(--emu-component-containers-comparison-slider-custom-handle-padding-narrow);
  --emu-component-containers-comparison-slider-handle-padding-wide: var(--emu-component-containers-comparison-slider-custom-handle-padding-wide);
  --emu-component-containers-comparison-slider-handle-icon-size-width-narrow: var(--emu-component-containers-comparison-slider-handle-size-width-narrow);
  --emu-component-containers-comparison-slider-handle-icon-size-width-wide: var(--emu-component-containers-comparison-slider-handle-size-width-wide);
  --emu-component-containers-comparison-slider-handle-icon-color-fill-light: var(--emu-component-containers-comparison-slider-divider-color-border-light);
  --emu-component-containers-comparison-slider-handle-icon-color-fill-dark: var(--emu-component-containers-comparison-slider-divider-color-border-dark);
  --emu-component-containers-comparison-slider-handle-icon-thickness-narrow: var(--emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow);
  --emu-component-containers-comparison-slider-handle-icon-thickness-wide: var(--emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide);
  --emu-component-containers-comparison-slider-handle-text-typography-narrow: var(--emu-component-containers-comparison-slider-custom-handle-text-typography-narrow);
  --emu-component-containers-comparison-slider-handle-text-typography-wide: var(--emu-component-containers-comparison-slider-custom-handle-text-typography-narrow);
  --emu-component-containers-comparison-slider-handle-text-color-light: var(--emu-component-containers-comparison-slider-custom-handle-text-color-light);
  --emu-component-containers-comparison-slider-handle-text-color-dark: var(--emu-component-containers-comparison-slider-custom-handle-text-color-dark);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-narrow: var(--emu-component-containers-comparison-slider-custom-handle-padding-top-narrow) var(--emu-component-containers-comparison-slider-custom-handle-padding-right-narrow) var(--emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow) var(--emu-component-containers-comparison-slider-custom-handle-padding-left-narrow);
  --emu-component-containers-comparison-slider-custom-handle-padding-wide: var(--emu-component-containers-comparison-slider-custom-handle-padding-top-wide) var(--emu-component-containers-comparison-slider-custom-handle-padding-right-wide) var(--emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide) var(--emu-component-containers-comparison-slider-custom-handle-padding-left-wide);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-narrow: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-wide: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-comparison-slider-custom-handle-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-typography-narrow: var(--emu-semantic-font-weight-regular) var(--emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow)/var(--emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow) var(--emu-semantic-font-families-body);
  --emu-component-containers-comparison-slider-custom-handle-text-typography-wide: var(--emu-semantic-font-weight-regular) var(--emu-component-containers-comparison-slider-custom-handle-text-font-size-wide)/var(--emu-component-containers-comparison-slider-custom-handle-text-line-height-wide) var(--emu-semantic-font-families-body);
  --emu-component-containers-tabs-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-common-colors-transparent);
  --emu-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-common-colors-transparent);
  --emu-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-semantic-colors-primary-50);
  --emu-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-semantic-colors-primary-50);
  --emu-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-content-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-content-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-content-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-content-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-content-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-color-background-light: var(--emu-component-containers-container-color-background-light);
  --emu-component-containers-tabs-content-color-background-dark: var(--emu-component-containers-container-color-background-dark);
  --emu-component-containers-isi-header-padding-top-narrow: 15px;
  --emu-component-containers-isi-header-padding-top-wide: 15px;
  --emu-component-containers-isi-header-padding-right-narrow: 15px;
  --emu-component-containers-isi-header-padding-right-wide: 15px;
  --emu-component-containers-isi-header-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-header-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-header-padding-left-narrow: 15px;
  --emu-component-containers-isi-header-padding-left-wide: 15px;
  --emu-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-border-width-top: var(--emu-semantic-border-width-none);
  --emu-component-containers-isi-body-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-open-content-size: 30vh;
  --emu-component-containers-isi-z-index: var(--emu-common-other-z-index-isi);
  --emu-component-containers-container-max-width: 1720px;
  --emu-component-containers-container-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-narrow: var(--emu-component-containers-container-padding-top-narrow) var(--emu-component-containers-container-padding-right-narrow) var(--emu-component-containers-container-padding-bottom-narrow) var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-container-padding-wide: var(--emu-component-containers-container-padding-top-wide) var(--emu-component-containers-container-padding-right-wide) var(--emu-component-containers-container-padding-bottom-wide) var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-container-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-container-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-color-border-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-color-border-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-margin-top-narrow: var(--emu-component-containers-carousel-indicators-gap-horizontal-narrow);
  --emu-component-containers-carousel-indicators-margin-top-wide: var(--emu-component-containers-carousel-indicators-gap-horizontal-wide);
  --emu-component-containers-carousel-indicators-margin-bottom-narrow: var(--emu-component-containers-carousel-indicators-gap-horizontal-narrow);
  --emu-component-containers-carousel-indicators-margin-bottom-wide: var(--emu-component-containers-carousel-indicators-gap-horizontal-wide);
  --emu-component-containers-carousel-indicators-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-dots-color-background-default-light: #ffffff73;
  --emu-component-containers-carousel-indicators-dots-color-background-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-containers-carousel-indicators-dots-color-background-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-containers-carousel-indicators-dots-color-border-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-containers-carousel-indicators-dots-color-border-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-containers-carousel-indicators-dots-color-border-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-containers-carousel-indicators-dots-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-indicators-dots-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-indicators-dots-size-narrow: 4px;
  --emu-component-containers-carousel-indicators-dots-size-wide: 4px;
  --emu-component-containers-carousel-indicators-dots-gap-narrow: 0px;
  --emu-component-containers-carousel-indicators-dots-gap-wide: 0px;
  --emu-component-containers-carousel-indicators-dots-padding-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-dots-padding-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-image-color-border-default-light: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-default-light);
  --emu-component-containers-carousel-indicators-image-color-border-default-dark: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-default-dark);
  --emu-component-containers-carousel-indicators-image-color-border-active-light: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-active-light);
  --emu-component-containers-carousel-indicators-image-color-border-active-dark: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-active-dark);
  --emu-component-containers-carousel-indicators-image-border-width-all: var(--emu-component-containers-carousel-indicators-thumbnail-border-width-narrow);
  --emu-component-containers-carousel-indicators-image-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-image-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-thumbnail-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-thumbnail-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-thumbnail-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-thumbnail-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-thumbnail-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-indicators-thumbnail-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-thumbnail-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-text-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-light: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-dark: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-carousel-indicators-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-carousel-controls-padding-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-controls-padding-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-controls-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-controls-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-color-border-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-border-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-controls-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-controls-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-controls-z-index: var(--emu-common-other-z-index-layer);
  --emu-component-containers-carousel-controls-icon-color-fill-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-containers-carousel-controls-icon-color-fill-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-containers-carousel-controls-icon-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-controls-icon-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-frame-container-padding-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-frame-container-padding-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-frame-container-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-frame-container-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-frame-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-frame-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-frame-color-background-default-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-frame-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-frame-color-background-active-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-frame-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-frame-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-frame-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-frame-color-border-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-frame-color-border-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-frame-border-width-default-narrow: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-frame-border-width-default-wide: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-frame-border-width-active-narrow: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-frame-border-width-active-wide: var(--emu-common-border-width-none);
  --emu-component-containers-embed-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-embed-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-embed-player-vimeo-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-vimeo-global-text-typography-line-height: 1.7;
  --emu-component-containers-embed-player-vimeo-color-background-light: rgba(0, 0, 0, 1);
  --emu-component-containers-embed-player-vimeo-color-background-dark: rgba(0, 0, 0, 1);
  --emu-component-containers-embed-player-vimeo-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-vimeo-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-vimeo-badge-text-color-light: #ffffff;
  --emu-component-containers-embed-player-vimeo-badge-text-color-dark: #ffffff;
  --emu-component-containers-embed-player-vimeo-menu-color-background-light: rgba(255, 255, 255, 0.9);
  --emu-component-containers-embed-player-vimeo-menu-color-background-dark: rgba(255, 255, 255, 0.9);
  --emu-component-containers-embed-player-vimeo-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-shadow: 0 1 2 0 rgba(0, 0, 0, 0.15);
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-vimeo-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-vimeo-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-vimeo-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-vimeo-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-vimeo-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-vimeo-controls-global-padding: 7px;
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-start: rgba(0, 0, 0, 0);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-end: rgba(0, 0, 0, 0.75);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-start: rgba(0, 0, 0, 0);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-end: rgba(0, 0, 0, 0.75);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-light: rgba(255, 255, 255, 0.9);
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-dark: rgba(255, 255, 255, 0.9);
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-shadow: 0 1 2 0 rgba(0, 0, 0, 0.15);
  --emu-component-containers-embed-player-vimeo-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-tooltip-padding: 5px;
  --emu-component-containers-embed-player-vimeo-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-light: rgba(35, 40, 47, 0.6);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-dark: rgba(35, 40, 47, 0.6);
  --emu-component-containers-embed-player-vimeo-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one: 0 1 1 0 rgba(215, 26, 18, 0.15);
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-two: 0 0 0 1 rgba(215, 26, 18, 0.2);
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-vimeo-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-light: rgba(193, 200, 209, 0.6);
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-dark: rgba(193, 200, 209, 0.6);
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-light: rgba(215, 26, 18, 0.1);
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-dark: rgba(215, 26, 18, 0.1);
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-light: rgba(255, 255, 255, 0.25);
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-dark: rgba(255, 255, 255, 0.25);
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-light: rgba(255, 255, 255, 0.25);
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-dark: rgba(255, 255, 255, 0.25);
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-light: rgba(255, 255, 255, 0.5);
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-dark: rgba(255, 255, 255, 0.5);
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-light: rgba(255, 255, 255, 0.25);
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-dark: rgba(255, 255, 255, 0.25);
  --emu-component-containers-embed-player-vimeo-captions-text-color-light: #ffffff;
  --emu-component-containers-embed-player-vimeo-captions-text-color-dark: #ffffff;
  --emu-component-containers-embed-player-vimeo-captions-color-background-light: rgba(0, 0, 0, 0.8);
  --emu-component-containers-embed-player-vimeo-captions-color-background-dark: rgba(0, 0, 0, 0.8);
  --emu-component-containers-embed-player-vimeo-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-youtube-global-text-typography-line-height: 1.7;
  --emu-component-containers-embed-player-youtube-color-background-light: rgba(0, 0, 0, 1);
  --emu-component-containers-embed-player-youtube-color-background-dark: rgba(0, 0, 0, 1);
  --emu-component-containers-embed-player-youtube-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-youtube-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-youtube-badge-text-color-light: #ffffff;
  --emu-component-containers-embed-player-youtube-badge-text-color-dark: #ffffff;
  --emu-component-containers-embed-player-youtube-menu-color-background-light: rgba(255, 255, 255, 0.9);
  --emu-component-containers-embed-player-youtube-menu-color-background-dark: rgba(255, 255, 255, 0.9);
  --emu-component-containers-embed-player-youtube-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-shadow: 0 1 2 0 rgba(0, 0, 0, 0.15);
  --emu-component-containers-embed-player-youtube-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-youtube-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-youtube-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-youtube-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-youtube-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-youtube-controls-global-padding: 7px;
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-start: rgba(0, 0, 0, 0);
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-end: rgba(0, 0, 0, 0.75);
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-start: rgba(0, 0, 0, 0);
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-end: rgba(0, 0, 0, 0.75);
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-light: rgba(255, 255, 255, 0.9);
  --emu-component-containers-embed-player-youtube-tooltip-color-background-dark: rgba(255, 255, 255, 0.9);
  --emu-component-containers-embed-player-youtube-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-shadow: 0 1 2 0 rgba(0, 0, 0, 0.15);
  --emu-component-containers-embed-player-youtube-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-tooltip-padding: 5px;
  --emu-component-containers-embed-player-youtube-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-light: rgba(35, 40, 47, 0.6);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-dark: rgba(35, 40, 47, 0.6);
  --emu-component-containers-embed-player-youtube-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-one: 0 1 1 0 rgba(215, 26, 18, 0.15);
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-two: 0 0 0 1 rgba(215, 26, 18, 0.2);
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-youtube-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-light: rgba(193, 200, 209, 0.6);
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-dark: rgba(193, 200, 209, 0.6);
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-light: rgba(215, 26, 18, 0.1);
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-dark: rgba(215, 26, 18, 0.1);
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-light: rgba(255, 255, 255, 0.25);
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-dark: rgba(255, 255, 255, 0.25);
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-light: rgba(255, 255, 255, 0.25);
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-dark: rgba(255, 255, 255, 0.25);
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-light: rgba(255, 255, 255, 0.5);
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-dark: rgba(255, 255, 255, 0.5);
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-light: rgba(255, 255, 255, 0.25);
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-dark: rgba(255, 255, 255, 0.25);
  --emu-component-containers-embed-player-youtube-captions-text-color-light: #ffffff;
  --emu-component-containers-embed-player-youtube-captions-text-color-dark: #ffffff;
  --emu-component-containers-embed-player-youtube-captions-color-background-light: rgba(0, 0, 0, 0.8);
  --emu-component-containers-embed-player-youtube-captions-color-background-dark: rgba(0, 0, 0, 0.8);
  --emu-component-containers-embed-player-youtube-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-gallery-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-separator-content-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-sizing-width-narrow: 100%;
  --emu-component-containers-gallery-item-sizing-width-wide: calc(33.33%  -  8px);
  --emu-component-containers-gallery-item-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-item-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-stage-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-gap-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-preview-container-gap-wide: var(--emu-common-sizing-xs);
  --emu-component-layers-modal-z-index: var(--emu-common-other-z-index-modal);
  --emu-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-layers-modal-body-sizing-width: 100%;
  --emu-component-layers-modal-body-sizing-max-width: 850px;
  --emu-component-layers-modal-body-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-modal-body-color-border-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-border-width-all: var(--emu-semantic-border-width-none);
  --emu-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-text-typography: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-layers-modal-actions-close-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-layers-cookie-consent-banner-z-index: var(--emu-common-other-z-index-cookie-banner);
  --emu-component-layers-cookie-consent-banner-max-width: var(--emu-component-containers-container-max-width);
  --emu-component-layers-cookie-consent-banner-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-shadow: 4px 4px 20px 4px rgba(0, 0, 0, 0.2);
  --emu-component-layers-cookie-consent-banner-padding-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-top-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-right-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-right-wide: 32px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-left-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-left-wide: 32px;
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) 12px/17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-wide: var(--emu-semantic-font-weight-regular) 12px/17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-wide: 0px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-wide: 56px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-outline-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light: var(--emu-common-colors-grey-300);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark: var(--emu-common-colors-grey-300);
}