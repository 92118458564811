footer.footer {
  z-index: calc(var(--emu-common-other-z-index-isi) + 1);
  position: relative;

  .footer {
    &__wrapper {
      padding-bottom: 30px;
      margin-top: -1px;
    }

    &__links {
      @include mq('large') {
        display: flex;
        justify-content: center;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        font-family: var(--emu-semantic-font-families-mono);
        margin-bottom: 20px;

        @include mq('large') {
          max-width: 60%; //as per live
        }

        li {
          font-size: var(--emu-common-font-sizes-wide-medium);
          padding-top: 5px;
          padding-bottom: 5px;
          padding-left: var(--emu-common-spacing-small);
          padding-right: var(--emu-common-spacing-small);
          line-height: var(--emu-common-line-heights-wide-medium);

          @include mq('medium') {
            padding-top: var(--emu-common-spacing-none);
            padding-bottom: var(--emu-common-spacing-none);
            padding-left: 15px;
            padding-right: 15px;
            line-height: 28px;
          }

          a {
            font-size: 13px;
            font-family: var(--emu-semantic-font-families-body);
            font-weight: var(--emu-semantic-font-weight-500);
          }

          img {
            width: 30px; //as per live
            margin-left: 3px;
            vertical-align: middle;
            margin-bottom: var(--emu-common-spacing-xxs);
          }
        }
      }
    }

    &__note {
      font-size: var(--emu-semantic-font-sizes-wide-xs);
      line-height: 1.5;
      max-width: 90%; //as per live
      margin-left: auto;
      margin-right: auto;

      @include mq('medium') {
        max-width: 100%; //as per live
      }

      @include mq('large') {
        font-size: var(--emu-common-font-sizes-wide-medium);
      }

      br {
        display: none;

        @include mq('medium') {
          display: block;
        }
      }

      .emphasis {
        white-space: nowrap;
      }
    }
  }
}
