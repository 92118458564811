:root {
  --emu-common-spacing-xs: 4px;
  --emu-common-spacing-xxs: 2px;
  --emu-common-spacing-small: 8px;
  --emu-common-spacing-medium: 16px;
  --emu-common-spacing-large: 32px;
  --emu-common-spacing-xl: 64px;
  --emu-common-spacing-none: 0px;
  --emu-common-spacing-unset: unset;
  --emu-common-colors-white: #fff;
  --emu-common-colors-black: #000;
  --emu-common-colors-red-50: #fff5f5;
  --emu-common-colors-red-100: #ffdcdb;
  --emu-common-colors-red-200: #ffaba8;
  --emu-common-colors-red-300: #ff7f7a;
  --emu-common-colors-red-400: #ff4e47;
  --emu-common-colors-red-500: #ff1d15;
  --emu-common-colors-red-600: #db0700;
  --emu-common-colors-red-700: #a30500;
  --emu-common-colors-red-800: #700400;
  --emu-common-colors-red-900: #380200;
  --emu-common-colors-red-950: #190100;
  --emu-common-colors-grey-100: #f4f5f5;
  --emu-common-colors-grey-200: #d4d7d8;
  --emu-common-colors-grey-300: #b4b8bb;
  --emu-common-colors-grey-400: #949a9e;
  --emu-common-colors-grey-500: #7e868b;
  --emu-common-colors-grey-600: #61676b;
  --emu-common-colors-grey-700: #44484b;
  --emu-common-colors-grey-800: #303436;
  --emu-common-colors-grey-900: #1d1f20;
  --emu-common-colors-transparent: #0000;
  --emu-common-colors-teal-50: #e0fbff;
  --emu-common-colors-teal-100: #c2f7ff;
  --emu-common-colors-teal-200: #85efff;
  --emu-common-colors-teal-300: #42e6ff;
  --emu-common-colors-teal-400: #05deff;
  --emu-common-colors-teal-500: #00abc7;
  --emu-common-colors-teal-600: #00899e;
  --emu-common-colors-teal-700: #006675;
  --emu-common-colors-teal-800: #004752;
  --emu-common-colors-teal-900: #002329;
  --emu-common-colors-teal-950: #001214;
  --emu-common-colors-beige-50: #fefcfb;
  --emu-common-colors-beige-100: #fdf9f7;
  --emu-common-colors-beige-200: #f9efeb;
  --emu-common-colors-beige-300: #f7e8e3;
  --emu-common-colors-beige-400: #f5e2db;
  --emu-common-colors-beige-500: #f2d9d0;
  --emu-common-colors-beige-600: #dfa18b;
  --emu-common-colors-beige-700: #cb6743;
  --emu-common-colors-beige-800: #8c4227;
  --emu-common-colors-beige-900: #482214;
  --emu-common-colors-beige-950: #24110a;
  --emu-common-colors-light-brown-50: #faf6f5;
  --emu-common-colors-light-brown-100: #f5ecea;
  --emu-common-colors-light-brown-200: #ead7d2;
  --emu-common-colors-light-brown-300: #e0c4bd;
  --emu-common-colors-light-brown-400: #d5aea5;
  --emu-common-colors-light-brown-500: #ca9a8e;
  --emu-common-colors-light-brown-600: #b4705f;
  --emu-common-colors-light-brown-700: #8e5243;
  --emu-common-colors-light-brown-800: #5e362c;
  --emu-common-colors-light-brown-900: #311c17;
  --emu-common-colors-light-brown-950: #180e0b;
  --emu-common-colors-medium-brown-50: #f6f0ef;
  --emu-common-colors-medium-brown-100: #ebdddb;
  --emu-common-colors-medium-brown-200: #d6bcb8;
  --emu-common-colors-medium-brown-300: #c29a94;
  --emu-common-colors-medium-brown-400: #ad7971;
  --emu-common-colors-medium-brown-500: #925c54;
  --emu-common-colors-medium-brown-600: #754a43;
  --emu-common-colors-medium-brown-700: #573732;
  --emu-common-colors-medium-brown-800: #3a2522;
  --emu-common-colors-medium-brown-900: #1d1211;
  --emu-common-colors-medium-brown-950: #100a09;
  --emu-common-colors-dark-brown-50: #f1e4e5;
  --emu-common-colors-dark-brown-100: #e3cacb;
  --emu-common-colors-dark-brown-200: #c8989a;
  --emu-common-colors-dark-brown-300: #ac6365;
  --emu-common-colors-dark-brown-400: #7e4345;
  --emu-common-colors-dark-brown-500: #492728;
  --emu-common-colors-dark-brown-600: #3c2021;
  --emu-common-colors-dark-brown-700: #2b1718;
  --emu-common-colors-dark-brown-800: #1e1010;
  --emu-common-colors-dark-brown-900: #0d0707;
  --emu-common-colors-dark-brown-950: #070404;
  --emu-common-colors-light-grey-50: #fff;
  --emu-common-colors-light-grey-100: #fdfcfc;
  --emu-common-colors-light-grey-200: #fdfcfc;
  --emu-common-colors-light-grey-300: #fcf9f8;
  --emu-common-colors-light-grey-400: #fcf9f8;
  --emu-common-colors-light-grey-500: #faf6f5;
  --emu-common-colors-light-grey-600: #dabcb4;
  --emu-common-colors-light-grey-700: #b87f70;
  --emu-common-colors-light-grey-800: #855042;
  --emu-common-colors-light-grey-900: #221411;
  --emu-common-colors-medium-grey-50: #fff;
  --emu-common-colors-medium-grey-100: #f8f7f7;
  --emu-common-colors-medium-grey-200: #e6e5e5;
  --emu-common-colors-medium-grey-300: #d8d5d5;
  --emu-common-colors-medium-grey-400: #c9c5c5;
  --emu-common-colors-medium-grey-500: #b9b4b4;
  --emu-common-colors-medium-grey-600: #958e8e;
  --emu-common-colors-medium-grey-700: #716a6a;
  --emu-common-colors-medium-grey-800: #4c4747;
  --emu-common-colors-medium-grey-900: #252222;
  --emu-common-colors-medium-grey-950: #121111;
  --emu-common-colors-dark-grey-50: #f7f7f7;
  --emu-common-colors-dark-grey-100: #e8e8e8;
  --emu-common-colors-dark-grey-200: #c9c9ca;
  --emu-common-colors-dark-grey-300: #adadae;
  --emu-common-colors-dark-grey-400: #8e8f90;
  --emu-common-colors-dark-grey-500: #6f7071;
  --emu-common-colors-dark-grey-600: #58595a;
  --emu-common-colors-dark-grey-700: #424243;
  --emu-common-colors-dark-grey-800: #2d2e2e;
  --emu-common-colors-dark-grey-900: #171717;
  --emu-common-colors-dark-grey-950: #0a0a0a;
  --emu-common-colors-blush-50: #fff;
  --emu-common-colors-blush-100: #fdfcfc;
  --emu-common-colors-blush-200: #f4ebef;
  --emu-common-colors-blush-300: #eddee4;
  --emu-common-colors-blush-400: #e6d1d9;
  --emu-common-colors-blush-500: #ddc1cc;
  --emu-common-colors-blush-600: #c08ca1;
  --emu-common-colors-blush-700: #a15876;
  --emu-common-colors-blush-800: #693a4d;
  --emu-common-colors-blush-900: #351d26;
  --emu-common-colors-blush-950: #1a0e13;
  --emu-common-colors-abbvie-blue-50: #e3ebfc;
  --emu-common-colors-abbvie-blue-100: #bed1f9;
  --emu-common-colors-abbvie-blue-200: #6a97f1;
  --emu-common-colors-abbvie-blue-300: #1b60e9;
  --emu-common-colors-abbvie-blue-400: #0f3d9a;
  --emu-common-colors-abbvie-blue-500: #071d49;
  --emu-common-colors-abbvie-blue-600: #06183c;
  --emu-common-colors-abbvie-blue-700: #04112a;
  --emu-common-colors-abbvie-blue-800: #030b1c;
  --emu-common-colors-abbvie-blue-900: #01060e;
  --emu-common-colors-abbvie-blue-950: #010409;
  --emu-common-font-families-sans: Arial, sans-serif;
  --emu-common-font-families-serif: Times New Roman, serif;
  --emu-common-font-families-mono: Courier, monospace;
  --emu-common-font-weight-bold: 700;
  --emu-common-font-weight-light: 300;
  --emu-common-font-weight-black: 900;
  --emu-common-font-weight-regular: 400;
  --emu-common-font-sizes-narrow-medium: 12px;
  --emu-common-font-sizes-narrow-large: 16.97px;
  --emu-common-font-sizes-narrow-xl: 23.99px;
  --emu-common-font-sizes-narrow-xxl: 33.93px;
  --emu-common-font-sizes-narrow-xxxl: 47.97px;
  --emu-common-font-sizes-wide-medium: 16px;
  --emu-common-font-sizes-wide-large: 22.62px;
  --emu-common-font-sizes-wide-xl: 31.99px;
  --emu-common-font-sizes-wide-xxl: 45.23px;
  --emu-common-font-sizes-wide-xxxl: 63.96px;
  --emu-common-line-heights-narrow-large: 18px;
  --emu-common-line-heights-narrow-medium: 18px;
  --emu-common-line-heights-narrow-xl: 36px;
  --emu-common-line-heights-narrow-xxl: 36px;
  --emu-common-line-heights-narrow-xxxl: 54px;
  --emu-common-line-heights-wide-large: 24px;
  --emu-common-line-heights-wide-medium: 24px;
  --emu-common-line-heights-wide-xl: 48px;
  --emu-common-line-heights-wide-xxl: 48px;
  --emu-common-line-heights-wide-xxxl: 72px;
  --emu-common-border-radius-xxs: 2px;
  --emu-common-border-radius-xs: 4px;
  --emu-common-border-radius-small: 8px;
  --emu-common-border-radius-medium: 16px;
  --emu-common-border-radius-large: 32px;
  --emu-common-border-radius-none: 0px;
  --emu-common-border-width-thin: 1px;
  --emu-common-border-width-medium: 2px;
  --emu-common-border-width-thick: 4px;
  --emu-common-border-width-none: 0px;
  --emu-common-outline-width-thin: thin;
  --emu-common-outline-width-medium: medium;
  --emu-common-outline-width-thick: thick;
  --emu-common-outline-width-unset: unset;
  --emu-common-sizing-none: 0px;
  --emu-common-sizing-xxs: 1px;
  --emu-common-sizing-xs: 8px;
  --emu-common-sizing-small: 16px;
  --emu-common-sizing-medium: 32px;
  --emu-common-sizing-large: 64px;
  --emu-common-sizing-xl: 120px;
  --emu-common-sizing-xxl: 256px;
  --emu-common-sizing-xxxl: 1170px;
  --emu-common-sizing-container: 1520px;
  --emu-common-sizing-container-large: 1720px;
  --emu-common-other-time-transition-short: .2s;
  --emu-common-other-time-transition-base: .4s;
  --emu-common-other-time-transition-long: .6s;
  --emu-common-other-time-transition-xl: 1s;
  --emu-common-other-time-duration-instant: .4s;
  --emu-common-other-time-duration-short: 2s;
  --emu-common-other-time-duration-base: 4s;
  --emu-common-other-time-duration-long: 6s;
  --emu-common-other-time-delay-none: 0s;
  --emu-common-other-time-delay-short: 50ms;
  --emu-common-other-time-delay-base: .1s;
  --emu-common-other-time-delay-long: .2s;
  --emu-common-other-z-index-cookie-banner: 700;
  --emu-common-other-z-index-modal: 600;
  --emu-common-other-z-index-header: 500;
  --emu-common-other-z-index-isi: 400;
  --emu-common-other-z-index-overlay: 300;
  --emu-common-other-z-index-layer: 100;
  --emu-common-other-z-index-base: 0;
  --emu-common-other-z-index-behind: -1;
  --emu-common-box-shadow-soft-light: 0 0 6 0 var(--emu-common-colors-grey-700);
  --emu-semantic-colors-none: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-light: var(--emu-common-colors-light-brown-100);
  --emu-semantic-colors-surface-dark: var(--emu-common-colors-dark-brown-500);
  --emu-semantic-colors-surface-modal-backdrop: #0006;
  --emu-semantic-colors-actions-primary-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-semantic-colors-actions-primary-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-semantic-colors-actions-primary-default-light: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-actions-primary-default-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-actions-primary-hover-light: var(--emu-semantic-colors-primary-600);
  --emu-semantic-colors-actions-primary-hover-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-active-light: var(--emu-semantic-colors-primary-600);
  --emu-semantic-colors-actions-primary-active-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-focus-light: var(--emu-semantic-colors-primary-600);
  --emu-semantic-colors-actions-primary-focus-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-disabled-light: var(--emu-common-colors-medium-grey-500);
  --emu-semantic-colors-actions-primary-disabled-dark: var(--emu-common-colors-medium-grey-300);
  --emu-semantic-colors-actions-primary-error-light: var(--emu-semantic-colors-error-500);
  --emu-semantic-colors-actions-primary-error-dark: var(--emu-semantic-colors-error-300);
  --emu-semantic-colors-actions-on-primary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-primary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-secondary-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-semantic-colors-actions-secondary-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-semantic-colors-actions-secondary-default-light: var(--emu-semantic-colors-secondary-500);
  --emu-semantic-colors-actions-secondary-default-dark: var(--emu-common-colors-blush-500);
  --emu-semantic-colors-actions-secondary-hover-light: var(--emu-semantic-colors-secondary-600);
  --emu-semantic-colors-actions-secondary-hover-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-active-light: var(--emu-semantic-colors-secondary-600);
  --emu-semantic-colors-actions-secondary-active-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-focus-light: var(--emu-semantic-colors-secondary-600);
  --emu-semantic-colors-actions-secondary-focus-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-disabled-light: var(--emu-common-colors-medium-grey-300);
  --emu-semantic-colors-actions-secondary-disabled-dark: var(--emu-common-colors-medium-grey-200);
  --emu-semantic-colors-actions-on-secondary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-secondary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-text-light: var(--emu-common-colors-black);
  --emu-semantic-colors-text-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-foreground-light: var(--emu-common-colors-medium-brown-300);
  --emu-semantic-colors-foreground-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-primary-0: var(--emu-common-colors-white);
  --emu-semantic-colors-primary-50: #363636;
  --emu-semantic-colors-primary-100: #004f50;
  --emu-semantic-colors-primary-200: #212529;
  --emu-semantic-colors-primary-300: #88b9bb;
  --emu-semantic-colors-primary-400: #3636367a;
  --emu-semantic-colors-primary-500: #36363691;
  --emu-semantic-colors-primary-600: #f1f0f6;
  --emu-semantic-colors-primary-700: #f0f5f5;
  --emu-semantic-colors-primary-800: #eae9ef;
  --emu-semantic-colors-primary-900: #ebeaf0;
  --emu-semantic-colors-primary-950: #c3d5d7;
  --emu-semantic-colors-primary-light: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-primary-dark: var(--emu-semantic-colors-primary-200);
  --emu-semantic-colors-secondary-50: #c3d6d740;
  --emu-semantic-colors-secondary-100: #ffffffde;
  --emu-semantic-colors-secondary-200: #b0c9c9;
  --emu-semantic-colors-secondary-300: #fefefe;
  --emu-semantic-colors-secondary-400: #c3d6d7;
  --emu-semantic-colors-secondary-500: #ffffff6e;
  --emu-semantic-colors-secondary-600: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-secondary-700: var(--emu-common-colors-blush-700);
  --emu-semantic-colors-secondary-800: var(--emu-common-colors-blush-800);
  --emu-semantic-colors-secondary-900: var(--emu-common-colors-blush-900);
  --emu-semantic-colors-secondary-950: var(--emu-common-colors-blush-950);
  --emu-semantic-colors-secondary-light: var(--emu-semantic-colors-secondary-500);
  --emu-semantic-colors-secondary-dark: var(--emu-semantic-colors-secondary-200);
  --emu-semantic-colors-tertiary-50: var(--emu-common-colors-abbvie-blue-50);
  --emu-semantic-colors-tertiary-100: var(--emu-common-colors-abbvie-blue-100);
  --emu-semantic-colors-tertiary-200: var(--emu-common-colors-abbvie-blue-200);
  --emu-semantic-colors-tertiary-300: var(--emu-common-colors-abbvie-blue-300);
  --emu-semantic-colors-tertiary-400: var(--emu-common-colors-abbvie-blue-400);
  --emu-semantic-colors-tertiary-500: var(--emu-common-colors-abbvie-blue-500);
  --emu-semantic-colors-tertiary-600: var(--emu-common-colors-abbvie-blue-600);
  --emu-semantic-colors-tertiary-700: var(--emu-common-colors-abbvie-blue-700);
  --emu-semantic-colors-tertiary-800: var(--emu-common-colors-abbvie-blue-800);
  --emu-semantic-colors-tertiary-900: var(--emu-common-colors-abbvie-blue-900);
  --emu-semantic-colors-tertiary-950: var(--emu-common-colors-abbvie-blue-950);
  --emu-semantic-colors-tertiary-light: var(--emu-semantic-colors-tertiary-500);
  --emu-semantic-colors-tertiary-dark: var(--emu-semantic-colors-tertiary-200);
  --emu-semantic-colors-error-50: var(--emu-common-colors-red-50);
  --emu-semantic-colors-error-100: var(--emu-common-colors-red-100);
  --emu-semantic-colors-error-200: var(--emu-common-colors-red-200);
  --emu-semantic-colors-error-300: var(--emu-common-colors-red-300);
  --emu-semantic-colors-error-400: var(--emu-common-colors-red-400);
  --emu-semantic-colors-error-500: var(--emu-common-colors-red-500);
  --emu-semantic-colors-error-600: var(--emu-common-colors-red-600);
  --emu-semantic-colors-error-700: var(--emu-common-colors-red-700);
  --emu-semantic-colors-error-800: var(--emu-common-colors-red-800);
  --emu-semantic-colors-error-900: var(--emu-common-colors-red-900);
  --emu-semantic-colors-error-950: var(--emu-common-colors-red-950);
  --emu-semantic-colors-error-light: var(--emu-semantic-colors-error-500);
  --emu-semantic-colors-error-dark: var(--emu-semantic-colors-error-200);
  --emu-semantic-colors-background-light: var(--emu-common-colors-light-brown-50);
  --emu-semantic-colors-background-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-font-weight-500: 500;
  --emu-semantic-font-weight-bold: var(--emu-common-font-weight-bold);
  --emu-semantic-font-weight-light: var(--emu-common-font-weight-light);
  --emu-semantic-font-weight-black: var(--emu-common-font-weight-black);
  --emu-semantic-font-weight-regular: var(--emu-common-font-weight-regular);
  --emu-semantic-font-families-body: "brandon-grotesque", sans-serif;
  --emu-semantic-font-families-heading: var(--emu-common-font-families-sans);
  --emu-semantic-font-families-mono: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --emu-semantic-spacing-none: var(--emu-common-spacing-none);
  --emu-semantic-spacing-one-line-height-wide: var(--emu-semantic-line-heights-wide-small);
  --emu-semantic-spacing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-small);
  --emu-semantic-spacing-two-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * 2);
  --emu-semantic-spacing-two-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * 2);
  --emu-semantic-spacing-three-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * 3);
  --emu-semantic-spacing-three-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * 3);
  --emu-semantic-spacing-rem-horizontal: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-narrow: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-wide: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-onerem-narrow: 1rem;
  --emu-semantic-spacing-horizontal-onerem-wide: 1rem;
  --emu-semantic-spacing-horizontal-tworem-narrow: calc(var(--emu-semantic-spacing-horizontal-onerem-narrow) * 2);
  --emu-semantic-spacing-horizontal-tworem-wide: calc(var(--emu-semantic-spacing-horizontal-onerem-wide) * 2);
  --emu-semantic-spacing-rem-vertical: var(--emu-common-spacing-none);
  --emu-semantic-spacing-vertical-narrow: var(--emu-common-spacing-none);
  --emu-semantic-spacing-vertical-wide: var(--emu-common-spacing-none);
  --emu-semantic-spacing-half-line-height-wide: calc(var(--emu-semantic-line-heights-wide-small) * .5);
  --emu-semantic-spacing-half-line-height-narrow: calc(var(--emu-semantic-line-heights-narrow-small) * .5);
  --emu-semantic-spacing-quarter-line-height-wide: calc(var(--emu-semantic-line-heights-wide-small) * .25);
  --emu-semantic-spacing-quarter-line-height-narrow: calc(var(--emu-semantic-line-heights-narrow-small) * .25);
  --emu-semantic-font-sizes-narrow-medium: 22px;
  --emu-semantic-font-sizes-narrow-large: 30px;
  --emu-semantic-font-sizes-narrow-xl: 32px;
  --emu-semantic-font-sizes-narrow-xxl: 40px;
  --emu-semantic-font-sizes-narrow-xxxl: 46px;
  --emu-semantic-font-sizes-narrow-small: 18px;
  --emu-semantic-font-sizes-narrow-xs: 12px;
  --emu-semantic-font-sizes-wide-medium: 22px;
  --emu-semantic-font-sizes-wide-large: 30px;
  --emu-semantic-font-sizes-wide-xl: 32px;
  --emu-semantic-font-sizes-wide-xxl: 60px;
  --emu-semantic-font-sizes-wide-xxxl: 46px;
  --emu-semantic-font-sizes-wide-small: 20px;
  --emu-semantic-font-sizes-wide-xs: 14px;
  --emu-semantic-line-heights-narrow-large: 36px;
  --emu-semantic-line-heights-narrow-medium: 33px;
  --emu-semantic-line-heights-narrow-xl: 38.4px;
  --emu-semantic-line-heights-narrow-xxl: 48px;
  --emu-semantic-line-heights-narrow-xxxl: 69px;
  --emu-semantic-line-heights-narrow-small: 27px;
  --emu-semantic-line-heights-narrow-xs: 14px;
  --emu-semantic-line-heights-wide-large: 36px;
  --emu-semantic-line-heights-wide-medium: 33px;
  --emu-semantic-line-heights-wide-xl: 38.4px;
  --emu-semantic-line-heights-wide-xxl: 72px;
  --emu-semantic-line-heights-wide-xxxl: 69px;
  --emu-semantic-line-heights-wide-small: 30px;
  --emu-semantic-line-heights-wide-xs: 16px;
  --emu-semantic-border-radius-xs: var(--emu-common-border-radius-xs);
  --emu-semantic-border-radius-small: var(--emu-common-border-radius-small);
  --emu-semantic-border-radius-medium: var(--emu-common-border-radius-medium);
  --emu-semantic-border-radius-large: var(--emu-common-border-radius-large);
  --emu-semantic-border-radius-none: var(--emu-common-border-radius-none);
  --emu-semantic-border-width-thin: var(--emu-common-border-width-thin);
  --emu-semantic-border-width-medium: var(--emu-common-border-width-medium);
  --emu-semantic-border-width-thick: var(--emu-common-border-width-thick);
  --emu-semantic-border-width-none: 0px;
  --emu-semantic-outline-width-thin: var(--emu-common-outline-width-thin);
  --emu-semantic-outline-width-medium: var(--emu-common-outline-width-medium);
  --emu-semantic-outline-width-thick: var(--emu-common-outline-width-thick);
  --emu-semantic-outline-width-unset: unset;
  --emu-semantic-sizing-none: var(--emu-common-sizing-none);
  --emu-semantic-sizing-xxs: var(--emu-common-sizing-xxs);
  --emu-semantic-sizing-xs: var(--emu-common-sizing-xs);
  --emu-semantic-sizing-small: var(--emu-common-sizing-small);
  --emu-semantic-sizing-medium: var(--emu-common-sizing-medium);
  --emu-semantic-sizing-large: var(--emu-common-sizing-large);
  --emu-semantic-sizing-xl: var(--emu-common-sizing-xl);
  --emu-semantic-sizing-xxl: var(--emu-common-sizing-xxl);
  --emu-semantic-sizing-breakpoints-small: 320px;
  --emu-semantic-sizing-breakpoints-small-plus: 576px;
  --emu-semantic-sizing-breakpoints-medium: 768px;
  --emu-semantic-sizing-breakpoints-tablet: 993px;
  --emu-semantic-sizing-breakpoints-large: 1024px;
  --emu-semantic-sizing-breakpoints-x-large: 1200px;
  --emu-semantic-sizing-breakpoints-xx-large: 1440px;
  --emu-semantic-sizing-one-line-height-narrow: 20px;
  --emu-semantic-sizing-one-line-height-wide: 20px;
  --emu-semantic-sizing-two-line-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * 2);
  --emu-semantic-sizing-two-line-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * 2);
  --emu-semantic-sizing-three-line-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * 3);
  --emu-semantic-sizing-three-line-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * 3);
  --emu-semantic-sizing-max-width-text-narrow: 40em;
  --emu-semantic-sizing-max-width-text-wide: 55em;
  --emu-semantic-typography-narrow-headings-xxxl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-headings-xxl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-xxl) / var(--emu-semantic-line-heights-narrow-xxl) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xl) / var(--emu-semantic-line-heights-narrow-xl) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-headings-large: var(--emu-semantic-font-weight-500) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-body-regular: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-small) / var(--emu-semantic-line-heights-narrow-small) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxxl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-wide-xxxl) / var(--emu-semantic-line-heights-wide-xxxl) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-wide-xxl) / var(--emu-semantic-line-heights-wide-xxl) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xl) / var(--emu-semantic-line-heights-wide-xl) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-large: var(--emu-semantic-font-weight-500) var(--emu-semantic-font-sizes-wide-large) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-wide-medium) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-body-regular: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-wide-small) / var(--emu-semantic-line-heights-wide-small) var(--emu-semantic-font-families-body);
  --emu-component-cards-card-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-cards-card-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-color-background-light: var(--emu-common-colors-white);
  --emu-component-cards-card-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-size-height: 30px;
  --emu-component-cards-card-header-button-icon-size-width: 30px;
  --emu-component-cards-card-header-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-cards-card-header-button-icon-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-cards-card-header-button-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-teaser-padding-top-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-top-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-narrow: var(--emu-semantic-spacing-two-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-cards-teaser-padding-wide: var(--emu-semantic-spacing-two-line-height-wide) var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-title-max-width: 66.6667%;
  --emu-component-cards-teaser-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-narrow: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-title-margin-wide: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-cards-teaser-description-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-description-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-subtext-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-subtext-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-actions-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-cards-teaser-actions-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-cards-teaser-actions-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-actions-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-disclaimer-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-cards-teaser-disclaimer-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-cards-teaser-old-padding-top-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-top-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-right-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-right-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-bottom-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-bottom-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-left-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-left-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-old-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-old-title-max-width: 66.6667%;
  --emu-component-cards-teaser-old-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-old-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-old-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-old-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-cards-content-fragment-card-margin-narrow: var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-margin-wide: var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-typography-name-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-cards-content-fragment-card-text-typography-name-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-cards-content-fragment-card-text-typography-title-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-cards-content-fragment-card-text-typography-title-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-cards-content-fragment-card-text-typography-bio-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-cards-content-fragment-card-text-typography-bio-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-cards-content-fragment-card-text-margin-narrow: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-margin-wide: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-cards-content-fragment-card-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-cards-content-fragment-card-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow);
  --emu-component-cards-content-fragment-card-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide);
  --emu-component-cards-content-fragment-card-color-background-dark: var(--emu-semantic-colors-primary-950);
  --emu-component-cards-content-fragment-card-color-background-light: var(--emu-semantic-colors-primary-0);
  --emu-component-cards-content-fragment-card-border-radius-narrow: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-cards-content-fragment-card-border-radius-wide: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-cards-content-fragment-card-author-image-sizing-height-narrow: var(--emu-semantic-sizing-xl);
  --emu-component-cards-content-fragment-card-author-image-sizing-height-wide: var(--emu-semantic-sizing-xxl);
  --emu-component-cards-content-fragment-card-author-image-sizing-width-narrow: var(--emu-semantic-sizing-xl);
  --emu-component-cards-content-fragment-card-author-image-sizing-width-wide: var(--emu-semantic-sizing-xxl);
  --emu-component-cards-content-fragment-card-author-image-border-radius-narrow: var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium);
  --emu-component-cards-content-fragment-card-author-image-border-radius-wide: var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium);
  --emu-component-lists-accordion-color-background-light: var(--emu-component-lists-accordion-item-body-color-background-light);
  --emu-component-lists-accordion-color-background-dark: var(--emu-component-lists-accordion-item-body-color-background-dark);
  --emu-component-lists-accordion-color-text-light: var(--emu-semantic-colors-primary-50);
  --emu-component-lists-accordion-color-text-dark: var(--emu-component-lists-accordion-item-header-text-color-dark);
  --emu-component-lists-accordion-color-border-light: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-light);
  --emu-component-lists-accordion-color-border-dark: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-dark);
  --emu-component-lists-accordion-margin-narrow: 0px;
  --emu-component-lists-accordion-margin-wide: 0px;
  --emu-component-lists-accordion-margin-top-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-top-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-color-border-bottom-closed-light: var(--emu-semantic-colors-primary-50);
  --emu-component-lists-accordion-item-color-border-bottom-open-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-color-border-bottom-open-light: var(--emu-semantic-colors-primary-50);
  --emu-component-lists-accordion-item-body-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-lists-accordion-item-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-body-color-border-open-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-body-color-border-open-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-body-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-body-padding-narrow: 20px;
  --emu-component-lists-accordion-item-body-padding-wide: 20px;
  --emu-component-lists-accordion-item-body-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-accordion-item-header-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-lists-accordion-item-header-text-typography-wide: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-lists-accordion-item-header-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-accordion-item-header-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-accordion-item-header-padding-narrow: 6px 12px 6px 0px;
  --emu-component-lists-accordion-item-header-padding-wide: 6px 12px 6px 0px;
  --emu-component-lists-accordion-item-header-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-icon-margin-narrow: var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-component-lists-accordion-item-header-icon-spacing);
  --emu-component-lists-accordion-item-header-icon-margin-wide: var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-component-lists-accordion-item-header-icon-spacing);
  --emu-component-lists-accordion-item-header-icon-spacing: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-item-header-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-lists-accordion-item-header-icon-border-radius: 0px;
  --emu-component-lists-accordion-item-header-icon-transform-open: rotate(0);
  --emu-component-lists-accordion-item-header-icon-transform-closed: rotate(180deg);
  --emu-component-lists-accordion-item-header-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-light: var(--emu-common-colors-transparent);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-light: var(--emu-common-colors-transparent);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-lists-accordion-item-header-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-size-height-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-size-width-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-lists-accordion-item-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-accordion-border-radius-narrow: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-wide: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-lists-accordion-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-border-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-border-hover-light: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-text-color-default-light: var(--emu-semantic-colors-primary-50);
  --emu-component-lists-navigation-banner-item-text-color-default-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-semantic-colors-primary-50);
  --emu-component-lists-navigation-banner-item-text-color-hover-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-dropdown-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-primary-100);
  --emu-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-z-index: var(--emu-common-other-z-index-header);
  --emu-component-lists-navigation-text-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-light: var(--emu-component-ingredients-select-input-option-text-color-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-light: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-light: var(--emu-component-ingredients-select-input-option-text-color-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-light: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-light: var(--emu-component-ingredients-select-input-option-text-color-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-light: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-light: var(--emu-component-ingredients-select-input-option-text-color-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-light: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-light: var(--emu-component-ingredients-select-input-option-color-background-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-light: var(--emu-component-ingredients-select-input-option-color-background-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-light: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-light: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-light: var(--emu-component-ingredients-select-input-option-color-background-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-light: var(--emu-component-ingredients-select-input-option-color-background-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-light: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-light: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-light: var(--emu-component-ingredients-select-input-color-border-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-dark: var(--emu-component-ingredients-select-input-color-border-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-light: var(--emu-component-ingredients-select-input-color-border-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-dark: var(--emu-component-ingredients-select-input-color-border-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-light: var(--emu-component-ingredients-select-input-color-border-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-dark: var(--emu-component-ingredients-select-input-color-border-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-light: var(--emu-component-ingredients-select-input-color-border-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-dark: var(--emu-component-ingredients-select-input-color-border-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-light: var(--emu-component-ingredients-select-input-color-border-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-dark: var(--emu-component-ingredients-select-input-color-border-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-light: var(--emu-component-ingredients-select-input-color-border-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-dark: var(--emu-component-ingredients-select-input-color-border-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-light: var(--emu-component-ingredients-select-input-color-border-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-dark: var(--emu-component-ingredients-select-input-color-border-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-light: var(--emu-component-ingredients-select-input-color-border-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-dark: var(--emu-component-ingredients-select-input-color-border-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-border-radius-narrow: var(--emu-component-ingredients-select-input-border-radius-narrow);
  --emu-component-lists-language-navigation-dropdown-border-radius-wide: var(--emu-component-ingredients-select-input-border-radius-wide);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-padding-narrow: var(--emu-component-ingredients-select-input-padding-narrow);
  --emu-component-lists-language-navigation-dropdown-padding-wide: var(--emu-component-ingredients-select-input-padding-wide);
  --emu-component-lists-language-navigation-dropdown-gap-narrow: var(--emu-component-ingredients-select-input-gap-narrow);
  --emu-component-lists-language-navigation-dropdown-gap-wide: var(--emu-component-ingredients-select-input-gap-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-narrow: var(--emu-component-ingredients-select-input-border-width-filled-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-wide: var(--emu-component-ingredients-select-input-border-width-filled-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-narrow: var(--emu-component-ingredients-select-input-border-width-outline-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-wide: var(--emu-component-ingredients-select-input-border-width-outline-wide);
  --emu-component-lists-language-navigation-pipes-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-gap-wide: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-lists-language-navigation-pipes-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-lists-language-navigation-pipes-color-border-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-language-navigation-pipes-color-border-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-feeds-instagram-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-post-gap-narrow: 40px;
  --emu-component-feeds-instagram-post-gap-wide: 40px;
  --emu-component-ingredients-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-height-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-separator-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-separator-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-separator-size-height: var(--emu-semantic-sizing-xxs);
  --emu-component-ingredients-title-h1-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxxl);
  --emu-component-ingredients-title-h1-typography-wide: var(--emu-semantic-typography-wide-headings-xxxl);
  --emu-component-ingredients-title-h2-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-ingredients-title-h2-typography-wide: var(--emu-semantic-typography-wide-headings-xxl);
  --emu-component-ingredients-title-h3-typography-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-ingredients-title-h3-typography-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-ingredients-title-h4-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-ingredients-title-h4-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-ingredients-title-h5-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-ingredients-title-h5-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-ingredients-title-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-title-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-text-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-text-text-typography-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-ingredients-text-text-typography-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-ingredients-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-ingredients-text-typography-wide: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-ingredients-loader-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-loader-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-image-map-buttons-size-height: 27px;
  --emu-component-ingredients-image-map-buttons-size-width: 27px;
  --emu-component-ingredients-image-map-buttons-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-image-map-buttons-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-image-map-buttons-color-icon-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-ingredients-image-map-buttons-color-icon-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-ingredients-image-map-buttons-border-radius-top-left: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-top-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-left: 50%;
  --emu-component-ingredients-image-map-buttons-shadow: 0 0 2 5 #0009;
  --emu-component-ingredients-google-map-color-background-light: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-color-background-dark: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-image-caption-padding-narrow: calc(var(--emu-semantic-spacing-vertical-narrow) * .5);
  --emu-component-ingredients-label-margin-narrow: var(--emu-semantic-spacing-half-line-height-narrow) 0px;
  --emu-component-ingredients-label-margin-wide: var(--emu-semantic-spacing-half-line-height-wide) 0px;
  --emu-component-ingredients-label-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-label-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-label-text-color-default-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-label-text-color-default-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-label-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-label-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-label-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-label-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-legend-margin-narrow: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-legend-margin-wide: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-legend-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-legend-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-field-message-margin-narrow: var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-field-message-margin-wide: var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-field-message-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-field-message-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-error-message-margin-narrow: var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-error-message-margin-wide: var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-error-message-text-color-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-error-message-text-color-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-text-color-outline-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-select-input-option-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-select-input-option-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-color-border-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-default-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-default-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-hover-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-hover-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-active-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-active-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-active-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-active-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-focus-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-focus-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-disabled-filled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-color-border-disabled-filled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-color-border-disabled-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-disabled-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-error-filled-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-color-border-error-filled-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-color-border-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-color-border-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-select-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-select-input-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-select-input-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-select-input-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-select-input-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-select-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-select-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-select-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-select-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-select-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-select-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-select-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-select-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-select-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-select-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-select-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-select-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-select-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-select-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-select-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-select-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-select-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-text-input-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-input-text-color-empty-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-text-input-text-color-empty-dark: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-text-input-text-color-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-input-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-text-input-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-text-input-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-text-input-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-text-input-text-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-text-input-text-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-text-input-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-empty-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-empty-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-text-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-text-input-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-text-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-text-input-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-empty-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-empty-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-disabled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-disabled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-error-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-error-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-text-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-text-input-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-text-input-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-text-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-text-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-text-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-text-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-text-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-text-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-text-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-text-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-text-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-text-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-text-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-text-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-text-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-text-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-text-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-text-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-text-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-text-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-radio-input-control-size-all-narrow: var(--emu-semantic-sizing-one-line-height-narrow);
  --emu-component-ingredients-radio-input-control-size-all-wide: var(--emu-semantic-sizing-one-line-height-wide);
  --emu-component-ingredients-radio-input-control-border-radius-narrow: var(--emu-semantic-border-radius-medium);
  --emu-component-ingredients-radio-input-control-border-radius-wide: var(--emu-semantic-border-radius-medium);
  --emu-component-ingredients-radio-input-control-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-radio-input-control-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-radio-input-control-color-background-focus-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-focus-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-checked-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-checked-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-disabled-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-disabled-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-error-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-error-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-active-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-active-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-hover-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-hover-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-ingredients-radio-input-control-color-border-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-focus-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-ingredients-radio-input-control-color-border-focus-dark: var(--emu-semantic-colors-actions-primary-focus-dark);
  --emu-component-ingredients-radio-input-control-color-border-checked-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-radio-input-control-color-border-checked-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-radio-input-control-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-radio-input-control-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-control-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-control-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-control-color-border-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-radio-input-control-color-border-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-radio-input-control-color-border-hover-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-ingredients-radio-input-control-color-border-hover-dark: var(--emu-semantic-colors-actions-primary-focus-dark);
  --emu-component-ingredients-radio-input-control-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-control-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-icon-size-all-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)  - 4px);
  --emu-component-ingredients-radio-input-icon-size-all-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)  - 4px);
  --emu-component-ingredients-radio-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-radio-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-radio-input-label-gap-narrow: var(--emu-component-ingredients-label-gap-narrow);
  --emu-component-ingredients-radio-input-label-gap-wide: var(--emu-component-ingredients-label-gap-wide);
  --emu-component-ingredients-radio-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-radio-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-radio-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-radio-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-radio-input-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-checked-light);
  --emu-component-ingredients-radio-input-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-checked-dark);
  --emu-component-ingredients-radio-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-radio-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-radio-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-radio-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-radio-input-legend-margin-narrow: var(--emu-component-ingredients-legend-margin-narrow);
  --emu-component-ingredients-radio-input-legend-margin-wide: var(--emu-component-ingredients-legend-margin-wide);
  --emu-component-ingredients-radio-input-legend-text-color-light: var(--emu-component-ingredients-legend-text-color-light);
  --emu-component-ingredients-radio-input-legend-text-color-dark: var(--emu-component-ingredients-legend-text-color-dark);
  --emu-component-ingredients-radio-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-radio-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-radio-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-radio-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-radio-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-radio-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-radio-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-radio-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-checkbox-input-control-size-all-narrow: var(--emu-semantic-sizing-one-line-height-narrow);
  --emu-component-ingredients-checkbox-input-control-size-all-wide: var(--emu-semantic-sizing-one-line-height-wide);
  --emu-component-ingredients-checkbox-input-control-border-radius-narrow: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-control-border-radius-wide: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-control-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-checkbox-input-control-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-focus-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-focus-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-checked-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-checked-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-disabled-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-disabled-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-error-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-error-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-hover-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-hover-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-active-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-active-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-checkbox-input-control-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-focus-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-focus-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-checked-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-checked-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-control-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-control-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-active-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-active-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-hover-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-hover-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-control-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-icon-size-all-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)  - 4px);
  --emu-component-ingredients-checkbox-input-icon-size-all-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)  - 4px);
  --emu-component-ingredients-checkbox-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-checkbox-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-checkbox-input-label-gap-narrow: var(--emu-component-ingredients-label-gap-narrow);
  --emu-component-ingredients-checkbox-input-label-gap-wide: var(--emu-component-ingredients-label-gap-wide);
  --emu-component-ingredients-checkbox-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-checkbox-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-checked-light);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-checked-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-checkbox-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-checkbox-input-legend-margin-narrow: var(--emu-component-ingredients-legend-margin-narrow);
  --emu-component-ingredients-checkbox-input-legend-margin-wide: var(--emu-component-ingredients-legend-margin-wide);
  --emu-component-ingredients-checkbox-input-legend-text-color-light: var(--emu-component-ingredients-legend-text-color-light);
  --emu-component-ingredients-checkbox-input-legend-text-color-dark: var(--emu-component-ingredients-legend-text-color-dark);
  --emu-component-ingredients-checkbox-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-checkbox-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-checkbox-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-checkbox-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-checkbox-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-checkbox-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-checkbox-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-checkbox-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-textarea-input-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-textarea-input-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-textarea-input-text-color-empty-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-textarea-input-text-color-empty-dark: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-textarea-input-text-color-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-textarea-input-text-color-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-textarea-input-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-textarea-input-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-textarea-input-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-textarea-input-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-textarea-input-text-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-textarea-input-text-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-textarea-input-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-empty-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-empty-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-textarea-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-textarea-input-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-textarea-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-textarea-input-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-empty-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-empty-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-disabled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-disabled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-error-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-error-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-textarea-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-textarea-input-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-textarea-input-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-textarea-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-textarea-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-textarea-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-textarea-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-textarea-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-textarea-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-textarea-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-textarea-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-textarea-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-textarea-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-textarea-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-textarea-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-textarea-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-textarea-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-textarea-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-textarea-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-textarea-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-textarea-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-actions-button-text-typography-narrow: var(--emu-semantic-font-weight-500) 16px / 16px var(--emu-semantic-font-families-body);
  --emu-component-actions-button-text-typography-wide: var(--emu-semantic-font-weight-500) 16px / 16px var(--emu-semantic-font-families-body);
  --emu-component-actions-button-text-color-link-light: var(--emu-component-actions-button-text-color-link-default-light);
  --emu-component-actions-button-text-color-link-dark: var(--emu-component-actions-button-text-color-link-default-dark);
  --emu-component-actions-button-text-color-link-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-button-text-color-link-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-link-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-button-text-color-link-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-button-text-color-link-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-button-text-color-link-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-link-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-button-text-color-link-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-primary-filled-light: var(--emu-component-actions-button-text-color-primary-filled-default-light);
  --emu-component-actions-button-text-color-primary-filled-dark: var(--emu-component-actions-button-text-color-primary-filled-default-dark);
  --emu-component-actions-button-text-color-primary-filled-focus-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-active-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-default-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-light: var(--emu-component-actions-button-text-color-primary-outline-default-light);
  --emu-component-actions-button-text-color-primary-outline-dark: var(--emu-component-actions-button-text-color-primary-outline-default-dark);
  --emu-component-actions-button-text-color-primary-outline-focus-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-text-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-text-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-active-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-text-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-default-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-text-color-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-text-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-text-color-secondary-filled-light: var(--emu-component-actions-button-text-color-secondary-filled-default-light);
  --emu-component-actions-button-text-color-secondary-filled-dark: var(--emu-component-actions-button-text-color-secondary-filled-default-dark);
  --emu-component-actions-button-text-color-secondary-filled-focus-light: var(--emu-semantic-colors-secondary-300);
  --emu-component-actions-button-text-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-hover-light: var(--emu-semantic-colors-secondary-300);
  --emu-component-actions-button-text-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-active-light: var(--emu-semantic-colors-secondary-300);
  --emu-component-actions-button-text-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-default-light: var(--emu-semantic-colors-secondary-300);
  --emu-component-actions-button-text-color-secondary-filled-default-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-light: var(--emu-component-actions-button-text-color-secondary-outline-default-light);
  --emu-component-actions-button-text-color-secondary-outline-dark: var(--emu-component-actions-button-text-color-secondary-outline-default-dark);
  --emu-component-actions-button-text-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-text-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-border-width-primary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-primary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-outline-width-primary-filled: 0;
  --emu-component-actions-button-outline-width-primary-outline: 0;
  --emu-component-actions-button-outline-width-secondary-filled: 0;
  --emu-component-actions-button-outline-width-secondary-outline: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-color-border-primary-filled-light: var(--emu-component-actions-button-color-border-primary-filled-default-light);
  --emu-component-actions-button-color-border-primary-filled-dark: var(--emu-component-actions-button-color-border-primary-filled-default-dark);
  --emu-component-actions-button-color-border-primary-filled-focus-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-primary-filled-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-primary-filled-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-active-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-primary-filled-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-default-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-primary-filled-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-border-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-border-primary-outline-light: var(--emu-component-actions-button-color-border-primary-outline-default-light);
  --emu-component-actions-button-color-border-primary-outline-dark: var(--emu-component-actions-button-color-border-primary-filled-default-dark);
  --emu-component-actions-button-color-border-primary-outline-focus-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-primary-outline-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-primary-outline-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-active-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-primary-outline-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-default-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-border-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-border-secondary-filled-light: var(--emu-component-actions-button-color-border-secondary-filled-default-light);
  --emu-component-actions-button-color-border-secondary-filled-dark: var(--emu-component-actions-button-color-border-secondary-filled-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-focus-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-active-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-secondary-filled-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-default-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-border-secondary-filled-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-border-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-border-secondary-outline-light: var(--emu-component-actions-button-color-border-secondary-outline-default-light);
  --emu-component-actions-button-color-border-secondary-outline-dark: var(--emu-component-actions-button-color-border-secondary-outline-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-border-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-primary-filled-light: var(--emu-component-actions-button-color-background-primary-filled-default-light);
  --emu-component-actions-button-color-background-primary-filled-dark: var(--emu-component-actions-button-color-background-primary-filled-default-dark);
  --emu-component-actions-button-color-background-primary-filled-focus-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-background-primary-filled-focus-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-background-primary-filled-hover-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-active-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-background-primary-filled-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-default-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-background-primary-filled-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-background-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-background-primary-outline-light: var(--emu-component-actions-button-color-background-primary-outline-default-light);
  --emu-component-actions-button-color-background-primary-outline-dark: var(--emu-component-actions-button-color-background-primary-outline-default-dark);
  --emu-component-actions-button-color-background-primary-outline-focus-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-hover-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-active-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-default-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-disabled-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-disabled-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-filled-light: var(--emu-component-actions-button-color-background-secondary-filled-default-light);
  --emu-component-actions-button-color-background-secondary-filled-dark: var(--emu-component-actions-button-color-background-secondary-filled-disabled-dark);
  --emu-component-actions-button-color-background-secondary-filled-focus-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-background-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-secondary-focus-dark);
  --emu-component-actions-button-color-background-secondary-filled-hover-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-background-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-secondary-hover-dark);
  --emu-component-actions-button-color-background-secondary-filled-active-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-background-secondary-filled-active-dark: var(--emu-semantic-colors-actions-secondary-active-dark);
  --emu-component-actions-button-color-background-secondary-filled-default-light: var(--emu-semantic-colors-primary-100);
  --emu-component-actions-button-color-background-secondary-filled-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-background-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-secondary-outline-light: var(--emu-component-actions-button-color-background-secondary-outline-default-light);
  --emu-component-actions-button-color-background-secondary-outline-dark: var(--emu-component-actions-button-color-background-secondary-outline-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-outline-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-default-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-outline-color-primary-filled-light: var(--emu-component-actions-button-color-outline-color-primary-filled-default-light);
  --emu-component-actions-button-color-outline-color-primary-filled-dark: var(--emu-component-actions-button-color-outline-color-primary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-light: var(--emu-component-actions-button-color-outline-color-primary-outline-default-light);
  --emu-component-actions-button-color-outline-color-primary-outline-dark: var(--emu-component-actions-button-color-outline-color-primary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-light: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-light);
  --emu-component-actions-button-color-outline-color-secondary-filled-dark: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-light: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-light);
  --emu-component-actions-button-color-outline-color-secondary-outline-dark: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-group-horizontal-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-horizontal-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-actions-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-actions-button-icon-transform-open: rotate(0);
  --emu-component-actions-button-icon-transform-closed: rotate(180deg);
  --emu-component-actions-button-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-actions-button-icon-color-primary-filled-light: var(--emu-component-actions-button-icon-color-primary-filled-default-light);
  --emu-component-actions-button-icon-color-primary-filled-dark: var(--emu-component-actions-button-icon-color-primary-filled-default-dark);
  --emu-component-actions-button-icon-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-light: var(--emu-component-actions-button-icon-color-primary-outline-default-light);
  --emu-component-actions-button-icon-color-primary-outline-dark: var(--emu-component-actions-button-icon-color-primary-outline-default-dark);
  --emu-component-actions-button-icon-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-default-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-icon-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-default-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-default-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-light: var(--emu-component-actions-button-icon-color-secondary-filled-default-light);
  --emu-component-actions-button-icon-color-secondary-filled-dark: var(--emu-component-actions-button-icon-color-secondary-filled-default-dark);
  --emu-component-actions-button-icon-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-outline-light: var(--emu-component-actions-button-icon-color-secondary-outline-default-light);
  --emu-component-actions-button-icon-color-secondary-outline-dark: var(--emu-component-actions-button-icon-color-secondary-outline-default-dark);
  --emu-component-actions-button-icon-size-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * .75);
  --emu-component-actions-button-icon-size-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * .75);
  --emu-component-actions-button-icon-size-width-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * .75);
  --emu-component-actions-button-icon-size-width-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * .75);
  --emu-component-actions-button-icon-border-radius: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-padding-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-padding-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-component-actions-button-padding-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-padding-right-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-button-padding-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-padding-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-component-actions-button-padding-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-padding-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-button-padding-narrow: 6px 40px;
  --emu-component-actions-button-padding-wide: 6px 40px;
  --emu-component-actions-button-border-radius-primary-filled: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-back-to-top-z-index: var(--emu-common-other-z-index-layer);
  --emu-component-actions-back-to-top-icon-size-height: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-icon-size-width: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-position-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-right-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-right-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-top-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-top-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-add-to-calendar-button-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-button-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-button-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-button-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-add-to-calendar-button-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-add-to-calendar-button-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-button-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-button-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-button-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-button-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-button-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-button-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-add-to-calendar-button-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-width: 165px;
  --emu-component-actions-add-to-calendar-dropdown-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-dropdown-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-dropdown-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-dropdown-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-dropdown-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-actions-add-to-calendar-dropdown-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-actions-add-to-calendar-dropdown-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-add-to-calendar-dropdown-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-add-to-calendar-dropdown-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-add-to-calendar-dropdown-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-height-wide: 24px;
  --emu-component-actions-add-to-calendar-dropdown-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-width-wide: 24px;
  --emu-component-actions-pagination-icon-narrow: var(--emu-semantic-font-sizes-narrow-small);
  --emu-component-actions-pagination-icon-wide: var(--emu-semantic-font-sizes-wide-small);
  --emu-component-actions-pagination-text-color-hover-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-hover-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-default-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-actions-pagination-text-color-default-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-actions-pagination-text-color-active-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-active-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-focus-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-focus-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-typography-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-actions-pagination-text-typography-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-actions-pagination-gap-narrow: var(--emu-semantic-spacing-horizontal-onerem-narrow);
  --emu-component-actions-pagination-gap-wide: var(--emu-semantic-spacing-horizontal-onerem-wide);
  --emu-component-actions-pagination-margin-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-pagination-margin-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-scroll-reveal-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-padding-top-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-top-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-static-section-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-cards-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-comparison-slider-divider-color-border-light: var(--emu-component-containers-comparison-slider-divider-color-light);
  --emu-component-containers-comparison-slider-divider-color-border-dark: var(--emu-component-containers-comparison-slider-divider-color-dark);
  --emu-component-containers-comparison-slider-divider-color-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-comparison-slider-divider-color-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-comparison-slider-divider-border-width-narrow: var(--emu-component-containers-comparison-slider-divider-size-width-narrow);
  --emu-component-containers-comparison-slider-divider-border-width-wide: var(--emu-component-containers-comparison-slider-divider-size-width-wide);
  --emu-component-containers-comparison-slider-divider-size-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-divider-size-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-handle-size-width-narrow: var(--emu-semantic-sizing-medium);
  --emu-component-containers-comparison-slider-handle-size-width-wide: var(--emu-semantic-sizing-large);
  --emu-component-containers-comparison-slider-handle-color-background-light: var(--emu-component-containers-comparison-slider-custom-handle-color-background-light);
  --emu-component-containers-comparison-slider-handle-color-background-dark: var(--emu-component-containers-comparison-slider-custom-handle-color-background-dark);
  --emu-component-containers-comparison-slider-handle-padding-narrow: var(--emu-component-containers-comparison-slider-custom-handle-padding-narrow);
  --emu-component-containers-comparison-slider-handle-padding-wide: var(--emu-component-containers-comparison-slider-custom-handle-padding-wide);
  --emu-component-containers-comparison-slider-handle-icon-size-width-narrow: var(--emu-component-containers-comparison-slider-handle-size-width-narrow);
  --emu-component-containers-comparison-slider-handle-icon-size-width-wide: var(--emu-component-containers-comparison-slider-handle-size-width-wide);
  --emu-component-containers-comparison-slider-handle-icon-color-fill-light: var(--emu-component-containers-comparison-slider-divider-color-border-light);
  --emu-component-containers-comparison-slider-handle-icon-color-fill-dark: var(--emu-component-containers-comparison-slider-divider-color-border-dark);
  --emu-component-containers-comparison-slider-handle-icon-thickness-narrow: var(--emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow);
  --emu-component-containers-comparison-slider-handle-icon-thickness-wide: var(--emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide);
  --emu-component-containers-comparison-slider-handle-text-typography-narrow: var(--emu-component-containers-comparison-slider-custom-handle-text-typography-narrow);
  --emu-component-containers-comparison-slider-handle-text-typography-wide: var(--emu-component-containers-comparison-slider-custom-handle-text-typography-narrow);
  --emu-component-containers-comparison-slider-handle-text-color-light: var(--emu-component-containers-comparison-slider-custom-handle-text-color-light);
  --emu-component-containers-comparison-slider-handle-text-color-dark: var(--emu-component-containers-comparison-slider-custom-handle-text-color-dark);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-narrow: var(--emu-component-containers-comparison-slider-custom-handle-padding-top-narrow) var(--emu-component-containers-comparison-slider-custom-handle-padding-right-narrow) var(--emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow) var(--emu-component-containers-comparison-slider-custom-handle-padding-left-narrow);
  --emu-component-containers-comparison-slider-custom-handle-padding-wide: var(--emu-component-containers-comparison-slider-custom-handle-padding-top-wide) var(--emu-component-containers-comparison-slider-custom-handle-padding-right-wide) var(--emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide) var(--emu-component-containers-comparison-slider-custom-handle-padding-left-wide);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-narrow: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-wide: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-comparison-slider-custom-handle-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-typography-narrow: var(--emu-semantic-font-weight-regular) var(--emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow) / var(--emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow) var(--emu-semantic-font-families-body);
  --emu-component-containers-comparison-slider-custom-handle-text-typography-wide: var(--emu-semantic-font-weight-regular) var(--emu-component-containers-comparison-slider-custom-handle-text-font-size-wide) / var(--emu-component-containers-comparison-slider-custom-handle-text-line-height-wide) var(--emu-semantic-font-families-body);
  --emu-component-containers-tabs-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-common-colors-transparent);
  --emu-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-common-colors-transparent);
  --emu-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-semantic-colors-primary-50);
  --emu-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-semantic-colors-primary-50);
  --emu-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-content-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-content-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-content-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-content-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-content-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-color-background-light: var(--emu-component-containers-container-color-background-light);
  --emu-component-containers-tabs-content-color-background-dark: var(--emu-component-containers-container-color-background-dark);
  --emu-component-containers-isi-header-padding-top-narrow: 15px;
  --emu-component-containers-isi-header-padding-top-wide: 15px;
  --emu-component-containers-isi-header-padding-right-narrow: 15px;
  --emu-component-containers-isi-header-padding-right-wide: 15px;
  --emu-component-containers-isi-header-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-header-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-header-padding-left-narrow: 15px;
  --emu-component-containers-isi-header-padding-left-wide: 15px;
  --emu-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-border-width-top: var(--emu-semantic-border-width-none);
  --emu-component-containers-isi-body-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-open-content-size: 30vh;
  --emu-component-containers-isi-z-index: var(--emu-common-other-z-index-isi);
  --emu-component-containers-container-max-width: 1720px;
  --emu-component-containers-container-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-narrow: var(--emu-component-containers-container-padding-top-narrow) var(--emu-component-containers-container-padding-right-narrow) var(--emu-component-containers-container-padding-bottom-narrow) var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-container-padding-wide: var(--emu-component-containers-container-padding-top-wide) var(--emu-component-containers-container-padding-right-wide) var(--emu-component-containers-container-padding-bottom-wide) var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-container-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-container-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-color-border-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-color-border-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-margin-top-narrow: var(--emu-component-containers-carousel-indicators-gap-horizontal-narrow);
  --emu-component-containers-carousel-indicators-margin-top-wide: var(--emu-component-containers-carousel-indicators-gap-horizontal-wide);
  --emu-component-containers-carousel-indicators-margin-bottom-narrow: var(--emu-component-containers-carousel-indicators-gap-horizontal-narrow);
  --emu-component-containers-carousel-indicators-margin-bottom-wide: var(--emu-component-containers-carousel-indicators-gap-horizontal-wide);
  --emu-component-containers-carousel-indicators-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-dots-color-background-default-light: #ffffff73;
  --emu-component-containers-carousel-indicators-dots-color-background-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-containers-carousel-indicators-dots-color-background-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-containers-carousel-indicators-dots-color-border-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-containers-carousel-indicators-dots-color-border-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-containers-carousel-indicators-dots-color-border-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-containers-carousel-indicators-dots-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-indicators-dots-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-indicators-dots-size-narrow: 4px;
  --emu-component-containers-carousel-indicators-dots-size-wide: 4px;
  --emu-component-containers-carousel-indicators-dots-gap-narrow: 0px;
  --emu-component-containers-carousel-indicators-dots-gap-wide: 0px;
  --emu-component-containers-carousel-indicators-dots-padding-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-dots-padding-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-image-color-border-default-light: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-default-light);
  --emu-component-containers-carousel-indicators-image-color-border-default-dark: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-default-dark);
  --emu-component-containers-carousel-indicators-image-color-border-active-light: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-active-light);
  --emu-component-containers-carousel-indicators-image-color-border-active-dark: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-active-dark);
  --emu-component-containers-carousel-indicators-image-border-width-all: var(--emu-component-containers-carousel-indicators-thumbnail-border-width-narrow);
  --emu-component-containers-carousel-indicators-image-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-image-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-thumbnail-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-thumbnail-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-thumbnail-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-thumbnail-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-thumbnail-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-indicators-thumbnail-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-thumbnail-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-text-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-light: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-dark: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-carousel-indicators-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-carousel-controls-padding-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-controls-padding-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-controls-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-controls-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-color-border-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-border-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-controls-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-controls-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-controls-z-index: var(--emu-common-other-z-index-layer);
  --emu-component-containers-carousel-controls-icon-color-fill-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-containers-carousel-controls-icon-color-fill-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-containers-carousel-controls-icon-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-controls-icon-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-frame-container-padding-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-frame-container-padding-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-frame-container-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-frame-container-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-frame-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-frame-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-frame-color-background-default-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-frame-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-frame-color-background-active-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-frame-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-frame-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-frame-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-frame-color-border-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-frame-color-border-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-frame-border-width-default-narrow: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-frame-border-width-default-wide: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-frame-border-width-active-narrow: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-frame-border-width-active-wide: var(--emu-common-border-width-none);
  --emu-component-containers-embed-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-embed-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-embed-player-vimeo-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-vimeo-global-text-typography-line-height: 1.7;
  --emu-component-containers-embed-player-vimeo-color-background-light: #000;
  --emu-component-containers-embed-player-vimeo-color-background-dark: #000;
  --emu-component-containers-embed-player-vimeo-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-vimeo-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-vimeo-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-vimeo-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-vimeo-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-vimeo-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-vimeo-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-vimeo-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-vimeo-controls-global-padding: 7px;
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-tooltip-padding: 5px;
  --emu-component-containers-embed-player-vimeo-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-vimeo-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-vimeo-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-vimeo-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-youtube-global-text-typography-line-height: 1.7;
  --emu-component-containers-embed-player-youtube-color-background-light: #000;
  --emu-component-containers-embed-player-youtube-color-background-dark: #000;
  --emu-component-containers-embed-player-youtube-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-youtube-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-youtube-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-youtube-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-youtube-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-youtube-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-youtube-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-youtube-controls-global-padding: 7px;
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-tooltip-padding: 5px;
  --emu-component-containers-embed-player-youtube-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-youtube-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-youtube-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-youtube-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-gallery-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-separator-content-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-sizing-width-narrow: 100%;
  --emu-component-containers-gallery-item-sizing-width-wide: calc(33.33% - 8px);
  --emu-component-containers-gallery-item-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-item-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-stage-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-gap-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-preview-container-gap-wide: var(--emu-common-sizing-xs);
  --emu-component-layers-modal-z-index: var(--emu-common-other-z-index-modal);
  --emu-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-layers-modal-body-sizing-width: 100%;
  --emu-component-layers-modal-body-sizing-max-width: 850px;
  --emu-component-layers-modal-body-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-modal-body-color-border-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-border-width-all: var(--emu-semantic-border-width-none);
  --emu-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-text-typography: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-layers-modal-actions-close-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-layers-cookie-consent-banner-z-index: var(--emu-common-other-z-index-cookie-banner);
  --emu-component-layers-cookie-consent-banner-max-width: var(--emu-component-containers-container-max-width);
  --emu-component-layers-cookie-consent-banner-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-shadow: 4px 4px 20px 4px #0003;
  --emu-component-layers-cookie-consent-banner-padding-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-top-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-right-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-right-wide: 32px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-left-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-left-wide: 32px;
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-wide: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-wide: 0px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-wide: 56px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-outline-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light: var(--emu-common-colors-grey-300);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark: var(--emu-common-colors-grey-300);
}

html {
  -webkit-text-size-adjust: 100%;
}

html, body, h1, h2, h3, h4, h5, h6, p, li, a, span, button {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  color: inherit;
}

body {
  color: var(--emu-semantic-colors-primary-50);
}

html .xf-content-height, body .xf-content-height {
  margin: inherit;
}

html .experiencefragment, body .experiencefragment {
  padding: var(--emu-common-spacing-none);
}

body {
  -webkit-overflow-scrolling: touch;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*, :before, :after {
  box-sizing: border-box;
}

.cmp-image__image, image {
  width: auto;
  max-width: 100%;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

body {
  max-width: var(--emu-common-sizing-container-large);
  margin: auto;
}

sup {
  vertical-align: baseline;
  font-size: 65%;
  line-height: 0;
  position: relative;
  top: -.45em;
}

.desktop-view {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-view {
    display: block;
  }
}

.cq-Editable-dom--container .desktop-view {
  display: block;
}

.tablet-desktop-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-desktop-view {
    display: block;
  }
}

.cq-Editable-dom--container .tablet-desktop-view, .tablet-mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .tablet-mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .tablet-mobile-view, .mobile-view {
  display: block;
}

@media (min-width: 768px) {
  .mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .mobile-view {
  display: block;
}

.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.u-container--default, .u-container--default.aaaem-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 993px) {
  .u-container--default, .u-container--default.aaaem-container {
    max-width: var(--emu-common-sizing-xxxl);
  }
}

[data-component="title"].u-text--color-white, [data-component="text"].u-text--color-white {
  color: var(--emu-common-colors-white);
}

[data-component="title"].u-text--color-black, [data-component="text"].u-text--color-black {
  color: var(--emu-common-colors-black);
}

[data-component="title"].u-text--color-primary-50, [data-component="text"].u-text--color-primary-50 {
  color: var(--emu-semantic-colors-primary-50);
}

[data-component="title"].u-text--color-primary-100, [data-component="text"].u-text--color-primary-100 {
  color: var(--emu-semantic-colors-primary-100);
}

[data-component="title"].u-text--color-primary-200, [data-component="text"].u-text--color-primary-200 {
  color: var(--emu-semantic-colors-primary-200);
}

[data-component="title"].u-text--color-primary-300, [data-component="text"].u-text--color-primary-300 {
  color: var(--emu-semantic-colors-primary-300);
}

[data-component="title"].u-text--color-primary-400, [data-component="text"].u-text--color-primary-400 {
  color: var(--emu-semantic-colors-primary-400);
}

.u-bg--primary-600 {
  background-color: var(--emu-semantic-colors-primary-600);
}

.u-bg--primary-700 {
  background-color: var(--emu-semantic-colors-primary-700);
}

.u-bg--primary-800 {
  background-color: var(--emu-semantic-colors-primary-800);
}

.u-bg--primary-100 {
  background-color: var(--emu-semantic-colors-primary-100);
}

.u-bg--color-black {
  background-color: var(--emu-common-colors-black);
}

.u-bg--primary-900 {
  background-color: var(--emu-semantic-colors-primary-900);
}

.u-bg--primary-950 {
  background-color: var(--emu-semantic-colors-primary-950);
}

.u-bg--secondary-50 {
  background-color: var(--emu-semantic-colors-secondary-50);
}

.u-list-style-none ul {
  padding: var(--emu-common-spacing-none);
  margin: var(--emu-common-spacing-none);
  list-style: none;
}

.u-hide-overflow {
  height: 100vh;
  overflow: hidden;
}

.u-hide {
  display: none !important;
}

.cq-Editable-dom--container .u-hide {
  display: block !important;
}

[data-component="title"].u-title--large .cmp-title__text {
  font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
  line-height: var(--emu-semantic-line-heights-narrow-xxxl);
}

.u-page-anchor {
  scroll-margin-top: 75px;
}

@media (min-width: 993px) {
  .u-page-anchor {
    scroll-margin-top: 130px;
  }
}

#about-latisse .about__banner {
  background-color: var(--emu-semantic-colors-primary-600);
  min-height: 750px;
  margin-top: -15px;
  position: relative;
}

@media (min-width: 768px) {
  #about-latisse .about__banner {
    min-height: var(--emu-common-sizing-xxs);
    margin-top: 35px;
    padding: 75px 35px;
  }
}

@media (min-width: 993px) {
  #about-latisse .about__banner {
    margin-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1024px) {
  #about-latisse .about__banner {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    background-size: cover;
    min-height: 750px;
    margin-top: -115px;
  }
}

#about-latisse .about__banner-bg > .image {
  background-color: var(--emu-semantic-colors-primary-600);
  z-index: var(--emu-common-other-z-index-base);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 768px) {
  #about-latisse .about__banner-bg > .image {
    background-color: var(--emu-common-colors-white);
  }
}

#about-latisse .about__banner-bg img {
  object-fit: contain;
  object-position: bottom;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 768px) {
  #about-latisse .about__banner-bg img {
    object-fit: contain;
    object-position: top;
  }
}

@media (min-width: 830px) {
  #about-latisse .about__banner-bg img {
    object-fit: cover;
  }
}

@media (min-width: 1024px) {
  #about-latisse .about__banner-bg img {
    object-position: left;
  }
}

@media (min-width: 1200px) {
  #about-latisse .about__banner-bg img {
    object-position: left top;
  }
}

#about-latisse .about__banner-content-wrapper.aaaem-container {
  z-index: var(--emu-common-other-z-index-layer);
  position: relative;
}

@media (min-width: 768px) {
  #about-latisse .about__banner-content-wrapper.aaaem-container {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#about-latisse .about__banner-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 700px;
  display: flex;
}

@media (min-width: 768px) {
  #about-latisse .about__banner-content {
    min-height: var(--emu-common-sizing-xxs);
    max-width: 50%;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1024px) {
  #about-latisse .about__banner-content {
    min-height: 700px;
  }
}

#about-latisse .about__banner-title {
  margin-bottom: 30px;
}

#about-latisse .about__banner-title h1 {
  font-size: var(--emu-semantic-font-sizes-wide-xxl);
  text-align: center;
  color: var(--emu-semantic-colors-primary-100);
  line-height: 1;
}

@media (min-width: 768px) {
  #about-latisse .about__banner-title h1 {
    text-align: left;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-size: 56px;
    line-height: 62px;
  }
}

@media (min-width: 1024px) {
  #about-latisse .about__banner-title h1 {
    text-indent: -40px;
    margin-top: 30px;
    font-size: 80px;
    line-height: 70px;
  }
}

#about-latisse .about__banner-title h1 span {
  color: var(--emu-semantic-colors-primary-300);
}

@media (min-width: 1024px) {
  #about-latisse .about__banner-title h1 span {
    text-indent: -55px;
    display: block;
    position: relative;
    top: -10px;
  }
}

#about-latisse .about__banner-text {
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #about-latisse .about__banner-text {
    max-width: 100%;
    margin-bottom: 30px;
  }
}

@media (min-width: 1024px) {
  #about-latisse .about__banner-text {
    max-width: 75%;
  }
}

#about-latisse .about__how-to-use {
  padding-top: 75px;
  padding-bottom: 75px;
}

#about-latisse .about__how-to-use-title {
  margin-bottom: 35px;
}

#about-latisse .about__how-to-use-title .cmp-title__text {
  line-height: 56px;
}

@media (min-width: 768px) {
  #about-latisse .about__how-to-use-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
  }
}

#about-latisse .about__how-to-use-text {
  max-width: 90%;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #about-latisse .about__how-to-use-text {
    max-width: 710px;
    margin-bottom: 35px;
    font-size: 20px;
    line-height: 30px;
  }

  #about-latisse .about__steps {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
  }

  #about-latisse .about__steps > .container {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

#about-latisse .about__step {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 768px) {
  #about-latisse .about__step {
    padding-left: 35px;
    padding-right: 35px;
  }
}

#about-latisse .about__step-img img {
  max-width: 150px;
  max-height: 150px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}

#about-latisse .about__step-title {
  margin-top: 20px;
  margin-bottom: 20px;
}

#about-latisse .about__step-title .cmp-title__text {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: 56px;
}

#about-latisse .about__step-desc {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #about-latisse .about__step-desc {
    width: 100%;
    font-size: 20px;
    line-height: 30px;
  }
}

#about-latisse .about__step-desc p {
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  #about-latisse .about__step-desc p {
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

#about-latisse .about__step-desc span {
  color: var(--emu-semantic-colors-primary-100);
  font-weight: var(--emu-common-font-weight-bold);
  text-indent: -1em;
  display: inline-block;
}

#about-latisse .about__imp-reminders {
  padding: 65px 15px 45px;
}

@media (min-width: 768px) {
  #about-latisse .about__imp-reminders {
    padding-bottom: 65px;
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 1024px) {
  #about-latisse .about__imp-reminders {
    padding: 75px;
  }
}

@media (min-width: 768px) {
  #about-latisse .about__imp-reminders-wrapper {
    display: flex;
  }

  #about-latisse .about__imp-reminders-wrapper.aaaem-container {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }

  #about-latisse .about__imp-reminders-wrapper > .container {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

#about-latisse .about__imp-reminders-title {
  margin-bottom: 15px;
}

#about-latisse .about__imp-reminders-title .cmp-title__text {
  line-height: 65px;
}

@media (min-width: 768px) {
  #about-latisse .about__imp-reminders-title .cmp-title__text {
    text-align: left;
  }
}

@media (min-width: 1024px) {
  #about-latisse .about__imp-reminders-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
  }
}

#about-latisse .about__imp-reminders-desc {
  font-weight: var(--emu-semantic-font-weight-500);
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-secondary-200);
  max-width: 95%;
  margin-bottom: 35px;
  padding-bottom: 35px;
}

@media (min-width: 768px) {
  #about-latisse .about__imp-reminders-desc {
    max-width: 75%;
  }
}

@media (min-width: 1024px) {
  #about-latisse .about__imp-reminders-desc {
    font-size: 18px;
    line-height: 27px;
  }
}

#about-latisse .about__imp-reminders-list ul {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-medium);
  padding-left: var(--emu-common-spacing-medium);
  max-width: 95%;
}

#about-latisse .about__imp-reminders-list ul li {
  margin-bottom: 15px;
  line-height: 1.2;
}

@media (min-width: 768px) {
  #about-latisse .about__imp-reminders-list ul li {
    margin-bottom: 10px;
    font-size: 20px;
  }
}

#about-latisse .about__imp-reminders-img {
  margin-bottom: 15px;
  padding-top: 25px;
}

@media (min-width: 768px) {
  #about-latisse .about__imp-reminders-img {
    padding-top: var(--emu-common-spacing-none);
  }
}

#about-latisse .about__imp-reminders-img img {
  width: 100%;
}

#about-latisse .about__imp-reminders-caption {
  font-weight: var(--emu-common-font-weight-regular);
  font-size: var(--emu-common-font-sizes-wide-medium);
  line-height: 1.5;
}

@media (min-width: 768px) {
  #about-latisse .about__imp-reminders-caption {
    font-size: 18px;
  }
}

#about-latisse .about__faq {
  padding-top: 48px;
  padding-bottom: 48px;
  background-size: 0 !important;
}

@media (min-width: 768px) {
  #about-latisse .about__faq {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover !important;
  }
}

@media (min-width: 1024px) {
  #about-latisse .about__faq {
    background-position: unset;
    background-size: 100% !important;
  }
}

@media (min-width: 768px) {
  #about-latisse .about__faq-wrapper.aaaem-container {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    display: flex;
  }

  #about-latisse .about__faq-wrapper.aaaem-container > .title, #about-latisse .about__faq-wrapper.aaaem-container > .container {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

#about-latisse .about__faq-title {
  padding: 24px;
}

@media (min-width: 768px) {
  #about-latisse .about__faq-title {
    padding-left: 39px;
    padding-right: 95px;
  }

  #about-latisse .about__faq-title .cmp-title__text {
    text-align: right;
  }

  #about-latisse .about__faq-accordion-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1024px) {
  #about-latisse .about__faq-accordion-text {
    font-size: 18px;
    line-height: 27px;
  }
}

#about-latisse .about__faq-cta.emu-button.emu-button-v2.emu-button__primary-outline {
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  border-width: var(--emu-common-border-width-none);
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-50);
  margin-top: 24px;
  margin-bottom: 24px;
}

#about-latisse .about__faq-cta.emu-button.emu-button-v2.emu-button__primary-outline span {
  line-height: var(--emu-common-line-heights-wide-large);
  font-weight: var(--emu-common-font-weight-regular);
  color: var(--emu-semantic-colors-primary-50);
}

.cq-Editable-dom--container .about__banner-bg {
  height: 600px !important;
  position: static !important;
}

.cq-Editable-dom--container .about__banner-bg img {
  object-fit: contain !important;
}

#error .error-page {
  padding-top: 185px;
  padding-bottom: 150px;
}

@media (min-width: 768px) {
  #error .error-page {
    padding-top: 135px;
  }
}

@media (min-width: 993px) {
  #error .error-page {
    padding-top: 100px;
  }
}

#error .error-page__title .cmp-title__text {
  margin-bottom: var(--emu-common-spacing-small);
  font-size: 90px;
  line-height: 1.2;
}

@media (min-width: 768px) {
  #error .error-page__title .cmp-title__text {
    font-size: 72px;
  }
}

#error .error-page__text {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  margin-bottom: var(--emu-common-spacing-medium);
  line-height: 1.5;
}

@media (min-width: 768px) {
  #error .error-page__text {
    font-size: 44px;
  }
}

#error .error-page .button {
  text-align: center;
}

#error .error-page .button a.emu-button-v2.emu-button.emu-button__primary-filled {
  line-height: var(--emu-common-line-heights-wide-large);
  font-weight: var(--emu-common-font-weight-regular);
  border-width: var(--emu-common-border-width-none);
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 10px 65px;
}

#home .home__banner .aaaem-embed__embeddable {
  margin-top: 104px;
}

@media (min-width: 768px) {
  #home .home__banner .aaaem-embed__embeddable {
    margin-top: 116px;
  }
}

@media (min-width: 993px) {
  #home .home__banner .aaaem-embed__embeddable {
    margin-top: var(--emu-common-spacing-xs);
  }
}

@media (min-width: 1200px) {
  #home .home__banner .aaaem-embed__embeddable {
    margin-top: 7px;
  }
}

#home .home__banner .aaaem-embed__embeddable:not(.no-ratio) {
  padding-bottom: 125.64%;
}

@media (min-width: 768px) {
  #home .home__banner .aaaem-embed__embeddable:not(.no-ratio) {
    padding-bottom: 56.25%;
  }
}

#home .home__banner-video-content {
  position: relative;
}

#home .home__banner-video-content > .container {
  background: #0003;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
  #home .home__banner-video-content > .container {
    background: var(--emu-common-colors-transparent);
  }
}

#home .home__banner-video-overlay-text {
  font-size: var(--emu-semantic-font-sizes-wide-small);
  line-height: var(--emu-semantic-line-heights-wide-small);
  font-weight: var(--emu-common-font-weight-regular);
}

@media (min-width: 768px) {
  #home .home__banner-video-overlay-text br {
    display: none;
  }
}

#home .home__banner-video-overlay-title {
  margin-bottom: var(--emu-common-spacing-small);
}

#home .home__banner-video-overlay-title .cmp-title__text {
  font-size: 36px;
  line-height: 46px;
  font-weight: var(--emu-common-font-weight-regular);
}

@media (min-width: 768px) {
  #home .home__banner-video-overlay-title .cmp-title__text {
    font-size: 55px;
    line-height: 66px;
  }

  #home .home__banner-video-overlay-title .cmp-title__text br {
    display: none;
  }
}

#home .home__banner-video-overlay-title--small .cmp-title__text {
  font-size: var(--emu-semantic-font-sizes-wide-xl);
  line-height: 1.2;
}

#home .home__banner-footnote {
  margin-top: -7px;
  position: relative;
}

@media (min-width: 768px) {
  #home .home__banner-footnote {
    margin-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1200px) {
  #home .home__banner-footnote {
    margin-top: var(--emu-common-sizing-xxs);
  }
}

#home .home__banner-footnote-text {
  padding-top: var(--emu-common-spacing-small);
  padding-bottom: var(--emu-common-spacing-small);
  font-weight: var(--emu-common-font-weight-regular);
  font-size: 13px;
  line-height: 1.5;
}

@media (min-width: 768px) {
  #home .home__banner-footnote-text {
    font-size: var(--emu-common-font-sizes-narrow-medium);
  }
}

@media (min-width: 993px) {
  #home .home__banner-footnote-text {
    font-size: 15px;
  }
}

#home .home__banner-footnote-text b {
  font-weight: var(--emu-common-font-weight-regular);
}

#home .home__text-with-desc-wrapper {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  #home .home__text-with-desc-wrapper {
    padding-top: 100px;
    padding-right: var(--emu-common-spacing-none);
    padding-bottom: 100px;
    padding-left: var(--emu-common-spacing-none);
    align-items: center;
    display: flex;
  }
}

#home .home__text-with-desc-wrapper > .text {
  flex: 0 0 50%;
}

@media (min-width: 768px) {
  #home .home__text-with-desc-wrapper > .text:first-child {
    align-self: start;
  }
}

#home .home__text-with-desc-title {
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  font-size: 52px;
  line-height: 1.2;
}

@media (min-width: 768px) {
  #home .home__text-with-desc-title {
    text-align: left;
    margin-bottom: var(--emu-common-spacing-none);
    padding-left: 15px;
    padding-right: 15px;
    font-size: 50px;
  }
}

@media (min-width: 993px) {
  #home .home__text-with-desc-title {
    font-size: 80px;
  }
}

@media (min-width: 768px) {
  #home .home__text-with-desc-title .emphasis {
    text-indent: -50px;
    display: block;
  }
}

@media (min-width: 993px) {
  #home .home__text-with-desc-title .emphasis {
    text-indent: -80px;
  }
}

#home .home__text-with-desc-text {
  font-size: var(--emu-common-font-sizes-wide-medium);
  text-align: center;
  line-height: 1.5;
}

@media (min-width: 768px) {
  #home .home__text-with-desc-text {
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 23px;
  }
}

#home .home__cards {
  padding-top: 80px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  #home .home__cards {
    padding-bottom: 80px;
  }
}

#home .home__cards-heading {
  margin-bottom: 40px;
}

#home .home__cards-heading .cmp-title__text {
  font-size: 35px;
  font-weight: var(--emu-semantic-font-weight-500);
  line-height: 1.2;
}

#home .home__cards-heading .cmp-title__text br {
  display: block;
}

@media (min-width: 768px) {
  #home .home__cards-heading .cmp-title__text br {
    display: none;
  }
}

@media (min-width: 576px) {
  #home .home__cards-grid {
    display: flex;
  }
}

@media (min-width: 768px) {
  #home .home__cards-grid {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 993px) {
  #home .home__cards-grid {
    width: 100%;
  }
}

#home .home__cards-content {
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  #home .home__cards-content {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#home .home__cards-content-img img {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  width: 100%;
}

#home .home__cards-content-footer {
  padding: 35px 30px;
}

@media (min-width: 768px) {
  #home .home__cards-content-footer {
    padding: 20px;
  }
}

#home .home__cards-content-title {
  margin-bottom: 12px;
}

#home .home__cards-content-text {
  line-height: 22px;
}

@media (min-width: 768px) {
  #home .home__cards-content-text {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    line-height: 25px;
  }
}

#home .home__cards-content-text sup {
  font-size: 13px;
}

#home .home__cards-footnote {
  font-size: 11px;
  font-family: var(--emu-semantic-font-families-mono);
  font-weight: var(--emu-common-font-weight-regular);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--emu-common-spacing-medium);
  max-width: 80%;
  line-height: 1.5;
}

@media (min-width: 576px) {
  #home .home__cards-footnote {
    margin-top: 20px;
  }
}

@media (min-width: 768px) {
  #home .home__cards-footnote {
    margin-top: var(--emu-common-spacing-none);
    font-size: var(--emu-common-font-sizes-wide-medium);
    max-width: 94%;
  }
}

@media (min-width: 1200px) {
  #home .home__cards-footnote {
    max-width: 100%;
    padding-left: 15px;
  }
}

#home .home__results {
  padding-top: 70px;
  padding-bottom: 120px;
}

@media (min-width: 768px) {
  #home .home__results {
    flex-direction: row-reverse;
    display: flex;
  }
}

#home .home__results.aaaem-container {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #home .home__results > .container:first-child {
    flex: 0 0 41.67%;
    max-width: 41.67%;
    margin-top: auto;
    margin-bottom: auto;
  }

  #home .home__results > .container:last-child {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
}

#home .home__results-content > .button {
  text-align: center;
}

@media (min-width: 768px) {
  #home .home__results-content > .button {
    text-align: left;
  }
}

#home .home__results-title .cmp-title__text {
  font-size: 50px;
  line-height: 1.2;
}

@media (min-width: 768px) {
  #home .home__results-title .cmp-title__text {
    text-align: left;
    font-size: 45px;
  }
}

@media (min-width: 993px) {
  #home .home__results-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
  }
}

#home .home__results-cta.emu-button.emu-button-v2.emu-button__primary-filled {
  justify-content: center;
  width: 50%;
  margin-top: 20px;
  margin-bottom: 65px;
  padding: 10px 25px;
}

@media (min-width: 768px) {
  #home .home__results-cta.emu-button.emu-button-v2.emu-button__primary-filled {
    margin-bottom: var(--emu-common-spacing-none);
    width: auto;
  }
}

#home .home__results-cta span {
  font-weight: var(--emu-common-font-weight-regular);
  font-size: var(--emu-semantic-font-sizes-wide-xs);
  line-height: 1.5;
}

#home .home__results-carousel-wrapper {
  position: relative;
}

@media (min-width: 768px) {
  #home .home__results-carousel-wrapper {
    display: flex;
  }
}

#home .home__results-carousel-wrapper:before {
  content: "";
  background: var(--emu-semantic-colors-secondary-400);
  width: 60%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 768px) {
  #home .home__results-carousel-wrapper > .text:first-of-type {
    justify-content: flex-end;
    align-items: flex-end;
    width: 20%;
    display: flex;
  }

  #home .home__results-carousel-wrapper > .carousel {
    max-width: 70%;
    margin-left: 15px;
  }
}

#home .home__results-carousel {
  max-width: 95%;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
}

@media (min-width: 768px) {
  #home .home__results-carousel {
    max-width: none;
    padding-top: 50px;
    padding-bottom: 45px;
  }
}

#home .home__results-carousel .emu-carousel__item {
  margin-right: var(--emu-common-sizing-xxs);
}

@media (min-width: 768px) {
  #home .home__results-carousel .emu-carousel__item {
    margin-right: var(--emu-common-spacing-none);
  }
}

#home .home__results-carousel-img {
  display: inline-block;
}

#home .home__results-carousel-sidebar {
  position: relative;
}

@media (min-width: 768px) {
  #home .home__results-carousel-sidebar ul {
    margin-bottom: 25px;
  }

  #home .home__results-carousel-sidebar ul li {
    font-weight: var(--emu-semantic-font-weight-500);
    cursor: pointer;
    padding-bottom: 12px;
  }
}

@media (min-width: 768px) and (min-width: 1024px) {
  #home .home__results-carousel-sidebar ul li {
    font-size: 18px;
    line-height: 1.5;
  }
}

@media (min-width: 768px) {
  #home .home__results-carousel-sidebar ul li:hover {
    text-decoration: underline;
  }

  #home .home__results-carousel-sidebar ul li.item-active {
    color: var(--emu-semantic-colors-primary-100);
    font-weight: var(--emu-common-font-weight-bold);
  }

  #home .home__results-carousel-sidebar ul li a, #home .home__results-carousel-sidebar ul li a:hover, #home .home__results-carousel-sidebar ul li a:focus, #home .home__results-carousel-sidebar ul li a:active {
    color: inherit;
    text-decoration: none;
  }
}

#home .home__results-footnote {
  font-family: var(--emu-semantic-font-families-mono);
  font-weight: var(--emu-common-font-weight-regular);
  font-size: var(--emu-common-font-sizes-narrow-medium);
  margin-top: var(--emu-common-spacing-medium);
  margin-bottom: var(--emu-common-spacing-medium);
  padding-left: 15px;
  padding-right: 15px;
  line-height: 1.5;
  position: absolute;
  bottom: -85px;
  left: 0;
}

@media (min-width: 768px) {
  #home .home__results-footnote {
    font-size: 11px;
    bottom: -65px;
  }
}

@media (min-width: 993px) {
  #home .home__results-footnote {
    margin-top: var(--emu-common-spacing-none);
    font-size: 12px;
  }
}

@media (min-width: 1240px) {
  #home .home__results-footnote {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#home .home__results-footnote br {
  display: none;
}

@media (min-width: 768px) {
  #home .home__results-footnote br {
    display: block;
  }
}

#results .results__banner-wrapper {
  margin-top: 115px;
  position: relative;
}

@media (min-width: 993px) {
  #results .results__banner-wrapper {
    margin-top: 80px;
  }
}

@media (min-width: 1024px) {
  #results .results__banner-wrapper {
    margin-top: var(--emu-common-spacing-none);
  }
}

#results .results__banner-title .emu-title__text {
  margin-bottom: var(--emu-common-spacing-small);
  padding-top: 50px;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  #results .results__banner-title .emu-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    line-height: var(--emu-semantic-line-heights-wide-xxl);
    padding-left: 15px;
    padding-right: 15px;
  }

  #results .results__banner-title .emu-title__text br:first-child {
    display: none;
  }
}

#results .results__banner-left-fade-img, #results .results__banner-right-fade-img {
  height: 100%;
  position: absolute;
  top: 0;
}

#results .results__banner-left-fade-img:before, #results .results__banner-right-fade-img:before {
  content: "";
  background-color: var(--emu-semantic-colors-secondary-500);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#results .results__banner-left-fade-img img, #results .results__banner-right-fade-img img {
  height: 100%;
}

#results .results__banner-left-fade-img {
  left: 0;
}

#results .results__banner-right-fade-img {
  right: 0;
}

#results .results__banner-carousel {
  margin-bottom: -5.6px;
  padding-left: 13px;
  padding-right: 13px;
}

@media (min-width: 768px) and (hover: hover) {
  #results .results__banner-carousel:has(.results__banner-carousel-item:hover) .results__banner-carousel-item:not(:hover):before {
    background-color: var(--emu-semantic-colors-secondary-500);
  }
}

@media (min-width: 768px) {
  #results .results__banner-carousel {
    margin-bottom: var(--emu-common-spacing-none);
    padding-left: 28px;
    padding-right: 28px;
  }
}

#results .results__banner-carousel-wrapper {
  padding-bottom: 136px;
}

@media (min-width: 768px) {
  #results .results__banner-carousel-wrapper {
    padding-bottom: var(--emu-common-spacing-none);
  }
}

#results .results__banner-carousel-content {
  cursor: pointer;
  position: relative;
}

#results .results__banner-carousel-item {
  position: relative;
}

#results .results__banner-carousel-item:before {
  content: "";
  background-color: var(--emu-common-colors-transparent);
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#results .results__banner-carousel-title .emu-title__text {
  font-size: var(--emu-common-font-sizes-wide-medium);
  border-bottom: var(--emu-common-border-width-medium) solid var(--emu-common-colors-white);
  width: 80%;
  line-height: 25px;
  position: absolute;
  top: 20px;
  left: 15px;
}

@media (min-width: 768px) {
  #results .results__banner-carousel-title .emu-title__text {
    width: 90%;
    left: 20px;
  }
}

@media (min-width: 1440px) {
  #results .results__banner-carousel-img img {
    width: 100%;
  }
}

#results .results__banner-carousel-expand {
  font-family: var(--emu-semantic-font-families-mono);
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: var(--emu-common-line-heights-narrow-medium);
  font-weight: var(--emu-common-font-weight-regular);
  pointer-events: none;
  position: absolute;
  bottom: 15px;
  left: 20px;
}

#results .results__banner-carousel .emu-carousel__action {
  top: auto;
  bottom: -53px;
}

#results .results__banner-carousel .emu-carousel__action-prev {
  left: var(--emu-common-spacing-xxs);
}

@media (min-width: 768px) {
  #results .results__banner-carousel .emu-carousel__action-prev {
    display: none;
  }
}

#results .results__banner-carousel .emu-carousel__action-next {
  right: var(--emu-common-spacing-xxs);
}

@media (min-width: 768px) {
  #results .results__banner-carousel .emu-carousel__action-next {
    height: 17px;
    top: 28px;
    right: 20px;
  }

  #results .results__banner-carousel .emu-carousel__action-next .emu-carousel__action-icon {
    background-image: url("resources/images/next-white-arrow.png");
    width: 42px;
    height: 17px;
  }
}

#results .results__banner-view-gallery {
  font-weight: var(--emu-semantic-font-weight-500);
  font-size: var(--emu-semantic-font-sizes-wide-xs);
  text-decoration: underline;
  position: absolute;
  bottom: -37px;
  left: 50%;
  transform: translateX(-50%);
}

#results .results__banner-footnote {
  font-size: 11px;
  line-height: 1.5;
  font-family: var(--emu-semantic-font-families-mono);
  font-weight: var(--emu-common-font-weight-regular);
  margin-bottom: var(--emu-common-spacing-medium);
  width: 100%;
  position: absolute;
  bottom: 9px;
}

@media (min-width: 768px) {
  #results .results__banner-footnote {
    font-size: var(--emu-semantic-font-sizes-narrow-xs);
    bottom: -50px;
  }
}

#results .results__banner-main-content {
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  #results .results__banner-main-content {
    padding-bottom: var(--emu-common-spacing-none);
  }
}

#results .results__banner-main-content.aaaem-container {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
}

#results .results__banner-close-btn.emu-button.emu-button-v2.emu-button__primary-filled {
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  background-color: var(--emu-common-colors-transparent);
  color: #333;
  font-family: var(--emu-semantic-font-families-mono);
  font-size: var(--emu-semantic-font-sizes-wide-xs);
  line-height: 21px;
  font-weight: var(--emu-common-font-weight-regular);
  border: none;
  width: auto;
  margin-bottom: 15px;
  margin-left: auto;
  padding-left: 5px;
  padding-right: 5px;
  display: block;
}

@media (min-width: 768px) {
  #results .results__banner-close-btn.emu-button.emu-button-v2.emu-button__primary-filled {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 1024px) {
  #results .results__banner-close-btn.emu-button.emu-button-v2.emu-button__primary-filled {
    padding-left: 5px;
    padding-right: 5px;
  }
}

#results .results__banner-accordion {
  margin-left: 20px;
  margin-right: 20px;
}

#results .results__banner-accordion:has(dd:last-of-type.-ba-is-active) {
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-100);
}

#results .results__banner-accordion .aaaem-accordion__item {
  padding: var(--emu-common-spacing-none);
}

#results .results__banner-accordion .aaaem-accordion__header {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: var(--emu-common-spacing-none);
  border-bottom-color: var(--emu-semantic-colors-primary-100);
}

#results .results__banner-accordion .aaaem-accordion__header[aria-expanded="true"] .aaaem-accordion__icon:before {
  bottom: var(--emu-common-spacing-xs);
  transform: rotate(-45deg);
}

#results .results__banner-accordion .aaaem-accordion__header.-ba-is-active {
  border: none;
  border-bottom: var(--emu-common-border-width-thin) solid #00000020;
  margin-bottom: -1px;
}

#results .results__banner-accordion .aaaem-accordion__title {
  font-family: var(--emu-semantic-font-families-mono);
  font-size: var(--emu-common-font-sizes-wide-medium);
  line-height: 1.5;
  font-weight: var(--emu-common-font-weight-regular);
  color: var(--emu-semantic-colors-primary-100);
}

#results .results__banner-accordion .aaaem-accordion__button {
  position: relative;
}

#results .results__banner-accordion .aaaem-accordion__icon {
  margin-left: var(--emu-common-spacing-none);
  right: 7.5px;
  bottom: var(--emu-common-spacing-xxs);
  width: 21px;
  height: 21px;
  position: absolute;
}

#results .results__banner-accordion .aaaem-accordion__icon:before {
  content: "";
  border-top: var(--emu-common-border-width-medium) solid var(--emu-semantic-colors-primary-100);
  border-right: var(--emu-common-border-width-medium) solid var(--emu-semantic-colors-primary-100);
  margin-top: var(--emu-common-spacing-none);
  width: 15px;
  height: 15px;
  position: absolute;
  right: 0;
}

#results .results__banner-accordion .aaaem-accordion__panel {
  cursor: url("resources/images/cursor_plus_white.png") 7 4, crosshair;
  position: relative;
}

#results .results__banner-accordion .aaaem-accordion__panel.-ba-is-active + .aaaem-accordion__item p {
  border-top: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-100);
}

#results .results__banner-accordion .aaaem-accordion__panel .js-badger-accordion-panel-inner {
  padding: var(--emu-common-spacing-none);
}

#results .results__banner-tab-content {
  display: flex;
}

#results .results__banner-tab-content > .container {
  flex: 0 0 50%;
}

@media (min-width: 768px) {
  #results .results__banner-tab-content > .container {
    flex: unset;
  }
}

#results .results__banner .custom-cursor__text {
  color: var(--emu-common-colors-black);
  background: #ffffff8c;
}

#results .results__banner-before-content, #results .results__banner-after-content {
  position: relative;
}

@media (min-width: 768px) {
  #results .results__banner-before-content, #results .results__banner-after-content {
    cursor: url("resources/images/cursor_plus_white.png") 7 4, crosshair;
    overflow: hidden;
  }
}

#results .results__banner-before-text, #results .results__banner-after-text {
  font-size: 10px;
  line-height: 1.5;
  font-weight: var(--emu-common-font-weight-regular);
  position: absolute;
  top: 10px;
  left: 10px;
}

@media (min-width: 768px) {
  #results .results__banner-before-text, #results .results__banner-after-text {
    font-size: var(--emu-common-font-sizes-wide-medium);
    left: 20px;
  }
}

#results .results__banner-before-img, #results .results__banner-after-img {
  -webkit-user-select: none;
  user-select: none;
  background-position: center;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  #results .results__banner-before-img, #results .results__banner-after-img {
    width: 310px;
    height: 455px;
    position: relative;
    overflow: hidden;
  }
}

@media (min-width: 1024px) {
  #results .results__banner-before-img, #results .results__banner-after-img {
    width: 485px;
    height: 600px;
  }
}

#results .results__banner-before-img img, #results .results__banner-after-img img {
  object-fit: contain;
  object-position: center;
  pointer-events: none;
  width: 170px;
  height: 210px;
  margin-left: auto;
  margin-right: auto;
}

#results .results__banner-before-img img.animated, #results .results__banner-after-img img.animated {
  transition: all .25s ease-in-out;
}

@media (min-width: 768px) {
  #results .results__banner-before-img img, #results .results__banner-after-img img {
    width: 375px;
    max-width: none;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 1024px) {
  #results .results__banner-before-img img, #results .results__banner-after-img img {
    width: 490px;
  }
}

@media (min-width: 768px) {
  #results .results__banner-before-img img {
    border-top-left-radius: var(--emu-common-border-radius-xs);
  }

  #results .results__banner-after-img img {
    border-top-right-radius: var(--emu-common-border-radius-xs);
  }
}

#results .results__banner-disclaimer {
  font-family: var(--emu-semantic-font-families-mono);
  font-size: 8px;
  line-height: 12px;
  font-weight: var(--emu-common-font-weight-regular);
  cursor: url("resources/images/cursor_plus_white.png") 7 4, crosshair;
  margin-top: 5px;
  margin-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 768px) {
  #results .results__banner-disclaimer {
    font-size: 12px;
    line-height: var(--emu-common-line-heights-narrow-large);
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    width: 620px;
    margin-top: 29px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1024px) {
  #results .results__banner-disclaimer {
    margin-top: 5px;
    margin-bottom: var(--emu-common-spacing-none);
    width: 100%;
  }
}

@media (min-width: 768px) {
  #results .results__banner-desktop-tabs {
    display: flex;
    position: relative;
  }
}

@media (min-width: 1024px) {
  #results .results__banner-desktop-tabs {
    padding-bottom: 60px;
  }
}

@media (min-width: 768px) {
  #results .results__banner-desktop-tabs > .aaaem-tabs__tablist {
    flex: 0 0 16.67%;
    max-width: 16.67%;
  }
}

#results .results__banner-desktop-tabs > .aaaem-tabs__tablist > .aaaem-tabs__tab {
  font-size: var(--emu-common-font-sizes-wide-medium);
  margin-left: 13.5px;
  line-height: 24.5px;
}

@media (min-width: 1200px) {
  #results .results__banner-desktop-tabs > .aaaem-tabs__tablist > .aaaem-tabs__tab {
    margin-left: var(--emu-common-spacing-none);
  }
}

#results .results__banner-desktop-tabs > .aaaem-tabs__tablist > .aaaem-tabs__tab a[aria-selected="true"] {
  color: var(--emu-semantic-colors-primary-100);
  font-weight: var(--emu-semantic-font-weight-500);
}

@media (min-width: 768px) {
  #results .results__banner-desktop-tabs > .aaaem-tabs__tabpanels {
    flex: 0 0 83.33%;
    max-width: 83.33%;
  }
}

#results .results__banner-next-btn {
  display: none;
}

@media (min-width: 768px) {
  #results .results__banner-next-btn {
    display: inline-flex;
    position: absolute;
    bottom: 10px;
    right: 65px;
  }
}

@media (min-width: 1024px) {
  #results .results__banner-next-btn {
    bottom: 43px;
    right: 5px;
  }
}

#results .results__banner-next-btn.emu-button.emu-button-v2.emu-button__primary-filled {
  padding: var(--emu-common-spacing-none);
  background-color: var(--emu-common-colors-transparent);
  border: none;
}

#results .results__banner-next-btn span {
  background-image: url("resources/images/next-arrow.png");
  background-size: 20px 16.5px;
  width: 20px;
  height: 16.5px;
}

#results .results__cards {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  #results .results__cards {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (min-width: 1024px) {
  #results .results__cards {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

#results .results__cards-heading {
  margin-bottom: 40px;
}

#results .results__cards-heading .cmp-title__text {
  font-weight: var(--emu-common-font-weight-light);
  line-height: 40px;
}

@media (min-width: 768px) {
  #results .results__cards-heading .cmp-title__text {
    font-size: 35px;
    line-height: 42px;
    font-weight: var(--emu-common-font-weight-regular);
  }
}

#results .results__cards-heading .cmp-title__text br {
  display: block;
}

@media (min-width: 768px) {
  #results .results__cards-heading .cmp-title__text br {
    display: none;
  }
}

@media (min-width: 576px) {
  #results .results__cards-grid {
    display: flex;
  }
}

@media (min-width: 768px) {
  #results .results__cards-grid {
    width: 91%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1024px) {
  #results .results__cards-grid {
    width: 100%;
  }
}

#results .results__cards-content {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  #results .results__cards-content {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#results .results__cards-content-footer {
  padding: 65px 40px;
}

@media (min-width: 768px) {
  #results .results__cards-content-footer {
    padding: 50px 15px;
  }
}

@media (min-width: 1024px) {
  #results .results__cards-content-footer {
    flex-direction: column;
    justify-content: center;
    min-height: 320px;
    padding: 30px 65px;
    display: flex;
  }
}

#results .results__cards-content-title {
  margin-bottom: 12px;
}

#results .results__cards-content-title .cmp-title__text {
  font-size: 26px;
  line-height: 1.2;
}

@media (min-width: 768px) {
  #results .results__cards-content-title .cmp-title__text {
    font-size: 24px;
  }
}

@media (min-width: 1024px) {
  #results .results__cards-content-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-large);
    line-height: var(--emu-semantic-line-heights-wide-large);
  }
}

#results .results__cards-content-text {
  font-size: var(--emu-common-font-sizes-wide-medium);
  line-height: 22px;
}

@media (min-width: 768px) {
  #results .results__cards-content-text {
    font-size: 15px;
    line-height: 25px;
  }
}

@media (min-width: 1024px) {
  #results .results__cards-content-text {
    font-size: var(--emu-semantic-font-sizes-wide-small);
  }
}

#results .results__cards-content-text sup {
  font-size: 13px;
}

#results .results__weekly-treatment-sec {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  #results .results__weekly-treatment-sec {
    padding-bottom: 80px;
  }
}

#results .results__weekly-treatment-sec-list ul {
  margin-bottom: var(--emu-common-spacing-medium);
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

#results .results__weekly-treatment-sec-list ul li {
  padding-left: var(--emu-common-spacing-xs);
  padding-right: var(--emu-common-spacing-xs);
}

#results .results__weekly-treatment-sec-list ul li.item-active a {
  font-weight: var(--emu-common-font-weight-bold);
  cursor: pointer;
}

#results .results__weekly-treatment-sec-list ul li.item-active a:before {
  content: "";
  background-color: var(--emu-semantic-colors-primary-100);
  width: 100%;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 0;
}

#results .results__weekly-treatment-sec-list ul li a {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: 42px;
  font-weight: var(--emu-common-font-weight-light);
  color: var(--emu-semantic-colors-primary-100);
  padding-left: 9px;
  padding-right: 9px;
  position: relative;
}

#results .results__weekly-treatment-sec-list ul li a:after {
  content: "";
  width: var(--emu-common-sizing-xxs);
  background-color: var(--emu-semantic-colors-primary-100);
  height: 70%;
  position: absolute;
  top: 15%;
  right: -5px;
}

#results .results__weekly-treatment-sec-list ul li:nth-child(3) a:after {
  content: none;
}

#results .results__weekly-treatment-sec-carousel {
  cursor: pointer;
  padding-left: 20px;
}

@media (min-width: 768px) {
  #results .results__weekly-treatment-sec-carousel {
    padding-left: var(--emu-common-spacing-none);
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1025px) {
  #results .results__weekly-treatment-sec-carousel {
    width: 100%;
  }
}

#results .results__weekly-treatment-sec-carousel .emu-carousel__item.slide-active .aaaem-carousel__item-container .emu-title__text {
  font-weight: var(--emu-semantic-font-weight-500);
}

#results .results__weekly-treatment-sec-carousel .emu-carousel__item.slide-active .aaaem-carousel__item-container .results__weekly-treatment-sec-carousel-img:before {
  content: "";
  height: var(--emu-common-sizing-xs);
  background-color: var(--emu-semantic-colors-primary-100);
  width: 100%;
  position: absolute;
  top: -8px;
}

@media (min-width: 768px) {
  #results .results__weekly-treatment-sec-carousel .emu-carousel__item.slide-active .aaaem-carousel__item-container .results__weekly-treatment-sec-carousel-img:before {
    height: 5px;
    top: 0;
  }
}

#results .results__weekly-treatment-sec-carousel .emu-carousel__item.slide-active .aaaem-carousel__item-container .results__weekly-treatment-sec-carousel-img:after {
  content: "";
  height: var(--emu-common-sizing-xs);
  background-color: var(--emu-semantic-colors-primary-100);
  width: 100%;
  position: absolute;
}

@media (min-width: 768px) {
  #results .results__weekly-treatment-sec-carousel .emu-carousel__item.slide-active .aaaem-carousel__item-container .results__weekly-treatment-sec-carousel-img:after {
    height: 5px;
    bottom: 0;
  }
}

#results .results__weekly-treatment-sec-carousel .emu-carousel__item.slide-active .aaaem-carousel__item-container .results__weekly-treatment-sec-carousel-footer p {
  color: var(--emu-semantic-colors-primary-100);
  font-weight: var(--emu-common-font-weight-bold);
}

@media (min-width: 768px) {
  #results .results__weekly-treatment-sec-carousel .emu-carousel__item.slide-active .aaaem-carousel__item-container .results__weekly-treatment-sec-carousel-footer p {
    font-weight: var(--emu-common-font-weight-regular);
  }
}

#results .results__weekly-treatment-sec-carousel .emu-carousel__action {
  top: 75.5%;
}

@media (min-width: 419px) {
  #results .results__weekly-treatment-sec-carousel .emu-carousel__action {
    top: 80%;
  }
}

#results .results__weekly-treatment-sec-carousel .emu-carousel__action-prev {
  left: -5px;
}

#results .results__weekly-treatment-sec-carousel .emu-carousel__action-next {
  right: 15px;
}

#results .results__weekly-treatment-sec-carousel-header .emu-title__text {
  font-weight: var(--emu-common-font-weight-light);
  padding-bottom: 15px;
  font-size: 25px;
  line-height: 1.2;
}

@media (min-width: 768px) {
  #results .results__weekly-treatment-sec-carousel-header .emu-title__text {
    padding-bottom: var(--emu-common-spacing-none);
  }
}

#results .results__weekly-treatment-sec-carousel-img {
  position: relative;
}

@media (min-width: 768px) {
  #results .results__weekly-treatment-sec-carousel-img {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

#results .results__weekly-treatment-sec-carousel-img img {
  width: 100%;
}

#results .results__weekly-treatment-sec-carousel-footer {
  font-size: var(--emu-common-font-sizes-wide-medium);
  color: #36363691;
  line-height: 1.5;
  font-weight: var(--emu-common-font-weight-regular);
  padding-top: 45px;
}

@media (min-width: 768px) {
  #results .results__weekly-treatment-sec-carousel-footer {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    padding-top: 15px;
  }
}

@media (min-width: 1024px) {
  #results .results__weekly-treatment-sec-carousel-footer {
    font-size: var(--emu-common-font-sizes-wide-medium);
  }
}

#results .results__weekly-treatment-sec-carousel-footer b {
  display: block;
}

#results .results__weekly-treatment-sec-footnote {
  font-size: 10px;
  line-height: 1.5;
  font-family: var(--emu-semantic-font-families-mono);
  font-weight: var(--emu-common-font-weight-regular);
  margin-top: 20px;
  margin-bottom: 5px;
  padding-left: 20px;
  padding-right: 10px;
}

@media (min-width: 768px) {
  #results .results__weekly-treatment-sec-footnote {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-size: 11px;
  }
}

@media (min-width: 1025px) {
  #results .results__weekly-treatment-sec-footnote {
    font-size: var(--emu-common-font-sizes-narrow-medium);
    width: 100%;
  }
}

#results .results__patient-result {
  position: relative;
}

#results .results__patient-result-img img {
  width: 100%;
}

#results .results__patient-result-text {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 11px;
  line-height: 12px;
  position: absolute;
  bottom: 12px;
}

@media (min-width: 768px) {
  #results .results__patient-result-text {
    font-size: var(--emu-common-font-sizes-narrow-medium);
    line-height: var(--emu-common-line-heights-narrow-medium);
    padding-left: 40px;
    padding-right: 40px;
  }
}

#rewards-program .rewards__alle {
  padding-top: 94px;
}

@media (min-width: 768px) {
  #rewards-program .rewards__alle {
    margin-top: 74px;
    padding-bottom: 55px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 993px) {
  #rewards-program .rewards__alle {
    margin-top: 78px;
    padding-top: 55px;
  }
}

@media (min-width: 1024px) {
  #rewards-program .rewards__alle {
    margin-top: var(--emu-common-spacing-none);
    padding-left: 35px;
    padding-right: 35px;
  }
}

#rewards-program .rewards__alle-wrapper.aaaem-container {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #rewards-program .rewards__alle-wrapper.aaaem-container {
    flex-direction: row-reverse;
    display: flex;
  }

  #rewards-program .rewards__alle-wrapper > .container {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

#rewards-program .rewards__alle-content-wrapper {
  padding: 71px 30px 15px;
}

@media (min-width: 768px) {
  #rewards-program .rewards__alle-content-wrapper {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
  }
}

#rewards-program .rewards__alle-title {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  #rewards-program .rewards__alle-title {
    margin-bottom: 30px;
  }
}

#rewards-program .rewards__alle-title .cmp-title__text {
  letter-spacing: .42px;
  line-height: 50px;
}

@media (min-width: 768px) {
  #rewards-program .rewards__alle-title .cmp-title__text {
    font-size: 56px;
    line-height: 62px;
  }
}

@media (min-width: 1024px) {
  #rewards-program .rewards__alle-title .cmp-title__text {
    font-size: 70px;
    line-height: 70px;
  }
}

@media (min-width: 576px) {
  #rewards-program .rewards__alle-title .cmp-title__text br {
    display: none;
  }
}

#rewards-program .rewards__alle-sub-title {
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  #rewards-program .rewards__alle-sub-title {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#rewards-program .rewards__alle-sub-title .cmp-title__text {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: 22px;
}

@media (min-width: 768px) {
  #rewards-program .rewards__alle-sub-title .cmp-title__text {
    font-size: 25px;
    line-height: 34px;
  }
}

#rewards-program .rewards__alle-text {
  font-size: var(--emu-common-font-sizes-wide-medium);
  line-height: 22px;
}

@media (min-width: 768px) {
  #rewards-program .rewards__alle-text {
    font-size: 25px;
    line-height: 34px;
  }
}

#rewards-program .rewards__alle-cta-wrapper {
  margin-top: 35px;
  margin-bottom: 35px;
  display: flex;
}

#rewards-program .rewards__alle-cta-wrapper > .button {
  width: 50%;
}

@media (min-width: 768px) {
  #rewards-program .rewards__alle-cta-wrapper > .button {
    width: auto;
  }
}

@media (min-width: 1024px) {
  #rewards-program .rewards__alle-cta-wrapper > .button {
    width: 50%;
    max-width: 240px;
    margin-right: 20px;
  }
}

@media (min-width: 1440px) {
  #rewards-program .rewards__alle-cta-wrapper > .button {
    max-width: unset;
    width: auto;
    min-width: 240px;
  }
}

#rewards-program .rewards__alle-cta-wrapper > .button:first-child {
  margin-right: 18px;
}

@media (min-width: 1024px) {
  #rewards-program .rewards__alle-cta-wrapper > .button:first-child {
    margin-right: 24px;
  }
}

#rewards-program .rewards__alle-join-cta, #rewards-program .rewards__alle-log-in-cta {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
}

#rewards-program .rewards__alle-join-cta span, #rewards-program .rewards__alle-log-in-cta span {
  letter-spacing: .38px;
}

#rewards-program .rewards__alle-join-cta.emu-button.emu-button-v2.emu-button__secondary-filled {
  border-width: var(--emu-common-border-width-none);
}

@media (min-width: 768px) {
  #rewards-program .rewards__alle-join-cta.emu-button.emu-button-v2.emu-button__secondary-filled {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 1024px) {
  #rewards-program .rewards__alle-join-cta.emu-button.emu-button-v2.emu-button__secondary-filled {
    padding-left: 55px;
    padding-right: 55px;
  }
}

@media (min-width: 768px) {
  #rewards-program .rewards__alle-log-in-cta.emu-button.emu-button-v2.emu-button__primary-outline {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 1024px) {
  #rewards-program .rewards__alle-log-in-cta.emu-button.emu-button-v2.emu-button__primary-outline {
    padding-left: 55px;
    padding-right: 55px;
  }
}

#rewards-program .rewards__alle-img-wrapper {
  padding-bottom: 15px;
  padding-right: 30px;
}

@media (min-width: 768px) {
  #rewards-program .rewards__alle-img-wrapper {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: var(--emu-common-spacing-none);
  }
}

#rewards-program .rewards__alle-img img {
  width: 100%;
}

@media (min-width: 768px) {
  #rewards-program .rewards__alle-img img {
    max-width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
}

#rewards-program .rewards__alle-disclaimer {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: var(--emu-common-line-heights-narrow-large);
  font-weight: var(--emu-common-font-weight-regular);
  max-width: 90%;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #rewards-program .rewards__alle-disclaimer {
    max-width: 75%;
    margin-bottom: 15px;
    font-size: 13px;
    line-height: 17px;
  }
}

#rewards-program .rewards__unlock-alle {
  padding: 30px 10px;
}

@media (min-width: 768px) {
  #rewards-program .rewards__unlock-alle {
    padding: 65px;
  }
}

@media (min-width: 1024px) {
  #rewards-program .rewards__unlock-alle {
    padding-top: 125px;
    padding-bottom: 125px;
  }
}

#rewards-program .rewards__unlock-alle-content-container {
  flex-direction: column;
  justify-content: center;
  height: 475px;
  display: flex;
}

@media (min-width: 768px) {
  #rewards-program .rewards__unlock-alle-content-container {
    height: 300px;
  }
}

#rewards-program .rewards__unlock-alle-content-container > .button {
  text-align: center;
}

#rewards-program .rewards__unlock-alle-title {
  width: 90%;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #rewards-program .rewards__unlock-alle-title {
    margin-bottom: 25px;
  }
}

#rewards-program .rewards__unlock-alle-title .cmp-title__text {
  line-height: 45px;
}

@media (min-width: 768px) {
  #rewards-program .rewards__unlock-alle-title .cmp-title__text {
    font-size: 38px;
    line-height: var(--emu-common-line-heights-wide-xl);
  }
}

@media (min-width: 1024px) {
  #rewards-program .rewards__unlock-alle-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    line-height: 70px;
  }
}

@media (min-width: 576px) {
  #rewards-program .rewards__unlock-alle-title br {
    display: none;
  }
}

#rewards-program .rewards__unlock-alle-cta {
  border-width: var(--emu-common-border-width-none);
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-bottom: 10px;
  padding-left: 55px;
  padding-right: 55px;
}

@media (min-width: 768px) {
  #rewards-program .rewards__unlock-alle-cta {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#rewards-program .rewards__unlock-alle-cta span {
  letter-spacing: .38px;
}

#rewards-program .rewards__benefits {
  flex-direction: column-reverse;
  display: flex;
  position: relative;
}

#rewards-program .rewards__benefits img {
  width: 100%;
}

#rewards-program .rewards__benefits--text-right .rewards__benefits-img {
  margin-top: -5px;
}

#rewards-program .rewards__benefits--text-right .rewards__benefits-content {
  padding-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #rewards-program .rewards__benefits--text-right .rewards__benefits-content {
    left: unset;
    width: 50%;
    top: 35px;
    right: 0;
  }
}

@media (min-width: 1024px) {
  #rewards-program .rewards__benefits--text-right .rewards__benefits-content {
    width: 45%;
    right: 50px;
  }
}

@media (min-width: 1200px) {
  #rewards-program .rewards__benefits--text-right .rewards__benefits-content {
    width: 35%;
    top: 85px;
    right: 150px;
  }
}

#rewards-program .rewards__benefits--text-right .rewards__benefits-title .cmp-title__text {
  font-size: 38px;
}

@media (min-width: 768px) {
  #rewards-program .rewards__benefits--text-right .rewards__benefits-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-large);
    line-height: 45px;
  }
}

@media (min-width: 1024px) {
  #rewards-program .rewards__benefits--text-right .rewards__benefits-title .cmp-title__text {
    font-size: 45px;
    line-height: 56px;
  }
}

#rewards-program .rewards__benefits--text-right .rewards__benefits-desc {
  margin-bottom: 25px;
}

#rewards-program .rewards__benefits-content {
  text-align: center;
  flex-direction: column;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  padding-bottom: 10px;
  display: flex;
}

@media (min-width: 768px) {
  #rewards-program .rewards__benefits-content {
    padding-top: var(--emu-common-spacing-none);
    width: 65%;
    position: absolute;
    top: 50px;
    left: 0;
  }
}

@media (min-width: 1024px) {
  #rewards-program .rewards__benefits-content {
    width: 45%;
    left: 50px;
  }
}

@media (min-width: 1200px) {
  #rewards-program .rewards__benefits-content {
    width: 35%;
    top: 110px;
    left: 150px;
  }
}

#rewards-program .rewards__benefits-icon {
  max-width: 95px;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #rewards-program .rewards__benefits-icon {
    max-width: 70px;
    margin-bottom: 10px;
  }
}

@media (min-width: 1024px) {
  #rewards-program .rewards__benefits-icon {
    max-width: 115px;
    margin-bottom: 25px;
  }
}

#rewards-program .rewards__benefits-title {
  text-align: center;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  #rewards-program .rewards__benefits-title {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1024px) {
  #rewards-program .rewards__benefits-title {
    margin-bottom: 15px;
  }
}

#rewards-program .rewards__benefits-title .cmp-title__text {
  line-height: 56px;
}

@media (min-width: 768px) {
  #rewards-program .rewards__benefits-title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-wide-large);
    line-height: 45px;
  }
}

@media (min-width: 1024px) {
  #rewards-program .rewards__benefits-title .cmp-title__text {
    font-size: 50px;
    line-height: 56px;
  }
}

#rewards-program .rewards__benefits-desc {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  max-width: 400px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  line-height: 28px;
}

@media (min-width: 768px) {
  #rewards-program .rewards__benefits-desc {
    max-width: 83%;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    line-height: 22px;
  }
}

@media (min-width: 1024px) {
  #rewards-program .rewards__benefits-desc {
    max-width: 400px;
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 576px) {
  #rewards-program .rewards__benefits-desc br {
    display: none;
  }
}

#rewards-program .rewards__benefits-disclaimer {
  font-size: 13px;
  line-height: 15px;
  font-weight: var(--emu-common-font-weight-regular);
  width: 80%;
  position: absolute;
  bottom: 15px;
  left: 20px;
}

@media (min-width: 768px) {
  #rewards-program .rewards__benefits-disclaimer {
    font-size: var(--emu-common-font-sizes-narrow-medium);
    line-height: var(--emu-semantic-line-heights-wide-xs);
    max-width: 60%;
    bottom: 13px;
    left: 45px;
  }
}

@media (min-width: 1024px) {
  #rewards-program .rewards__benefits-disclaimer {
    max-width: 100%;
    font-size: 13px;
    line-height: 15px;
    bottom: 25px;
  }
}

#safety .safety__banner {
  margin-top: -105px;
  position: relative;
}

@media (min-width: 768px) {
  #safety .safety__banner {
    min-height: 300px;
    margin-top: 113px;
  }
}

@media (min-width: 993px) {
  #safety .safety__banner {
    margin-top: 78px;
  }
}

@media (min-width: 1024px) {
  #safety .safety__banner {
    margin-top: -40px;
  }
}

#safety .safety__banner-bg img {
  object-position: bottom;
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 768px) {
  #safety .safety__banner-bg img {
    object-fit: cover;
  }
}

#safety .safety__banner-wrapper {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  z-index: var(--emu-common-other-z-index-layer);
  position: relative;
}

@media (min-width: 768px) {
  #safety .safety__banner-wrapper > .title {
    max-width: 50%;
    margin-left: auto;
  }
}

#safety .safety__banner-title {
  justify-content: center;
  align-items: center;
  min-height: 400px;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  #safety .safety__banner-title {
    min-height: 222px;
  }
}

@media (min-width: 1024px) {
  #safety .safety__banner-title {
    min-height: 400px;
  }
}

#safety .safety__banner-title .cmp-title__text {
  font-size: 35px;
  line-height: 1;
  position: absolute;
  bottom: 20%;
  right: 10%;
}

@media (min-width: 768px) {
  #safety .safety__banner-title .cmp-title__text {
    position: unset;
    padding-top: 160px;
    padding-left: 30px;
    font-size: 56px;
    line-height: 62px;
  }
}

@media (min-width: 1024px) {
  #safety .safety__banner-title .cmp-title__text {
    padding-left: var(--emu-common-spacing-none);
    padding-top: 40px;
    font-size: 80px;
    line-height: 70px;
  }
}

@media (min-width: 768px) {
  #safety .safety__instruction-wrapper {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    display: flex;
  }

  #safety .safety__instruction-wrapper > .text, #safety .safety__instruction-wrapper > .container {
    flex: 0 0 50%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

#safety .safety__instruction-content {
  font-weight: var(--emu-common-font-weight-regular);
  max-width: 535px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 35px;
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  #safety .safety__instruction-content {
    padding-top: 55px;
    padding-bottom: 30px;
  }
}

#safety .safety__instruction-content p:first-of-type {
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  #safety .safety__instruction-content p:first-of-type {
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

#safety .safety__instruction-content p:last-of-type {
  margin-bottom: var(--emu-common-spacing-medium);
}

#safety .safety__instruction-table {
  max-width: 535px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  #safety .safety__instruction-table {
    padding-top: 55px;
  }
}

@media (min-width: 1200px) {
  #safety .safety__instruction-table {
    padding-bottom: 40px;
  }
}

#safety .safety__instruction-table-title {
  margin-bottom: var(--emu-common-spacing-small);
  width: 98%;
}

@media (min-width: 768px) {
  #safety .safety__instruction-table-title {
    width: 100%;
  }
}

#safety .safety__instruction-table-title .cmp-title__text {
  font-family: var(--emu-semantic-font-families-mono);
  font-weight: var(--emu-semantic-font-weight-500);
  font-size: var(--emu-common-font-sizes-wide-medium);
  line-height: 1.2;
}

@media (min-width: 1024px) {
  #safety .safety__instruction-table-title .cmp-title__text {
    font-size: 20px;
  }
}

#safety .safety__instruction-disclaimer {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  font-weight: var(--emu-common-font-weight-regular);
  line-height: var(--emu-semantic-line-heights-wide-xs);
}

@media (min-width: 1200px) {
  #safety .safety__instruction-disclaimer {
    min-width: 580px;
  }
}

#safety .safety__instruction-disclaimer p {
  margin-bottom: 5px;
  padding-left: 10px;
}

@media (min-width: 768px) {
  #safety .safety__instruction-disclaimer p {
    font-size: var(--emu-semantic-font-sizes-narrow-small);
    padding-left: 12px;
    line-height: 1.5;
  }
}

@media (min-width: 1024px) {
  #safety .safety__instruction-disclaimer p {
    font-size: 20px;
  }
}

#safety .safety__instruction-disclaimer p:first-of-type {
  margin-top: var(--emu-common-spacing-small);
}

@media (min-width: 768px) {
  #safety .safety__instruction-disclaimer p:first-of-type {
    margin-top: var(--emu-common-spacing-none);
    font-size: var(--emu-semantic-font-sizes-wide-xs);
  }
}

#safety .safety__instruction-modal-title {
  padding-top: var(--emu-common-spacing-medium);
  padding-left: var(--emu-common-spacing-large);
  padding-right: var(--emu-common-spacing-large);
}

#safety .safety__instruction-modal-title .cmp-title__text {
  font-family: var(--emu-semantic-font-families-mono);
  font-weight: var(--emu-semantic-font-weight-500);
  margin-bottom: var(--emu-common-spacing-small);
  font-size: 15px;
  line-height: 22px;
}

@media (min-width: 768px) {
  #safety .safety__instruction-modal-title .cmp-title__text {
    font-size: 26px;
    line-height: 1.2;
  }
}

#safety .safety__instruction-modal-footer {
  font-size: var(--emu-common-font-sizes-wide-medium);
  font-family: var(--emu-semantic-font-families-mono);
  font-weight: var(--emu-common-font-weight-regular);
  line-height: var(--emu-common-line-heights-wide-medium);
  padding: 12px;
}

#safety .safety__instruction-modal-footer p {
  margin: var(--emu-common-spacing-xs);
}

#safety .safety__instruction-modal-close-text {
  top: var(--emu-common-spacing-large);
  font-size: var(--emu-semantic-font-sizes-wide-xs);
  font-family: var(--emu-semantic-font-families-mono);
  opacity: .5;
  cursor: pointer;
  position: absolute;
  right: 28px;
}

@media (min-width: 768px) {
  #safety .safety__instruction-modal-close-text {
    top: 46px;
    right: 38px;
  }
}

#safety .safety__instruction-modal-close-text:hover, #safety .safety__instruction-main-modal .close.main-close:hover + .container .safety__instruction-modal-close-text {
  opacity: .75;
}

.cq-Editable-dom--container .safety__banner-bg img {
  object-fit: contain !important;
  width: auto !important;
  height: auto !important;
  position: static !important;
}

#sitemap .sitemap {
  padding-top: 205px;
  padding-bottom: 65px;
}

@media (min-width: 768px) {
  #sitemap .sitemap {
    padding-top: 135px;
    padding-bottom: 150px;
  }
}

@media (min-width: 993px) {
  #sitemap .sitemap {
    padding-top: 100px;
  }
}

#sitemap .sitemap__title .cmp-title__text {
  margin-bottom: 45px;
  font-size: 44px;
  line-height: 1.2;
}

@media (min-width: 768px) {
  #sitemap .sitemap__title .cmp-title__text {
    margin-bottom: 65px;
  }
}

#sitemap .sitemap__text ul li {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-medium);
  font-weight: var(--emu-semantic-font-weight-500);
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  #sitemap .sitemap__text ul li {
    margin-bottom: 30px;
  }
}

#sitemap .sitemap__text ul li:last-child {
  margin-bottom: 30px;
}

#sitemap .sitemap__text ul li a {
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
}

#sitemap .sitemap__text ul li ul {
  margin-top: 5px;
}

@media (min-width: 768px) {
  #sitemap .sitemap__text ul li ul {
    margin-top: 20px;
  }
}

#sitemap .sitemap__text ul li ul li {
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  #sitemap .sitemap__text ul li ul li {
    margin-bottom: 15px;
  }
}

#sitemap .sitemap__text ul li ul li a {
  font-weight: var(--emu-common-font-weight-regular);
  text-decoration: none;
}

@media (min-width: 993px) {
  .cards-layout-wrapper {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}

.aaaem-accordion__item {
  padding-top: 12px;
  padding-bottom: 11px;
  padding-right: 20px;
}

.aaaem-accordion__item.u-hide + .aaaem-accordion__panel {
  display: none;
}

.aaaem-accordion__title {
  font-weight: var(--emu-semantic-font-weight-500);
}

.aaaem-accordion__icon {
  align-self: end;
  width: auto;
  height: auto;
  display: block;
}

.aaaem-accordion__icon:before {
  content: "+";
  font-size: var(--emu-semantic-font-sizes-wide-small);
  line-height: var(--emu-semantic-line-heights-wide-small);
  font-weight: var(--emu-semantic-font-weight-500);
  position: unset;
  transform: unset;
}

.aaaem-accordion__icon:after {
  display: none;
}

.aaaem-accordion__header[aria-expanded="true"] .aaaem-accordion__icon:before {
  content: "x";
}

@media (min-width: 768px) {
  .alle-fap {
    display: flex;
  }

  .alle-fap > .container {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.alle-fap .alle__container {
  position: relative;
}

.alle-fap .alle__content-container {
  position: absolute;
  top: 10%;
  right: 0;
}

@media (min-width: 768px) {
  .alle-fap .alle__content-container {
    top: 15%;
  }
}

@media (min-width: 1024px) {
  .alle-fap .alle__content-container {
    top: 20%;
    right: 5%;
  }
}

.alle-fap .alle__logo img {
  max-width: 80px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .alle-fap .alle__logo img {
    max-width: 150px;
  }
}

.alle-fap .alle__content {
  font-weight: var(--emu-common-font-weight-regular);
  font-size: var(--emu-common-font-sizes-narrow-medium);
  max-width: 65%;
  margin: 15px auto;
  line-height: 14px;
}

@media (min-width: 768px) {
  .alle-fap .alle__content {
    max-width: 60%;
    font-size: 14px;
    line-height: 16px;
  }
}

@media (min-width: 1024px) {
  .alle-fap .alle__content {
    max-width: 65%;
    font-size: 20px;
    line-height: 26px;
  }
}

.alle-fap .alle__content a {
  text-decoration: none;
}

.alle-fap .alle__content span.emphasis {
  color: var(--emu-semantic-colors-primary-100);
  text-underline-position: under;
  font-size: 20px;
  line-height: 1.5;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .alle-fap .alle__content span.emphasis {
    font-size: 18px;
  }
}

@media (min-width: 1024px) {
  .alle-fap .alle__content span.emphasis {
    font-size: var(--emu-semantic-font-sizes-wide-small);
  }
}

@media (min-width: 768px) {
  .alle-fap .alle__content p:not(:last-child) {
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

.alle-fap .fap__container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 15px;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  .alle-fap .fap__container {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    height: 100%;
  }
}

.alle-fap .fap__container .cmp-form {
  display: flex;
}

.alle-fap .fap__container .cmp-form-text {
  text-align: center;
}

.alle-fap .fap__container .cmp-form-text.has-error .error-message {
  display: none;
}

.alle-fap .fap__container .cmp-form-text__text {
  border-radius: var(--emu-common-border-radius-none);
  background-color: var(--emu-common-colors-transparent);
  border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-300);
  font-weight: var(--emu-common-font-weight-regular);
  font-size: var(--emu-common-font-sizes-wide-medium);
  line-height: var(--emu-common-line-heights-wide-large);
  color: var(--emu-common-colors-white);
  width: 210px;
  height: 40px;
}

.alle-fap .fap__container .cmp-form-text__text::placeholder {
  color: var(--emu-common-colors-white);
  text-align: center;
}

.alle-fap .fap__container .cmp-form-button {
  background-color: var(--emu-semantic-colors-secondary-400);
  width: 39px;
  height: 40px;
  font-size: 0;
  line-height: 0;
}

@media (min-width: 1024px) {
  .alle-fap .fap__container .cmp-form-button {
    transform: translateY(-1.5px);
  }
}

.alle-fap .fap__container .cmp-form-button:before {
  content: "";
  background-image: url("resources/images/search.png");
  background-size: cover;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  transform: translate(47%, -40%);
}

.alle-fap .fap__title {
  margin-bottom: 15px;
}

.alle-fap .fap__text {
  margin-bottom: var(--emu-common-spacing-medium);
  font-size: 14px;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .alle-fap .fap__text {
    font-size: var(--emu-common-font-sizes-wide-medium);
  }
}

.backToTop {
  background-color: var(--emu-common-colors-black);
}

.backToTop .aaaem-back-to-top-button.js-content-shown {
  display: block;
}

.backToTop a {
  line-height: var(--emu-common-line-heights-wide-medium);
  pointer-events: none;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  top: 53px;
  left: 0;
}

@media (min-width: 993px) {
  .backToTop a {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
  }
}

.backToTop a span {
  pointer-events: auto;
  font-size: var(--emu-semantic-font-sizes-wide-xs);
  font-weight: var(--emu-common-font-weight-regular);
  color: var(--emu-common-colors-white);
  line-height: 1.5;
}

.card {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .card {
    max-width: unset;
    flex: 1 0;
  }
}

@media (min-width: 768px) {
  .card {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 576px) {
  .card [data-emu-type="card"], .card .aaaem-card {
    height: 100%;
    display: flex;
  }
}

.card .aaaem-card__wrapper {
  border: var(--emu-common-border-width-thin) solid #00000020;
  border-radius: var(--emu-common-border-radius-xs);
}

@media (min-width: 576px) {
  .card .aaaem-card__content-wrapper {
    height: 100%;
    display: flex;
  }

  .card .aaaem-card__content-wrapper [data-ref="card-content"] {
    flex-direction: column;
    display: flex;
  }
}

.card .aaaem-card__footer {
  background-color: var(--emu-semantic-colors-primary-100);
}

@media (min-width: 576px) {
  .card .aaaem-card__footer {
    flex: 1;
  }
}

[data-component="carousel-v2"].emu-carousel[data-nav="true"] {
  padding-bottom: var(--emu-common-spacing-none);
}

[data-component="carousel-v2"].emu-carousel .tns-nav {
  bottom: 25px;
}

[data-component="carousel-v2"].emu-carousel .tns-nav button {
  border-radius: var(--emu-common-border-radius-none);
  width: 40px;
}

[data-component="carousel-v2"].emu-carousel .tns-nav button:not(:last-child) {
  margin-right: 10px;
}

@media (min-width: 768px) {
  [data-component="carousel-v2"].emu-carousel .tns-nav button {
    width: 30px;
  }
}

@media (min-width: 993px) {
  [data-component="carousel-v2"].emu-carousel .tns-nav button {
    width: 55px;
  }
}

[data-component="carousel-v2"].emu-carousel .emu-carousel__action span svg {
  display: none;
}

[data-component="carousel-v2"].emu-carousel .emu-carousel__action span:before {
  display: block;
}

[data-component="carousel-v2"].emu-carousel .emu-carousel__action-icon {
  background-repeat: no-repeat;
  background-size: 45%;
  width: 46px;
  height: 25px;
  display: block;
}

[data-component="carousel-v2"].emu-carousel .emu-carousel__action-prev .emu-carousel__action-icon {
  background-image: url("resources/images/prev-arrow.png");
}

[data-component="carousel-v2"].emu-carousel .emu-carousel__action-next .emu-carousel__action-icon {
  background-image: url("resources/images/next-arrow.png");
  background-position: 100% 0;
}

[data-component="container-isi-v2"] .aaaem-isi-banner {
  align-items: unset;
  z-index: var(--emu-common-other-z-index-layer);
  flex-direction: column-reverse;
  position: relative;
}

@media (min-width: 993px) {
  [data-component="container-isi-v2"] .aaaem-isi-banner {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container {
  height: 155px;
}

[data-component="container-isi-v2"] .aaaem-isi-container__button-wrapper span {
  font-weight: var(--emu-common-font-weight-bold);
  font-size: var(--emu-semantic-font-sizes-wide-xs);
  margin-bottom: 6px;
  line-height: 15px;
}

[data-component="container-isi-v2"] .aaaem-isi-container__banner-content p {
  font-size: var(--emu-semantic-font-sizes-wide-xs);
  font-weight: var(--emu-common-font-weight-regular);
  line-height: 15px;
}

[data-component="container-isi-v2"] .aaaem-isi-container__banner-content p:not(:has(.emphasis)) {
  margin-bottom: 6px;
}

[data-component="container-isi-v2"] .aaaem-isi-container__banner-content p .emphasis b {
  font-size: var(--emu-common-font-sizes-wide-medium);
  line-height: 1.5;
}

[data-component="container-isi-v2"] .aaaem-isi-container__banner-content p sup {
  font-weight: var(--emu-common-font-weight-regular);
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content {
  padding-top: 123px;
  padding-bottom: 40px;
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content p {
  font-size: var(--emu-common-font-sizes-wide-medium);
  line-height: 1.5;
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content p:not(:has(.emphasis)) {
  margin-bottom: var(--emu-common-spacing-medium);
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content p sup {
  font-weight: var(--emu-common-font-weight-light);
}

.custom-cursor__container {
  position: relative;
}

.custom-cursor__element {
  cursor: crosshair;
}

.custom-cursor__element--crosshair {
  cursor: url("resources/images/crossHair-image-new-medium.png") 7 4, crosshair;
}

.custom-cursor__element--crosshair-white {
  cursor: url("resources/images/cursor_plus_white.png") 7 4, crosshair;
}

.custom-cursor__text {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: var(--emu-common-line-heights-narrow-medium);
  padding-top: 3px;
  padding-bottom: var(--emu-common-spacing-xxs);
  padding-left: var(--emu-common-spacing-small);
  padding-right: var(--emu-common-spacing-small);
  background-color: #d5e2e2;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(16px, 20px);
}

.cq-Editable-dom--container .custom-cursor__text {
  display: block;
  position: static;
}

.custom-cursor__text ul {
  padding: var(--emu-common-spacing-none);
  margin: var(--emu-common-spacing-none);
  list-style: none;
}

.custom-cursor__text li {
  display: none;
}

.custom-cursor__text li:first-child {
  display: block;
}

.custom-cursor__text li:last-child {
  display: none;
}

.cq-Editable-dom--container .custom-cursor__text li, .custom-cursor__text--show {
  display: block;
}

.custom-cursor__text--toggle-text ul li:first-child {
  display: none;
}

.custom-cursor__text--toggle-text ul li:last-child {
  display: block;
}

footer.footer {
  z-index: calc(var(--emu-common-other-z-index-isi)  + 1);
  position: relative;
}

footer.footer .footer__wrapper {
  margin-top: -1px;
  padding-bottom: 30px;
}

@media (min-width: 1024px) {
  footer.footer .footer__links {
    justify-content: center;
    display: flex;
  }
}

footer.footer .footer__links ul {
  font-family: var(--emu-semantic-font-families-mono);
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
  display: flex;
}

@media (min-width: 1024px) {
  footer.footer .footer__links ul {
    max-width: 60%;
  }
}

footer.footer .footer__links ul li {
  font-size: var(--emu-common-font-sizes-wide-medium);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: var(--emu-common-spacing-small);
  padding-right: var(--emu-common-spacing-small);
  line-height: var(--emu-common-line-heights-wide-medium);
}

@media (min-width: 768px) {
  footer.footer .footer__links ul li {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    padding-left: 15px;
    padding-right: 15px;
    line-height: 28px;
  }
}

footer.footer .footer__links ul li a {
  font-size: 13px;
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-semantic-font-weight-500);
}

footer.footer .footer__links ul li img {
  vertical-align: middle;
  margin-left: 3px;
  margin-bottom: var(--emu-common-spacing-xxs);
  width: 30px;
}

footer.footer .footer__note {
  font-size: var(--emu-semantic-font-sizes-wide-xs);
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
}

@media (min-width: 768px) {
  footer.footer .footer__note {
    max-width: 100%;
  }
}

@media (min-width: 1024px) {
  footer.footer .footer__note {
    font-size: var(--emu-common-font-sizes-wide-medium);
  }
}

footer.footer .footer__note br {
  display: none;
}

@media (min-width: 768px) {
  footer.footer .footer__note br {
    display: block;
  }
}

footer.footer .footer__note .emphasis {
  white-space: nowrap;
}

div.header .header--sticky .header__menu {
  position: fixed;
  top: 0;
  left: 0;
}

@media (min-width: 993px) {
  div.header .header--sticky .header__menu {
    height: var(--emu-common-sizing-none);
  }
}

header.experiencefragment {
  z-index: var(--emu-common-other-z-index-header);
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.skip-menu-container a.skip-menu {
  position: absolute;
}

.header__eyebrow-dropdown-wrapper > .button {
  z-index: calc(var(--emu-common-other-z-index-header)  + 1);
  position: absolute;
  top: 11px;
  right: 31px;
}

.header__eyebrow-dropdown-wrapper:has(.header__eyebrow-container.js-toggle-on) > .button {
  top: 16px;
}

.header__eyebrow-wrapper.aaaem-container {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
}

@media (min-width: 993px) {
  .header__eyebrow-wrapper.aaaem-container {
    justify-content: center;
    padding-left: 15px;
    padding-right: 45px;
    display: flex;
  }

  .header__eyebrow-wrapper.aaaem-container > .button:not(:last-child) {
    align-items: center;
    display: flex;
  }
}

.header__eyebrow-container {
  z-index: calc(var(--emu-common-other-z-index-header)  + 1);
  width: 100%;
  transition: transform .3s ease-in-out;
  position: absolute;
  transform: translateY(-100%)translateY(41px);
}

@media (min-width: 993px) {
  .header__eyebrow-container {
    transform: unset;
    position: relative;
  }
}

.header__eyebrow-container.js-toggle-on {
  transform: unset;
}

.header__eyebrow-container.js-toggle-on .header__eyebrow-items ul li:last-child {
  padding-top: 15px;
  padding-bottom: 17px;
}

.header__eyebrow-btn {
  position: absolute;
}

.header__eyebrow-btn.emu-button__primary-filled.emu-button.emu-button-v2 {
  padding: var(--emu-common-spacing-none);
}

@media (min-width: 993px) {
  .header__eyebrow-btn.emu-button__primary-filled.emu-button.emu-button-v2 {
    display: none;
  }
}

.header__eyebrow-btn:before {
  content: "";
  border-right: var(--emu-common-border-width-medium) solid var(--emu-common-colors-white);
  border-top: var(--emu-common-border-width-medium) solid var(--emu-common-colors-white);
  width: 10px;
  height: 10px;
  position: absolute;
  transform: rotate(135deg);
}

.header__eyebrow-btn.js-toggle-on:before {
  transform: rotate(-45deg);
}

.header__eyebrow-items {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: normal;
}

@media (min-width: 993px) {
  .header__eyebrow-items ul {
    display: flex;
  }
}

.header__eyebrow-items li {
  padding-top: 15px;
  padding-bottom: 12px;
}

@media (min-width: 993px) {
  .header__eyebrow-items li {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    border-bottom: none;
    align-items: center;
    height: 35px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
  }
}

.header__eyebrow-items li:not(:last-child) {
  border-bottom: 1.5px solid var(--emu-common-colors-white);
}

@media (min-width: 993px) {
  .header__eyebrow-items li:not(:last-child) {
    border-bottom: none;
  }
}

.header__eyebrow-items li:last-child {
  padding-top: 13px;
  padding-bottom: 10px;
}

@media (min-width: 993px) {
  .header__eyebrow-items li:last-child {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
  }

  .header__eyebrow-items li.item-active {
    color: var(--emu-semantic-colors-primary-100);
    background-color: var(--emu-common-colors-white);
    font-weight: var(--emu-common-font-weight-bold);
  }
}

.header__eyebrow-items a {
  text-decoration: none;
}

.header__brand-menu-dropdown {
  background-color: var(--emu-semantic-colors-primary-100);
  z-index: calc(var(--emu-common-other-z-index-header)  + 1);
  transition: all ease var(--emu-common-other-time-transition-xl);
  width: 100%;
  display: none;
  position: absolute;
  top: 0;
}

@media (min-width: 993px) {
  .header__brand-menu-dropdown {
    background-color: var(--emu-common-colors-white);
    top: 35px;
  }
}

.header__brand-menu-dropdown--open {
  display: block;
}

.header__brand-menu-close-btn {
  position: absolute;
  top: 10px;
  right: 20px;
}

@media (min-width: 993px) {
  .header__brand-menu-close-btn {
    top: 0;
  }
}

.header__brand-menu-close-btn span {
  font-weight: var(--emu-common-font-weight-regular);
  font-size: var(--emu-semantic-font-sizes-wide-xs);
  color: var(--emu-common-colors-white);
  line-height: 21px;
}

@media (min-width: 993px) {
  .header__brand-menu-close-btn span {
    color: var(--emu-semantic-colors-primary-50);
  }
}

.header__brand-menu-close-btn.emu-button.emu-button-v2.emu-button__primary-filled {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  background-color: var(--emu-common-colors-transparent);
  border-width: var(--emu-common-border-width-none);
}

.header__brand-menu-close-btn.emu-button.emu-button-v2.emu-button__primary-filled:hover, .header__brand-menu-close-btn.emu-button.emu-button-v2.emu-button__primary-filled:active, .header__brand-menu-close-btn.emu-button.emu-button-v2.emu-button__primary-filled:focus {
  background-color: var(--emu-common-colors-transparent);
  color: inherit;
}

.header__brand-menu-items ul {
  flex-direction: column;
  width: 100%;
  display: flex;
}

@media (min-width: 993px) {
  .header__brand-menu-items ul {
    flex-wrap: wrap;
    height: 95px;
  }
}

.header__brand-menu-items li {
  flex: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 12px;
  padding-bottom: 15px;
}

@media (min-width: 993px) {
  .header__brand-menu-items li {
    justify-content: center;
    align-items: center;
    padding-top: 13.5px;
    padding-bottom: 13.5px;
    display: flex;
  }
}

.header__brand-menu-items li:not(:last-child) {
  border-bottom: 1.5px solid var(--emu-common-colors-white);
}

@media (min-width: 993px) {
  .header__brand-menu-items li:not(:last-child) {
    border-bottom: none;
    border-right: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-100);
  }
}

.header__brand-menu-items a {
  font-size: var(--emu-semantic-font-sizes-wide-xs);
  line-height: 21px;
  font-weight: var(--emu-common-font-weight-regular);
  color: var(--emu-common-colors-white);
  text-decoration: none;
  display: block;
}

@media (min-width: 993px) {
  .header__brand-menu-items a {
    color: var(--emu-semantic-colors-primary-50);
    border-bottom: var(--emu-common-border-width-thin) solid var(--emu-common-colors-transparent);
  }
}

@media (hover: hover) and (min-width: 993px) {
  .header__brand-menu-items a:hover {
    border-color: var(--emu-semantic-colors-primary-50);
  }
}

.header__menu {
  background-color: var(--emu-semantic-colors-secondary-100);
  width: 100%;
  height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  top: 45px;
}

@media (min-width: 768px) {
  .header__menu {
    top: 40px;
  }
}

@media (min-width: 993px) {
  .header__menu {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    position: unset;
    height: 95px;
  }
}

@media (min-width: 1024px) {
  .header__menu {
    background-color: var(--emu-common-colors-transparent);
  }
}

.header__menu > .button {
  top: 20.5px;
  right: var(--emu-common-spacing-medium);
  position: absolute;
}

@media (min-width: 768px) {
  .header__menu > .button {
    top: 24px;
  }
}

@media (min-width: 993px) {
  .header__menu > .button {
    display: none;
  }
}

.header__menu > .button .emu-button.emu-button-v2 {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  border-width: var(--emu-common-border-width-none);
}

.header__menu > .button .emu-button.emu-button-v2 span {
  width: var(--emu-common-sizing-medium);
  background-image: url("resources/images/menu-hamburger.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 20px;
  display: block;
}

.header__menu > .button .emu-button.emu-button-v2.js-toggle-on span {
  background-image: url("resources/images/menu-close.png");
  width: 24px;
}

.header__menu-mobile-dropdown {
  width: 100%;
  max-height: 0;
  transition: all .3s ease-in-out;
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  overflow: hidden;
}

@media (min-width: 993px) {
  .header__menu-mobile-dropdown {
    max-height: unset;
    position: relative;
    overflow: visible;
  }
}

.header__menu-mobile-dropdown > .container {
  pointer-events: none;
  background-color: var(--emu-semantic-colors-secondary-100);
  max-height: 100vh;
  padding-top: 11px;
  padding-bottom: 3px;
  overflow: auto;
}

@media (min-width: 768px) {
  .header__menu-mobile-dropdown > .container {
    padding-bottom: var(--emu-common-spacing-none);
    padding-top: 18px;
  }
}

@media (min-width: 993px) {
  .header__menu-mobile-dropdown > .container {
    padding-top: var(--emu-common-spacing-none);
    pointer-events: initial;
    position: static;
    overflow: visible;
  }
}

.header__menu-mobile-dropdown.js-toggle-on {
  max-height: 100vh;
}

.header__menu-mobile-dropdown.js-toggle-on > .container {
  pointer-events: initial;
}

@media (min-width: 993px) {
  .header__menu-mobile-dropdown-wrapper {
    display: flex;
  }
}

.header__menu-mobile-dropdown-wrapper.aaaem-container {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
}

@media (min-width: 993px) {
  .header__menu-mobile-dropdown-wrapper.aaaem-container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .header__menu-mobile-dropdown-wrapper > .navigationlist {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .header__menu-mobile-dropdown-wrapper > .image {
    flex: 0 0 16.6667%;
    justify-content: center;
    align-items: center;
    max-width: 16.6667%;
    display: flex;
  }
}

.header__menu-mobile-dropdown-wrapper .header__navigation-list > ul li:last-child {
  border-width: var(--emu-common-border-width-none);
}

@media (min-width: 993px) {
  .header__menu-mobile-dropdown-wrapper .header__navigation-list > ul li:last-child {
    border-bottom-width: var(--emu-common-border-width-thick);
  }
}

@media (min-width: 768px) {
  .header__menu-mobile-dropdown-wrapper .header__navigation-list > ul li:last-child a {
    padding-bottom: 7px;
  }
}

@media (min-width: 993px) {
  .header__menu-mobile-dropdown-wrapper .header__navigation-list > ul li:last-child a {
    padding-bottom: var(--emu-common-spacing-none);
  }

  .header__menu-mobile-dropdown-wrapper .header__navigation-list > ul {
    justify-content: flex-end;
  }
}

.header__menu-close-logo {
  justify-content: center;
  display: flex;
}

@media (min-width: 993px) {
  .header__menu-close-logo {
    display: none;
  }
}

.cq-Editable-dom--container .header__menu-close-logo {
  display: block;
}

.header__menu-close-logo img {
  width: 110px;
}

@media (min-width: 768px) {
  .header__menu-close-logo img {
    width: 128px;
  }
}

.header__menu-open-logo {
  display: none;
}

@media (min-width: 993px) {
  .header__menu-open-logo {
    display: block;
  }
}

.cq-Editable-dom--container .header__menu-open-logo {
  display: block;
}

.header__menu-open-logo img {
  width: 160px;
}

.navigationlist .emu-navigation__content-wrapper {
  position: relative;
}

@media (min-width: 993px) {
  .navigationlist .emu-navigation__content-wrapper, .navigationlist .emu-navigation__content-wrapper nav, .navigationlist .emu-navigation__content-wrapper nav > ul {
    position: static;
  }
}

.navigationlist .emu-navigation__content-wrapper nav .emu-navigation__menu--level-0 .emu-navigation__item-parent {
  border-bottom-width: var(--emu-common-border-width-none);
}

@media (min-width: 993px) {
  .navigationlist .emu-navigation__content-wrapper nav .emu-navigation__menu--level-0 .emu-navigation__item-parent {
    border-bottom-width: var(--emu-common-border-width-thick);
  }

  .navigationlist .emu-navigation__content-wrapper nav .emu-navigation__menu--level-0 .emu-navigation__item-parent:after {
    content: "";
    background-color: var(--emu-semantic-colors-secondary-100);
    border-top: var(--emu-common-border-width-thin) solid #c4c3c3;
    visibility: hidden;
    z-index: var(--emu-common-other-z-index-behind);
    width: 100%;
    height: 35px;
    position: absolute;
    bottom: -35px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.navigationlist .emu-navigation__content-wrapper nav .emu-navigation__menu--level-0 .emu-navigation__item-parent > a {
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-common-colors-black);
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (min-width: 993px) {
  .navigationlist .emu-navigation__content-wrapper nav .emu-navigation__menu--level-0 .emu-navigation__item-parent > a {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    border-bottom: none;
  }

  .navigationlist .emu-navigation__content-wrapper nav .emu-navigation__menu--level-0 .emu-navigation__item-parent:hover:after {
    visibility: visible;
  }

  .navigationlist .emu-navigation__content-wrapper nav .emu-navigation__menu--level-0 .emu-navigation__item-parent:hover .emu-navigation__menu--level-1 {
    display: flex;
  }
}

.navigationlist .emu-navigation__content-wrapper nav > ul {
  padding-left: 25px;
  padding-right: 25px;
}

@media (min-width: 768px) {
  .navigationlist .emu-navigation__content-wrapper nav > ul {
    flex-direction: column;
  }
}

@media (min-width: 993px) {
  .navigationlist .emu-navigation__content-wrapper nav > ul {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    flex-direction: row;
    align-items: center;
    height: 95px;
  }
}

.navigationlist .emu-navigation__content-wrapper nav > ul > li {
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-common-colors-black);
}

@media (min-width: 993px) {
  .navigationlist .emu-navigation__content-wrapper nav > ul > li {
    border-bottom: var(--emu-common-border-width-thick) solid var(--emu-common-colors-transparent);
    margin-top: var(--emu-common-spacing-xs);
    align-items: center;
    height: 91px;
    display: flex;
  }

  .navigationlist .emu-navigation__content-wrapper nav > ul > li:hover {
    border-bottom-color: var(--emu-semantic-colors-primary-100);
  }
}

.navigationlist .emu-navigation__content-wrapper nav > ul > li a {
  text-align: center;
  font-size: var(--emu-semantic-font-sizes-wide-xs);
  line-height: 21px;
  font-weight: var(--emu-common-font-weight-regular);
  margin-top: var(--emu-common-spacing-xs);
  padding-top: 13px;
  padding-bottom: 10px;
}

@media (min-width: 993px) {
  .navigationlist .emu-navigation__content-wrapper nav > ul > li a {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    margin-top: var(--emu-common-spacing-none);
    padding-left: 22px;
    padding-right: 22px;
  }
}

.navigationlist .emu-navigation__content-wrapper nav > ul > li > ul {
  display: block;
}

@media (min-width: 768px) {
  .navigationlist .emu-navigation__content-wrapper nav > ul > li > ul {
    position: relative;
  }
}

@media (min-width: 993px) {
  .navigationlist .emu-navigation__content-wrapper nav > ul > li > ul {
    display: none;
    position: absolute;
    bottom: -35px;
  }
}

.navigationlist .emu-navigation__content-wrapper nav > ul > li > ul li {
  border-bottom: var(--emu-common-border-width-thin) solid #bcb8b6;
}

@media (min-width: 993px) {
  .navigationlist .emu-navigation__content-wrapper nav > ul > li > ul li {
    border-bottom: none;
  }
}

.navigationlist .emu-navigation__content-wrapper nav > ul > li > ul li a {
  margin-top: var(--emu-common-spacing-none);
  padding-top: var(--emu-common-spacing-small);
  height: auto;
  padding-bottom: 5px;
}

@media (min-width: 993px) {
  .navigationlist .emu-navigation__content-wrapper nav > ul > li > ul li a {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.emu-modal.emu-modal .modal-wrapper {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  align-items: center;
  width: auto;
  transition: all .3s ease-out .3s;
  display: flex;
}

@media (min-width: 768px) {
  .emu-modal.emu-modal .modal-wrapper {
    width: 100%;
  }
}

.emu-modal.emu-modal .modal-wrapper .modal-content {
  transition: all .3s ease-out;
  transition-delay: var(--emu-common-other-time-delay-none);
  opacity: 0;
  margin-left: var(--emu-common-spacing-small);
  margin-right: var(--emu-common-spacing-small);
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  width: auto;
  transform: translateY(-50px);
}

@media (min-width: 576px) {
  .emu-modal.emu-modal .modal-wrapper .modal-content {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 768px) {
  .emu-modal.emu-modal .modal-wrapper .modal-content {
    margin-left: auto;
    margin-right: auto;
  }
}

.emu-modal.emu-modal .modal-wrapper.is-open {
  opacity: 1;
  background-color: var(--emu-semantic-colors-secondary-100);
  visibility: visible;
  transition-delay: var(--emu-common-other-time-delay-none);
  pointer-events: initial;
}

.emu-modal.emu-modal .modal-wrapper.is-open .modal-content {
  opacity: 1;
  transition-delay: .3s;
  transform: translateY(0);
}

.emu-modal.emu-modal .modal-wrapper .close {
  text-shadow: 0 1px 0 var(--emu-common-colors-white);
  opacity: .5;
  z-index: var(--emu-common-other-z-index-layer);
  justify-content: end;
  width: 96px;
  display: flex;
  top: 14px;
  right: -6px;
}

@media (min-width: 768px) {
  .emu-modal.emu-modal .modal-wrapper .close {
    top: 28px;
    right: var(--emu-common-spacing-xs);
  }
}

.emu-modal.emu-modal .modal-wrapper .close:hover {
  opacity: .75;
}

.emu-modal.emu-modal .modal-wrapper .close span {
  font-size: 20px;
  font-family: var(--emu-semantic-font-families-mono);
  font-weight: var(--emu-common-font-weight-bold);
  margin-top: var(--emu-common-spacing-xs);
  line-height: 1;
}

@media (min-width: 1024px) {
  .aaaem-tabs__tab a:hover {
    text-decoration: underline;
  }
}



[data-component="text"] p, [data-component="text"] li {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  background-color: inherit;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
}

.aaaem-title__color-black {
  color: var(--emu-common-colors-black);
}

.aaaem-title__color-white {
  color: var(--emu-common-colors-white);
}

.aaaem-title__color-primary {
  color: var(--emu-semantic-colors-primary-100);
}

.aaaem-tabs--treatment {
  flex-direction: column-reverse;
  display: flex;
}

.aaaem-tabs--treatment .aaaem-tabs__tablist {
  justify-content: space-evenly;
  margin-top: 15px;
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  .aaaem-tabs--treatment .aaaem-tabs__tablist {
    padding-bottom: var(--emu-common-spacing-none);
    left: var(--emu-common-spacing-medium);
    flex-direction: column;
    position: absolute;
    bottom: 70px;
  }
}

@media (min-width: 1200px) {
  .aaaem-tabs--treatment .aaaem-tabs__tablist {
    left: 0;
  }
}

.aaaem-tabs--treatment .aaaem-tabs__tab {
  margin-bottom: 15px;
  font-size: 10px;
  line-height: 1.5;
}

@media (min-width: 1024px) {
  .aaaem-tabs--treatment .aaaem-tabs__tab {
    font-size: 14px;
  }
}

.aaaem-tabs--treatment .aaaem-tabs__tab a {
  align-items: center;
  display: flex;
}

.aaaem-tabs--treatment .aaaem-tabs__tab a:before {
  background-size: 25px;
  width: 25px;
  height: 25px;
  display: inline-block;
}

@media (min-width: 1024px) {
  .aaaem-tabs--treatment .aaaem-tabs__tab a:before {
    background-size: 40px;
    width: 40px;
    height: 40px;
  }
}

.aaaem-tabs--treatment .aaaem-tabs__tab a[aria-selected="true"] {
  font-weight: var(--emu-semantic-font-weight-500);
}

.aaaem-tabs--treatment .aaaem-tabs__tab span {
  padding-left: 5px;
}

@media (min-width: 1024px) {
  .aaaem-tabs--treatment .aaaem-tabs__tab span {
    padding-left: 10px;
  }
}

@media (min-width: 1200px) {
  .aaaem-tabs--treatment .aaaem-tabs__tab span {
    padding-left: 15px;
  }
}

.aaaem-tabs--treatment .aaaem-tabs__tab:first-child a:before {
  content: "";
  background-image: url("resources/images/eye-open-icon.png");
}

.aaaem-tabs--treatment .aaaem-tabs__tab:first-child a[aria-selected="true"]:before {
  background-image: url("resources/images/eye-open-icon-active.png");
}

.aaaem-tabs--treatment .aaaem-tabs__tab:nth-child(2) a:before {
  content: "";
  background-image: url("resources/images/eye-close-icon.png");
}

.aaaem-tabs--treatment .aaaem-tabs__tab:nth-child(2) a[aria-selected="true"]:before {
  background-image: url("resources/images/eye-close-icon-active.png");
}

.aaaem-tabs--treatment .aaaem-tabs__tab:nth-child(3) a:before {
  content: "";
  background-image: url("resources/images/eye-angle-icon.png");
}

.aaaem-tabs--treatment .aaaem-tabs__tab:nth-child(3) a[aria-selected="true"]:before {
  background-image: url("resources/images/eye-angle-icon-active.png");
}

/*# sourceMappingURL=main.css.map */
