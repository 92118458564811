.card {
  max-width: 80%; //as per live
  margin-left: auto;
  margin-right: auto;

  @include mq('small-plus') {
    max-width: unset; //as per live
    flex: 1 0 0%;
  }

  @include mq('medium') {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: var(--emu-common-spacing-none);
  }

  & [data-emu-type='card'] {
    @include mq('small-plus') {
      display: flex;
      height: 100%;
    }
  }

  .aaaem-card {
    @include mq('small-plus') {
      display: flex;
      height: 100%;
    }

    &__wrapper {
      border: var(--emu-common-border-width-thin) solid #00000020; //color used once
      border-radius: var(--emu-common-border-radius-xs);
    }

    &__content-wrapper {
      @include mq('small-plus') {
        display: flex;
        height: 100%;
      }

      & [data-ref='card-content'] {
        @include mq('small-plus') {
          display: flex;
          flex-direction: column;
        }
      }
    }

    &__footer {
      background-color: var(--emu-semantic-colors-primary-100);

      @include mq('small-plus') {
        flex: 1;
      }
    }
  }
}
