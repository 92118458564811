div.header {
  .header--sticky {
    .header {
      &__menu {
        position: fixed;
        top: 0;
        left: 0;

        @include mq('tablet') {
          height: var(--emu-common-sizing-none);
        }
      }
    }
  }
}
