#home {
  .home {
    &__banner {
      .aaaem-embed {
        &__embeddable {
          margin-top: 104px;

          @include mq('medium') {
            margin-top: 116px;
          }

          @include mq('tablet') {
            margin-top: var(--emu-common-spacing-xs);
          }

          @include mq('x-large') {
            margin-top: 7px;
          }

          &:not(.no-ratio) {
            padding-bottom: 125.64%; //to match live

            @include mq('medium') {
              padding-bottom: 56.25%;
            }
          }
        }
      }

      &-video {
        &-content {
          position: relative;

          > .container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            background: rgba(0, 0, 0, 0.2); //used once
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            @include mq('medium') {
              background: var(--emu-common-colors-transparent);
            }
          }
        }

        &-overlay {
          &-text {
            font-size: var(--emu-semantic-font-sizes-wide-small);
            line-height: var(--emu-semantic-line-heights-wide-small);
            font-weight: var(--emu-common-font-weight-regular);

            br {
              @include mq('medium') {
                display: none;
              }
            }
          }

          &-title {
            margin-bottom: var(--emu-common-spacing-small);

            .cmp-title__text {
              font-size: 36px;
              line-height: 46px;
              font-weight: var(--emu-common-font-weight-regular);

              @include mq('medium') {
                font-size: 55px;
                line-height: 66px;
              }

              br {
                @include mq('medium') {
                  display: none;
                }
              }
            }

            &--small {
              .cmp-title__text {
                font-size: var(--emu-semantic-font-sizes-wide-xl);
                line-height: 1.2;
              }
            }
          }
        }
      }

      &-footnote {
        margin-top: -7px;
        position: relative;

        @include mq('medium') {
          margin-top: var(--emu-common-spacing-none);
        }

        @include mq('x-large') {
          margin-top: var(--emu-common-sizing-xxs);
        }

        &-text {
          padding-top: var(--emu-common-spacing-small);
          padding-bottom: var(--emu-common-spacing-small);
          font-weight: var(--emu-common-font-weight-regular);
          font-size: 13px;
          line-height: 1.5;

          @include mq('medium') {
            font-size: var(--emu-common-font-sizes-narrow-medium);
          }

          @include mq('tablet') {
            font-size: 15px;
          }

          b {
            font-weight: var(--emu-common-font-weight-regular);
          }
        }
      }
    }

    &__text-with-desc {
      &-wrapper {
        padding-top: 60px;
        padding-bottom: 60px;

        @include mq('medium') {
          display: flex;
          padding-top: 100px;
          padding-right: var(--emu-common-spacing-none);
          padding-bottom: 100px;
          padding-left: var(--emu-common-spacing-none);
          align-items: center;
        }

        > .text {
          flex: 0 0 50%; // as per live

          &:first-child {
            @include mq('medium') {
              align-self: start; // to match live
            }
          }
        }
      }

      &-title {
        font-size: 52px;
        margin-bottom: 15px;
        line-height: 1.2;
        text-align: center;
        width: fit-content; // as per live
        margin-left: auto;
        margin-right: auto;

        @include mq('medium') {
          text-align: left;
          padding-left: 15px;
          padding-right: 15px;
          font-size: 50px;
          margin-bottom: var(--emu-common-spacing-none);
        }

        @include mq('tablet') {
          font-size: 80px;
        }

        .emphasis {
          @include mq('medium') {
            display: block;
            text-indent: -50px; // as per live
          }

          @include mq('tablet') {
            text-indent: -80px; // as per live
          }
        }
      }

      &-text {
        font-size: var(--emu-common-font-sizes-wide-medium);
        line-height: 1.5;
        text-align: center;

        @include mq('medium') {
          font-size: 23px;
          text-align: left;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }

    &__cards {
      padding-top: 80px;
      padding-bottom: 50px;

      @include mq('medium') {
        padding-bottom: 80px;
      }

      &-heading {
        margin-bottom: 40px;

        .cmp-title__text {
          font-size: 35px;
          font-weight: var(--emu-semantic-font-weight-500);
          line-height: 1.2;

          br {
            display: block;

            @include mq('medium') {
              display: none;
            }
          }
        }
      }

      &-grid {
        @include mq('small-plus') {
          display: flex;
        }

        @include mq('medium') {
          width: 95%; //as per live
          margin-left: auto;
          margin-right: auto;
        }

        @include mq('tablet') {
          width: 100%; //as per live
        }
      }

      &-content {
        margin-bottom: 20px;

        @include mq('small-plus') {
          margin-bottom: var(--emu-common-spacing-none);
        }

        &-img {
          img {
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            width: 100%; //as per live
          }
        }

        &-footer {
          padding-top: 35px;
          padding-bottom: 35px;
          padding-left: 30px;
          padding-right: 30px;

          @include mq('medium') {
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 20px;
            padding-right: 20px;
          }
        }

        &-title {
          margin-bottom: 12px;
        }

        &-text {
          line-height: 22px;

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-small);
            line-height: 25px;
          }

          sup {
            font-size: 13px;
          }
        }
      }

      &-footnote {
        font-size: 11px;
        font-family: var(--emu-semantic-font-families-mono);
        font-weight: var(--emu-common-font-weight-regular);
        line-height: 1.5;
        max-width: 80%; //as per live
        margin-left: auto;
        margin-right: auto;
        margin-bottom: var(--emu-common-spacing-medium);

        @include mq('small-plus') {
          margin-top: 20px;
        }

        @include mq('medium') {
          margin-top: var(--emu-common-spacing-none);
          max-width: 94%; //as per live
          font-size: var(--emu-common-font-sizes-wide-medium);
        }

        @include mq('x-large') {
          max-width: 100%; //as per live
          padding-left: 15px;
        }
      }
    }

    &__results {
      padding-top: 70px;
      padding-bottom: 120px;

      @include mq('medium') {
        display: flex;
        flex-direction: row-reverse;
      }

      &.aaaem-container {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      > .container {
        &:first-child {
          @include mq('medium') {
            max-width: 41.67%; // as per live
            flex: 0 0 41.67%; // as per live
            margin-top: auto;
            margin-bottom: auto;
          }
        }

        &:last-child {
          @include mq('medium') {
            max-width: 58.33%; // as per live
            flex: 0 0 58.33%; // as per live
          }
        }
      }

      &-content {
        > .button {
          text-align: center;

          @include mq('medium') {
            text-align: left;
          }
        }
      }

      &-title {
        .cmp-title__text {
          font-size: 50px;
          line-height: 1.2;

          @include mq('medium') {
            font-size: 45px;
            text-align: left;
          }

          @include mq('tablet') {
            font-size: var(--emu-semantic-font-sizes-wide-xxl);
          }
        }
      }

      &-cta {
        &.emu-button.emu-button-v2.emu-button__primary-filled {
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 25px;
          padding-right: 25px;
          width: 50%; // as per live
          justify-content: center;
          margin-top: 20px;
          margin-bottom: 65px;

          @include mq('medium') {
            width: auto; // as per live
            margin-bottom: var(--emu-common-spacing-none);
          }
        }

        span {
          font-weight: var(--emu-common-font-weight-regular);
          font-size: var(--emu-semantic-font-sizes-wide-xs);
          line-height: 1.5;
        }
      }

      &-carousel-wrapper {
        position: relative;

        @include mq('medium') {
          display: flex;
        }

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 100%; // as per live
          width: 60%; // as per live
          background: var(--emu-semantic-colors-secondary-400);
        }

        > .text {
          &:first-of-type {
            @include mq('medium') {
              width: 20%; // as per live
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
            }
          }
        }

        > .carousel {
          @include mq('medium') {
            max-width: 70%; // as per live
            margin-left: 15px;
          }
        }
      }

      &-carousel {
        max-width: 95%; // as per live
        padding-left: 30px;
        padding-top: 30px;
        padding-bottom: 30px;

        @include mq('medium') {
          max-width: none;
          padding-top: 50px;
          padding-bottom: 45px;
        }

        .emu-carousel__item {
          margin-right: var(
            --emu-common-sizing-xxs
          ); // to hide next image in carousel

          @include mq('medium') {
            margin-right: var(--emu-common-spacing-none);
          }
        }
      }

      &-carousel-img {
        display: inline-block; // as per live
      }

      &-carousel-sidebar {
        position: relative;

        @include mq('medium') {
          ul {
            margin-bottom: 25px;

            li {
              font-weight: var(--emu-semantic-font-weight-500);
              padding-bottom: 12px;
              cursor: pointer;

              @include mq('large') {
                font-size: 18px;
                line-height: 1.5;
              }

              &:hover {
                text-decoration: underline;
              }

              &.item-active {
                color: var(--emu-semantic-colors-primary-100);
                font-weight: var(--emu-common-font-weight-bold);
              }

              a {
                &,
                &:hover,
                &:focus,
                &:active {
                  color: inherit;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }

      &-footnote {
        font-family: var(--emu-semantic-font-families-mono);
        font-weight: var(--emu-common-font-weight-regular);
        font-size: var(--emu-common-font-sizes-narrow-medium);
        line-height: 1.5;
        margin-top: var(--emu-common-spacing-medium);
        margin-bottom: var(--emu-common-spacing-medium);
        padding-left: 15px;
        padding-right: 15px;
        position: absolute;
        bottom: -85px;
        left: 0;

        @include mq('medium') {
          font-size: 11px;
          bottom: -65px;
        }

        @include mq('tablet') {
          font-size: 12px;
          margin-top: var(--emu-common-spacing-none);
        }

        // needed to make the output look better
        @include mq('1240px') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }

        br {
          display: none;

          @include mq('medium') {
            display: block;
          }
        }
      }
    }
  }
}
