#safety {
  .safety {
    &__banner {
      margin-top: -105px;
      position: relative;

      @include mq('medium') {
        min-height: 300px; //as per live
        margin-top: 113px;
      }

      @include mq('tablet') {
        margin-top: 78px;
      }

      @include mq('large') {
        margin-top: -40px;
      }

      &-bg {
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-position: bottom;
          object-fit: contain;

          @include mq('medium') {
            object-fit: cover;
          }
        }
      }

      &-wrapper {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
        position: relative;
        z-index: var(--emu-common-other-z-index-layer);

        > .title {
          @include mq('medium') {
            max-width: 50%; // as per live
            margin-left: auto;
          }
        }
      }

      &-title {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        min-height: 400px; //as per live

        @include mq('medium') {
          min-height: 222px; //as per live
        }

        @include mq('large') {
          min-height: 400px; //as per live
        }

        .cmp-title__text {
          font-size: 35px;
          line-height: 1;
          position: absolute;
          bottom: 20%;
          right: 10%;

          @include mq('medium') {
            font-size: 56px;
            line-height: 62px;
            padding-left: 30px;
            padding-top: 160px;
            position: unset;
          }

          @include mq('large') {
            font-size: 80px;
            line-height: 70px;
            padding-left: var(--emu-common-spacing-none);
            padding-top: 40px;
          }
        }
      }
    }

    &__instruction {
      &-wrapper {
        @include mq('medium') {
          display: flex;
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }

        > .text {
          @include mq('medium') {
            flex: 0 0 50%; // as per live
            padding-left: 15px;
            padding-right: 15px;
          }
        }

        > .container {
          @include mq('medium') {
            flex: 0 0 50%; // as per live
            padding-left: 15px;
            padding-right: 15px;
          }
        }
      }

      &-content {
        max-width: 535px; //as per live
        margin-left: auto;
        margin-right: auto;
        padding-top: 35px;
        padding-bottom: 10px;
        font-weight: var(--emu-common-font-weight-regular);

        @include mq('medium') {
          padding-top: 55px;
          padding-bottom: 30px;
        }

        p {
          &:first-of-type {
            margin-bottom: 25px;

            @include mq('medium') {
              margin-bottom: var(--emu-common-spacing-medium);
            }
          }

          &:last-of-type {
            margin-bottom: var(--emu-common-spacing-medium);
          }
        }
      }

      &-table {
        padding-bottom: 30px;
        max-width: 535px; //as per live
        margin-left: auto;
        margin-right: auto;

        @include mq('medium') {
          padding-top: 55px;
        }

        @include mq('x-large') {
          padding-bottom: 40px;
        }

        &-title {
          margin-bottom: var(--emu-common-spacing-small);
          width: 98%; //as per live

          @include mq('medium') {
            width: 100%; //as per live
          }

          .cmp-title__text {
            font-family: var(--emu-semantic-font-families-mono);
            font-weight: var(--emu-semantic-font-weight-500);
            font-size: var(--emu-common-font-sizes-wide-medium);
            line-height: 1.2;

            @include mq('large') {
              font-size: 20px;
            }
          }
        }
      }

      &-disclaimer {
        font-size: var(--emu-semantic-font-sizes-narrow-xs);
        font-weight: var(--emu-common-font-weight-regular);
        line-height: var(--emu-semantic-line-heights-wide-xs);

        @include mq('x-large') {
          min-width: 580px; //as per live
        }

        p {
          margin-bottom: 5px;
          padding-left: 10px;

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-narrow-small);
            padding-left: 12px;
            line-height: 1.5;
          }

          @include mq('large') {
            font-size: 20px;
          }

          &:first-of-type {
            margin-top: var(--emu-common-spacing-small);

            @include mq('medium') {
              margin-top: var(--emu-common-spacing-none);
              font-size: var(--emu-semantic-font-sizes-wide-xs);
            }
          }
        }
      }

      &-modal {
        &-title {
          padding-top: var(--emu-common-spacing-medium);
          padding-left: var(--emu-common-spacing-large);
          padding-right: var(--emu-common-spacing-large);

          .cmp-title__text {
            font-family: var(--emu-semantic-font-families-mono);
            font-weight: var(--emu-semantic-font-weight-500);
            margin-bottom: var(--emu-common-spacing-small);
            font-size: 15px;
            line-height: 22px;

            @include mq('medium') {
              font-size: 26px;
              line-height: 1.2;
            }
          }
        }

        &-footer {
          font-size: var(--emu-common-font-sizes-wide-medium);
          font-family: var(--emu-semantic-font-families-mono);
          font-weight: var(--emu-common-font-weight-regular);
          line-height: var(--emu-common-line-heights-wide-medium);
          padding: 12px;

          p {
            margin: var(--emu-common-spacing-xs);
          }
        }

        &-close-text {
          position: absolute;
          top: var(--emu-common-spacing-large);
          right: 28px;
          font-size: var(--emu-semantic-font-sizes-wide-xs);
          font-family: var(--emu-semantic-font-families-mono);
          opacity: 0.5;
          cursor: pointer;

          @include mq('medium') {
            top: 46px;
            right: 38px;
          }

          &:hover {
            opacity: 0.75;
          }
        }
      }

      &-main-modal {
        .close.main-close {
          &:hover {
            & + .container {
              .safety__instruction-modal-close-text {
                opacity: 0.75;
              }
            }
          }
        }
      }
    }
  }
}

.safety__banner-bg {
  img {
    @include aem-editor-view {
      object-fit: contain !important;
      position: static !important;
      height: auto !important;
      width: auto !important;
    }
  }
}
