#error {
  .error-page {
    padding-top: 185px;
    padding-bottom: 150px;

    @include mq('medium') {
      padding-top: 135px;
    }

    @include mq('tablet') {
      padding-top: 100px;
    }

    &__title {
      .cmp-title__text {
        font-size: 90px;
        line-height: 1.2;
        margin-bottom: var(--emu-common-spacing-small);

        @include mq('medium') {
          font-size: 72px;
        }
      }
    }

    &__text {
      font-size: var(--emu-semantic-font-sizes-narrow-large);
      line-height: 1.5;
      margin-bottom: var(--emu-common-spacing-medium);

      @include mq('medium') {
        font-size: 44px;
      }
    }

    .button {
      text-align: center;

      a.emu-button-v2.emu-button.emu-button__primary-filled {
        line-height: var(--emu-common-line-heights-wide-large);
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 65px;
        padding-right: 65px;
        font-weight: var(--emu-common-font-weight-regular);
        margin-top: 40px;
        margin-bottom: 40px;
        border-width: var(--emu-common-border-width-none);
      }
    }
  }
}
