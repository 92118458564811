.navigationlist {
  .emu-navigation {
    &__content-wrapper {
      position: relative;

      @include mq('tablet') {
        position: static;
      }

      nav {
        &,
        & > ul {
          @include mq('tablet') {
            position: static;
          }
        }

        .emu-navigation__menu--level-0 {
          .emu-navigation__item-parent {
            border-bottom-width: var(--emu-common-border-width-none);

            @include mq('tablet') {
              border-bottom-width: var(--emu-common-border-width-thick);
            }

            &::after {
              @include mq('tablet') {
                content: '';
                background-color: var(--emu-semantic-colors-secondary-100);
                border-top: var(--emu-common-border-width-thin) solid #c4c3c3;
                width: 100%;
                height: 35px;
                position: absolute;
                bottom: -35px;
                left: 50%;
                transform: translateX(-50%);
                visibility: hidden;
                z-index: var(--emu-common-other-z-index-behind);
              }
            }

            > a {
              padding-top: 10px;
              padding-bottom: 10px;
              border-bottom: var(--emu-common-border-width-thin) solid
                var(--emu-common-colors-black);

              @include mq('tablet') {
                padding-top: var(--emu-common-spacing-none);
                padding-bottom: var(--emu-common-spacing-none);
                border-bottom: none;
              }
            }

            &:hover {
              &::after {
                @include mq('tablet') {
                  visibility: visible;
                }
              }
              .emu-navigation__menu--level-1 {
                @include mq('tablet') {
                  display: flex;
                }
              }
            }
          }
        }

        > ul {
          padding-left: 25px;
          padding-right: 25px;

          @include mq('medium') {
            flex-direction: column;
          }

          @include mq('tablet') {
            flex-direction: row;
            align-items: center;
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            height: 95px; // to match live
          }

          > li {
            border-bottom: var(--emu-common-border-width-thin) solid
              var(--emu-common-colors-black);

            @include mq('tablet') {
              border-bottom: var(--emu-common-border-width-thick) solid
                var(--emu-common-colors-transparent);
              height: 91px; // as per live
              display: flex;
              align-items: center;
              margin-top: var(--emu-common-spacing-xs);

              &:hover {
                border-bottom-color: var(--emu-semantic-colors-primary-100);
              }
            }

            a {
              text-align: center;
              font-size: var(--emu-semantic-font-sizes-wide-xs);
              line-height: 21px;
              font-weight: var(--emu-common-font-weight-regular);
              padding-top: 13px;
              padding-bottom: 10px;
              margin-top: var(--emu-common-spacing-xs);

              @include mq('tablet') {
                padding-top: var(--emu-common-spacing-none);
                padding-bottom: var(--emu-common-spacing-none);
                padding-left: 22px;
                padding-right: 22px;
                margin-top: var(--emu-common-spacing-none);
              }
            }

            > ul {
              display: block;

              @include mq('medium') {
                position: relative;
              }

              @include mq('tablet') {
                display: none;
                position: absolute;
                bottom: -35px;
              }

              li {
                border-bottom: var(--emu-common-border-width-thin) solid #bcb8b6; // used once

                @include mq('tablet') {
                  border-bottom: none;
                }

                a {
                  margin-top: var(--emu-common-spacing-none);
                  padding-top: var(--emu-common-spacing-small);
                  padding-bottom: 5px;
                  height: auto;

                  @include mq('tablet') {
                    padding-left: 25px;
                    padding-right: 25px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
