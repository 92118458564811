[data-component='text'] {
  p,
  li {
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    background-color: inherit;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
  }
}
