.backToTop {
  background-color: var(--emu-common-colors-black);
  .aaaem-back-to-top-button.js-content-shown {
    display: block;
  }

  a {
    width: 100%;
    line-height: var(--emu-common-line-heights-wide-medium);
    padding-right: 15px;
    padding-left: 15px;
    pointer-events: none;
    position: absolute;
    top: 53px;
    left: 0;

    @include mq('tablet') {
      max-width: 1170px; //as per live
      margin-left: auto;
      margin-right: auto;
    }

    span {
      pointer-events: auto;
      font-size: var(--emu-semantic-font-sizes-wide-xs);
      font-weight: var(--emu-common-font-weight-regular);
      color: var(--emu-common-colors-white);
      line-height: 1.5;
    }
  }
}
