#rewards-program {
  .rewards {
    &__alle {
      padding-top: 94px;

      @include mq('medium') {
        padding-right: 10px;
        padding-bottom: 55px;
        padding-left: 10px;
        margin-top: 74px;
      }

      @include mq('tablet') {
        padding-top: 55px;
        margin-top: 78px;
      }

      @include mq('large') {
        margin-top: var(--emu-common-spacing-none);
        padding-left: 35px;
        padding-right: 35px;
      }

      &-wrapper {
        &.aaaem-container {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);

          @include mq('medium') {
            display: flex;
            flex-direction: row-reverse;
          }
        }

        > .container {
          @include mq('medium') {
            flex: 0 0 50%; // as per live
            max-width: 50%; // as per live
          }
        }
      }

      &-content-wrapper {
        padding-top: 71px;
        padding-bottom: 15px;
        padding-right: 30px;
        padding-left: 30px;

        @include mq('medium') {
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-none);
          padding-right: 15px;
          padding-left: 15px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
        }
      }

      &-title {
        margin-bottom: 10px;

        @include mq('medium') {
          margin-bottom: 30px;
        }

        .cmp-title__text {
          line-height: 50px;
          letter-spacing: 0.42px;

          @include mq('medium') {
            font-size: 56px;
            line-height: 62px;
          }

          @include mq('large') {
            font-size: 70px;
            line-height: 70px;
          }

          br {
            @include mq('small-plus') {
              display: none;
            }
          }
        }
      }

      &-sub-title {
        margin-bottom: 5px;

        @include mq('medium') {
          margin-bottom: var(--emu-common-spacing-none);
        }

        .cmp-title__text {
          font-size: var(--emu-semantic-font-sizes-narrow-small);
          line-height: 22px;

          @include mq('medium') {
            font-size: 25px;
            line-height: 34px;
          }
        }
      }

      &-text {
        font-size: var(--emu-common-font-sizes-wide-medium);
        line-height: 22px;

        @include mq('medium') {
          font-size: 25px;
          line-height: 34px;
        }
      }

      &-cta-wrapper {
        margin-top: 35px;
        margin-bottom: 35px;
        display: flex;

        > .button {
          width: 50%; // as per live

          @include mq('medium') {
            width: auto; // as per live
          }

          @include mq('large') {
            width: 50%; // to improve styles
            max-width: 240px; // to improve styles
            margin-right: 20px;
          }

          @include mq('xx-large') {
            min-width: 240px; // as per live
            max-width: unset;
            width: auto;
          }

          &:first-child {
            margin-right: 18px;

            @include mq('large') {
              margin-right: 24px;
            }
          }
        }
      }

      &-join-cta,
      &-log-in-cta {
        height: 50px; // as per live
        align-items: center;
        justify-content: center;
        width: 100%;

        span {
          letter-spacing: 0.38px;
        }
      }

      &-join-cta {
        &.emu-button.emu-button-v2.emu-button__secondary-filled {
          border-width: var(--emu-common-border-width-none);

          @include mq('medium') {
            padding-left: 50px;
            padding-right: 50px;
          }

          @include mq('large') {
            padding-left: 55px;
            padding-right: 55px;
          }
        }
      }

      &-log-in-cta {
        &.emu-button.emu-button-v2.emu-button__primary-outline {
          @include mq('medium') {
            padding-left: 50px;
            padding-right: 50px;
          }

          @include mq('large') {
            padding-left: 55px;
            padding-right: 55px;
          }
        }
      }

      &-img-wrapper {
        padding-right: 30px;
        padding-bottom: 15px;

        @include mq('medium') {
          padding-left: 15px;
          padding-right: 15px;
          padding-bottom: var(--emu-common-spacing-none);
        }
      }

      &-img {
        img {
          width: 100%;

          @include mq('medium') {
            max-width: 75%; // as per live
            margin-left: auto;
            margin-right: auto;
          }
        }
      }

      &-disclaimer {
        max-width: 90%; // as per live
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
        font-size: var(--emu-common-font-sizes-narrow-medium);
        line-height: var(--emu-common-line-heights-narrow-large);
        font-weight: var(--emu-common-font-weight-regular);

        @include mq('medium') {
          max-width: 75%; // as per live
          font-size: 13px;
          line-height: 17px;
          margin-bottom: 15px;
        }
      }
    }

    &__unlock-alle {
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 10px;
      padding-right: 10px;

      @include mq('medium') {
        padding-top: 65px;
        padding-bottom: 65px;
        padding-left: 65px;
        padding-right: 65px;
      }

      @include mq('large') {
        padding-top: 125px;
        padding-bottom: 125px;
      }

      &-content-container {
        height: 475px; // as per live
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include mq('medium') {
          height: 300px; // as per live
        }

        > .button {
          text-align: center;
        }
      }

      &-title {
        width: 90%; // as per live
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 35px;

        @include mq('medium') {
          margin-bottom: 25px;
        }

        .cmp-title__text {
          line-height: 45px;

          @include mq('medium') {
            font-size: 38px;
            line-height: var(--emu-common-line-heights-wide-xl);
          }

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xxl);
            line-height: 70px;
          }
        }

        br {
          @include mq('small-plus') {
            display: none;
          }
        }
      }

      &-cta {
        margin-bottom: 10px;
        height: 50px; // as per live
        align-items: center;
        justify-content: center;
        padding-left: 55px;
        padding-right: 55px;
        border-width: var(--emu-common-border-width-none);

        @include mq('medium') {
          margin-bottom: var(--emu-common-spacing-none);
        }

        span {
          letter-spacing: 0.38px;
        }
      }
    }

    &__benefits {
      position: relative;
      display: flex;
      flex-direction: column-reverse;

      img {
        width: 100%;
      }

      &--text-right {
        .rewards__benefits {
          &-img {
            margin-top: -5px;
          }

          &-content {
            padding-bottom: var(--emu-common-spacing-none);

            @include mq('medium') {
              width: 50%; // as per live
              left: unset;
              right: 0;
              top: 35px;
            }

            @include mq('large') {
              width: 45%; // to improve style
              right: 50px;
            }

            @include mq('x-large') {
              width: 35%; // as per live
              top: 85px;
              right: 150px;
            }
          }

          &-title {
            .cmp-title__text {
              font-size: 38px;

              @include mq('medium') {
                font-size: var(--emu-semantic-font-sizes-wide-large);
                line-height: 45px;
              }

              @include mq('large') {
                font-size: 45px;
                line-height: 56px;
              }
            }
          }

          &-desc {
            margin-bottom: 25px;
          }
        }
      }

      &-content {
        width: 90%; // as per live
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-top: 50px;
        padding-bottom: 10px;

        @include mq('medium') {
          position: absolute;
          top: 0;
          left: 0;
          width: 65%; // as per live
          top: 50px;
          padding-top: var(--emu-common-spacing-none);
        }

        @include mq('large') {
          left: 50px;
          width: 45%; // to improve styles
        }

        @include mq('x-large') {
          top: 110px;
          left: 150px;
          width: 35%; // as per live
        }
      }

      &-icon {
        max-width: 95px; // as per live
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 25px;

        @include mq('medium') {
          max-width: 70px; // as per live
          margin-bottom: 10px;
        }

        @include mq('large') {
          max-width: 115px; // as per live
          margin-bottom: 25px;
        }
      }

      &-title {
        margin-bottom: 15px;
        text-align: center;

        @include mq('medium') {
          margin-bottom: var(--emu-common-spacing-none);
        }

        @include mq('large') {
          margin-bottom: 15px;
        }

        .cmp-title__text {
          line-height: 56px;

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-large);
            line-height: 45px;
          }

          @include mq('large') {
            font-size: 50px;
            line-height: 56px;
          }
        }
      }

      &-desc {
        font-size: var(--emu-semantic-font-sizes-narrow-small);
        line-height: 28px;
        margin-bottom: 30px;
        max-width: 400px; // as per live
        margin-left: auto;
        margin-right: auto;

        @include mq('medium') {
          max-width: 83%; // as per live
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 25px;
          line-height: 22px;
        }

        @include mq('large') {
          font-size: 20px;
          line-height: 28px;
          max-width: 400px; // as per live
        }

        br {
          @include mq('small-plus') {
            display: none;
          }
        }
      }

      &-disclaimer {
        position: absolute;
        bottom: 15px;
        left: 20px;
        width: 80%; // as per live
        font-size: 13px;
        line-height: 15px;
        font-weight: var(--emu-common-font-weight-regular);

        @include mq('medium') {
          font-size: var(--emu-common-font-sizes-narrow-medium);
          max-width: 60%; // as per live
          line-height: var(--emu-semantic-line-heights-wide-xs);
          bottom: 13px;
          left: 45px;
        }

        @include mq('large') {
          max-width: 100%;
          bottom: 25px;
          font-size: 13px;
          line-height: 15px;
        }
      }
    }
  }
}
