// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }

  @include aem-editor-view {
    display: block;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }

  @include aem-editor-view {
    display: block;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }

  @include aem-editor-view {
    display: block;
  }
}

.mobile-view {
  display: block;

  @include mq('medium') {
    display: none;
  }

  @include aem-editor-view {
    display: block;
  }
}

// padding adjustments
.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.u-container--default,
.u-container--default.aaaem-container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;

  @include mq('tablet') {
    max-width: var(--emu-common-sizing-xxxl);
  }
}

[data-component='title'],
[data-component='text'] {
  &.u-text--color-white {
    color: var(--emu-common-colors-white);
  }

  &.u-text--color-black {
    color: var(--emu-common-colors-black);
  }

  &.u-text--color-primary-50 {
    color: var(--emu-semantic-colors-primary-50);
  }

  &.u-text--color-primary-100 {
    color: var(--emu-semantic-colors-primary-100);
  }

  &.u-text--color-primary-200 {
    color: var(--emu-semantic-colors-primary-200);
  }

  &.u-text--color-primary-300 {
    color: var(--emu-semantic-colors-primary-300);
  }

  &.u-text--color-primary-400 {
    color: var(--emu-semantic-colors-primary-400);
  }
}

.u-bg--primary-600 {
  background-color: var(--emu-semantic-colors-primary-600);
}

.u-bg--primary-700 {
  background-color: var(--emu-semantic-colors-primary-700);
}

.u-bg--primary-800 {
  background-color: var(--emu-semantic-colors-primary-800);
}

.u-bg--primary-100 {
  background-color: var(--emu-semantic-colors-primary-100);
}

.u-bg--color-black {
  background-color: var(--emu-common-colors-black);
}

.u-bg--primary-900 {
  background-color: var(--emu-semantic-colors-primary-900);
}

.u-bg--primary-950 {
  background-color: var(--emu-semantic-colors-primary-950);
}

.u-bg--secondary-50 {
  background-color: var(--emu-semantic-colors-secondary-50);
}

.u-list-style-none ul {
  list-style: none;
  padding: var(--emu-common-spacing-none);
  margin: var(--emu-common-spacing-none);
}

.u-hide-overflow {
  overflow: hidden;
  height: 100vh;
}

.u-hide {
  display: none !important;

  @include aem-editor-view {
    display: block !important;
  }
}

[data-component='title'] {
  &.u-title--large {
    .cmp-title__text {
      font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
      line-height: var(--emu-semantic-line-heights-narrow-xxxl);
    }
  }
}

.u-page-anchor {
  scroll-margin-top: 75px;

  @include mq('tablet') {
    scroll-margin-top: 130px;
  }
}
