#sitemap {
  .sitemap {
    padding-top: 205px;
    padding-bottom: 65px;

    @include mq('medium') {
      padding-top: 135px;
      padding-bottom: 150px;
    }

    @include mq('tablet') {
      padding-top: 100px;
    }

    &__title {
      .cmp-title__text {
        font-size: 44px;
        line-height: 1.2;
        margin-bottom: 45px;

        @include mq('medium') {
          margin-bottom: 65px;
        }
      }
    }

    &__text {
      ul {
        li {
          margin-bottom: 40px;
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          line-height: var(--emu-semantic-line-heights-narrow-medium);
          font-weight: var(--emu-semantic-font-weight-500);

          @include mq('medium') {
            margin-bottom: 30px;
          }

          &:last-child {
            margin-bottom: 30px;
          }

          a {
            text-underline-offset: 4px;
            text-decoration-thickness: 1px;
          }

          ul {
            margin-top: 5px;

            @include mq('medium') {
              margin-top: 20px;
            }

            li {
              margin-bottom: 5px;

              @include mq('medium') {
                margin-bottom: 15px;
              }

              a {
                text-decoration: none;
                font-weight: var(--emu-common-font-weight-regular);
              }
            }
          }
        }
      }
    }
  }
}
