.custom-cursor {
  &__container {
    position: relative;
  }

  &__element {
    cursor: crosshair;

    &--crosshair {
      cursor: url('../../assets/images/crossHair-image-new-medium.png') 7 4,
        crosshair;
    }

    &--crosshair-white {
      cursor: url('../../assets/images/cursor_plus_white.png') 7 4, crosshair;
    }
  }

  &__text {
    font-size: var(--emu-common-font-sizes-narrow-medium);
    line-height: var(--emu-common-line-heights-narrow-medium);
    padding-top: 3px;
    padding-bottom: var(--emu-common-spacing-xxs);
    padding-left: var(--emu-common-spacing-small);
    padding-right: var(--emu-common-spacing-small);
    background-color: #d5e2e2; // used only once
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    transform: translate(16px, 20px);

    @include aem-editor-view {
      position: static;
      display: block;
    }

    ul {
      list-style: none;
      padding: var(--emu-common-spacing-none);
      margin: var(--emu-common-spacing-none);
    }

    li {
      display: none;

      &:first-child {
        display: block;
      }

      &:last-child {
        display: none;
      }

      @include aem-editor-view {
        display: block;
      }
    }

    &--show {
      display: block;
    }

    &--toggle-text {
      ul {
        li {
          &:first-child {
            display: none;
          }

          &:last-child {
            display: block;
          }
        }
      }
    }
  }
}
